export const checkIsMobile = (customSize) => {
    let isMobile = false
    let size = customSize || 600
    if (window.innerWidth < size) {
        isMobile = true
    }
    return isMobile
}

export const uuidGenerator = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const copyToClipboard =  (text) => {
    if (getDevice()) {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    } else {
         navigator.clipboard.writeText(text)
    }

}

export const getDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
}
