import React from 'react';
import videojs from 'video.js'
import "../assets/VideoPlayer.module.css"
import {getDevice} from "../constants/CommonUtils";
import {Label} from "./ComponentManager/ComponentManager";

export default class VideoPlayer extends React.Component {

    state = {iframeLink: false, hideUIonPlay: true, enableStretch: false}
    firstLoad = true;
    videoInterval;
    timeout;
    lastTap = 0;

    setupPlayer = () => {
        // instantiate Video.js
        console.log("CHECK PROPS: ", this.props.videoJsOptions)
        this.player = videojs(this.videoNode, {
            ...this.props.videoJsOptions,
            techOrder: ["html5"],

            userActions: {
                userActions: {
                    doubleClick: false
                },
                hotkeys: async (event) => {
                    //TODO handle hotkey
                    if (event.code === "Space") {
                        await this.togglePlayPause()
                    } else if (event.code === "ArrowRight") {
                        this.player.currentTime(this.player.currentTime() + 10);
                    } else if (event.code === "ArrowLeft") {
                        this.player.currentTime(this.player.currentTime() - 10);
                    } else if (event.code === "ArrowUp") {
                        this.player.currentTime(this.player.currentTime() + 80);
                    } else if (event.code === "ArrowDown") {
                        this.player.currentTime(this.player.currentTime() - 80);
                    }


                }
            },
            nativeControlsForTouch: false,

        }, async () => {
            console.log('onPlayerReady', this, this.props)
            if (this.props.forcePlayFromHost) {
                this.player.currentTime(this.props.forcePlayFromHost);
                await this.videoNode.play();
            }
        });


        let {setRef} = this.props;
        if (setRef) {
            setRef(this)
        }
        // let ModalDialog = videojs.getComponent('ModalDialog');
        //
        // let modal = new ModalDialog(this.player, {
        //     content: "Right arrow --> 10s time skip forward || "
        //         +
        //         "Left arrow --> 10s time skip backward || "
        //         +
        //         "Up arrow --> 80s time skip forward || "
        //         +
        //         "Down arrow --> 80s time skip backward "
        //     ,
        //     label: "test",
        //     temporary: false
        // });
        //
        // this.player.addChild(modal);
        // modal.open();
        this.player.on('error', () => {
            this.setState({iframeLink: true})
        });
        // this.player.on('pause', function() {
        //     modal.open();
        // });
        //
        // this.player.on('play', function() {
        //     modal.close();
        // });
    }

    componentDidMount() {

        this.setupPlayer()


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {videoJsOptions, disableCenterPlay} = this.props
        let {sources, controls} = videoJsOptions
        let {iframeLink} = this.state
        if (!iframeLink && prevState.iframeLink && iframeLink !== prevState.iframeLink) {
            this.setupPlayer()
        } else if (sources && sources[0] && prevProps.videoJsOptions.sources && prevProps.videoJsOptions.sources[0] && sources[0].src !== prevProps.videoJsOptions.sources[0].src) {
            this.setState({iframeLink: false}, () => {
                this.player.src(sources[0])
                // this.player.play()
                this.firstLoad = true

            })
        } else if (controls !== prevProps.videoJsOptions.controls) {
            this.player.controls(controls)
            this.forceUpdate()
        } else if (disableCenterPlay !== prevProps.disableCenterPlay) {
            if (disableCenterPlay) {
                document.getElementById("realVideoPlayer").classList.remove("vjs-show-big-play-button-on-pause")
            } else {

                document.getElementById("realVideoPlayer").classList.add("vjs-show-big-play-button-on-pause")
            }

        }


    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    togglePlayPause = async () => {
        if (!this.lockPlayPause) {
            if (this.videoNode.paused) {
                this.lockPlayPause = true
                await this.videoNode.play();
                this.lockPlayPause = false
            } else {
                this.lockPlayPause = true
                await this.videoNode.pause();
                this.lockPlayPause = false
            }
        }
    }

    onTouchEnd = async (event) => {
        let {isQuickVideo} = this.props
        let currentTime = new Date().getTime();
        let tapLength = currentTime - this.lastTap;
        clearTimeout(this.timeout);
        if (tapLength < 350 && tapLength > 0) {
            //Foward/Backward
            event.preventDefault();

            if (this.touch.clientX >= window.innerWidth / 2 && this.touch.clientY >= window.innerHeight / 2) {
                this.player.currentTime(this.player.currentTime() + 10);
            } else if (this.touch.clientX < window.innerWidth / 2 && this.touch.clientY >= window.innerHeight / 2) {
                this.player.currentTime(this.player.currentTime() - 10);
            } else if (this.touch.clientX >= window.innerWidth / 2 && this.touch.clientY < window.innerHeight / 2) {
                this.player.currentTime(this.player.currentTime() + 80);
            } else if (this.touch.clientX < window.innerWidth / 2 && this.touch.clientY < window.innerHeight / 2) {
                this.player.currentTime(this.player.currentTime() - 80);
            }
            // await this.togglePlayPause("double tap")
        } else {
            this.timeout = setTimeout(async () => {
                if (isQuickVideo) {
                    await this.togglePlayPause()
                }
                //Play/Pause
                clearTimeout(this.timeout);
            }, 350);
        }
        this.lastTap = currentTime;

    }

    onTouchStart = (e) => {
        if (e.touches && e.touches[0]) {
            this.touch = e.touches[0]
        }
    }

    renderUI = () => {
        let {hideUIonPlay} = this.state
        return hideUIonPlay &&
            <div key={"videoUIcustom" + hideUIonPlay} style={{zIndex: 1, position: "absolute"}}
                 onTouchStart={this.onTouchStart}
                 onTouchEnd={this.onTouchEnd}
            >
                <div style={{
                    opacity: 0.5,
                    position: "fixed",
                    height: "50%",
                    width: "50%",
                    borderBottom: "2px dotted",
                    borderRight: "2px dotted",
                    borderColor: "var(--second-color)",
                    top: 0,
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    background: "rgba(0,0,0,0.5)"

                }}>
                    <Label
                        style={{}}>
                        Double tap here to skip 80s backward
                    </Label>
                </div>
                <div style={{
                    opacity: 0.5,
                    position: "fixed",
                    height: "50%",
                    width: "50%",
                    borderBottom: "2px dotted",
                    borderLeft: "2px dotted",
                    borderColor: "var(--second-color)",
                    top: 0,
                    left: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    background: "rgba(0,0,0,0.5)"
                }}>
                    <Label style={{}}>
                        Double tap here to skip 80s forward
                    </Label>
                </div>
                <div style={{
                    opacity: 0.5,
                    position: "fixed",
                    height: "50%",
                    width: "50%",
                    borderTop: "2px dotted",
                    borderRight: "2px dotted",
                    borderColor: "var(--second-color)",
                    top: "50%",
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    background: "rgba(0,0,0,0.5)"
                }}>
                    <Label style={{}}>
                        Double tap here to skip 10s backward
                    </Label>
                </div>
                <div style={{
                    opacity: 0.5,
                    position: "fixed",
                    height: "50%",
                    width: "50%",
                    borderTop: "2px dotted",
                    borderLeft: "2px dotted",
                    borderColor: "var(--second-color)",
                    top: "50%",
                    left: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    background: "rgba(0,0,0,0.5)"
                }}>
                    <Label style={{}}>
                        Double tap here to skip 10s forward
                    </Label>
                </div>

            </div>
    }
    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM

    // see https://github.com/videojs/video.js/pull/3856
    render() {
        let {
            onPause = () => {
            },
            selectedLink,
            isMobile,
            onPlay = () => {
            },
            disableUi,
            toggleUIonPlay,
            disableCenterPlay,
            disableTouchEvents,
            disableForcedFullScreen,
            isQuickVideo,
            forceFullScreen
        } = this.props;
        let {iframeLink, enableStretch} = this.state;
        let classNames = "video-js vjs-theme-city vjs-big-play-centered "
        if (!disableCenterPlay) {
            classNames += "vjs-show-big-play-button-on-pause"
        }

        let aspectRatioStyle = {
            objectFit: "fill",
            padding: "0px 250px"
        }

        if (!enableStretch) {
            aspectRatioStyle = {}
        }
        return (<div id={"videoDiv"} style={{display: "flex", flex: 1, height: "-webkit-fill-available"}}>
                <style>
                    {!disableCenterPlay && ".vjs-big-play-button,.vjs-control-bar {" +
                    "position: absolute!important;" +
                    "z-index: 10!important;}"
                    }
                    {disableTouchEvents &&
                    ".vjs-progress-control {display:none!important;}" +
                    ".video-js {pointer-events:none!important;}"
                    }
                    {".vjs-control-bar{pointer-events:auto!important;}"}
                    {/*{isQuickVideo &&  ".vjs-default-skin.vjs-user-inactive .vjs-control-bar{  display: block;\n" +*/}
                    {/*"  visibility: hidden;\n" +*/}
                    {/*"  opacity: 0;\n" +*/}
                    {/*"\n" +*/}
                    {/*"  -webkit-transition: visibility 1.5s, opacity 1.5s;\n" +*/}
                    {/*"     -moz-transition: visibility 1.5s, opacity 1.5s;\n" +*/}
                    {/*"      -ms-transition: visibility 1.5s, opacity 1.5s;\n" +*/}
                    {/*"       -o-transition: visibility 1.5s, opacity 1.5s;\n" +*/}
                    {/*"          transition: visibility 1.5s, opacity 1.5s;}"}*/}
                </style>

                {iframeLink && <iframe
                    onLoad={(e) => {
                        console.log("ONLOAD: ", e);
                    }
                    }
                    // sandbox="allow-modals,allow-forms,allow-orientation-lock,allow-pointer-lock,allow-presentation,allow-same-origin,allow-scripts"
                    src={selectedLink}

                    scrolling="yes"
                    frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no"
                    style={{flex: 1, overflow: "auto"}}
                    allowFullScreen/>
                }
                {!disableUi && getDevice() && this.renderUI()}
                {!iframeLink && <div data-vjs-player>
                    <video
                        id={"realVideoPlayer"}
                        onError={(e) => {
                            if (toggleUIonPlay) {

                                toggleUIonPlay(true)
                            }
                            this.setState({iframeLink: true})
                        }}
                        onTouchStart={!disableTouchEvents ? this.onTouchStart : () => {
                        }}
                        onTouchEnd={!disableTouchEvents ? this.onTouchEnd : () => {
                        }}

                        onPause={() => {
                            if (isQuickVideo) {
                                this.player.controlBar.show()
                            }
                            return onPause(this.videoNode)
                        }}
                        onPlay={async () => {
                            try {

                                if (isQuickVideo) {
                                    this.player.controlBar.hide()
                                }

                                if (forceFullScreen || (getDevice() && !disableForcedFullScreen)) {
                                    this.player.requestFullscreen()
                                    if (getDevice()) {
                                        await window.screen.orientation.lock("landscape")
                                    }
                                }
                            } catch (e) {
                                console.error("Errore nel forceFullscreen", e)
                            }


                            let h = this.player.videoHeight(), w = this.player.videoWidth()
                            if (h / w === 0.75) {
                                this.setState({enableStretch: true})
                            }
                            return onPlay(this.videoNode)
                        }}
                        style={{
                            width: "100vw", flex: 1, height: "100vh", ...aspectRatioStyle
                        }


                        }
                        ref={node => this.videoNode = node}
                        className={classNames}/>
                </div>}

            </div>
        )
    }
}


export const getTypeVideo = (link) => {
    let tmpExt = link.split(".")
    let extension = tmpExt[tmpExt.length - 1]
    switch (extension) {
        case "mp4":
            return "video/webm";
        case "m3u8":
            return "application/x-mpegURL";
        case"mkv":
            return "video/webm;codecs=vp8,opus";
    }
}