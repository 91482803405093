import React, {Component} from 'react';
import Button from "@material-ui/core/Button"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

class EpisodeListButton extends Component {
    render() {
        let {enableSettings, enableEpisodeList,  editStateFromChild} = this.props
        return (
            enableSettings && <Button color={"primary"}
                                      variant={"contained"}
                                      style={{
                                          width: "50px",
                                          height: "40px",
                                          marginLeft: "10px",
                                          zIndex: 1000
                                      }}
                                      onClick={() => editStateFromChild({enableEpisodeList: !enableEpisodeList})}>
                EP{enableEpisodeList ? <KeyboardArrowDown/> : <KeyboardArrowUp/>}
            </Button>

        );
    }
}

export default EpisodeListButton;