import React, {Component} from 'react';
import {IonLoading} from "@ionic/react";

class LoadingMobile extends Component {

    lMobile = null;

    render() {
        let { loading} = this.props;

        if (this.lMobile && !loading) {
            this.lMobile.dismiss();
        }

        return (
            <IonLoading
                ref={x => this.lMobile = x}
                isOpen={true}
                message={"Loading, please wait..."}
            />
        );
    };


}

export default (LoadingMobile);
