import React, {Component} from 'react';
import {v4 as uuid4} from "uuid"
import IconButton from "@material-ui/core/IconButton";
import EpisodeCard from "./EpisodeCard";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {ArrowBack, OpenInNew} from "@material-ui/icons";
import VideoPlayer, {getTypeVideo} from "./VideoPlayer";
import {
    episodeSeen,
    getTimeLink,
    getTimeLinkRaw,
    rawEpisodeSeen,
    saveTimeLink,
    saveTimeLinkRaw
} from "../constants/StorageHelper";
import VideoPlayerCustomUiContainer from "./VideoPlayerCustomUi/VideoPlayerCustomUiContainer";
import EpisodeListUi from "./VideoPlayerCustomUi/VideoPlayerEpisodeList/EpisodeListUI";
import VideoPlayerButtonsUi from "./VideoPlayerCustomUi/VideoPlayerButtonsUI";
import EpisodeListButton from "./VideoPlayerCustomUi/VideoPlayerEpisodeList/EpisodeListButton";
import VideoPlayerMobileGridActions
    from "./VideoPlayerCustomUi/VideoPlayerMobileGridActions/VideoPlayerMobileGridActions";
import {ColumnContainer, Label, RowContainer} from "./ComponentManager/ComponentManager";

class VideoPlayerPage extends Component {
    state = {
        iframeLink: false,
        seasonNumber: this.props.seasonNumber,
        episode: this.props.episode,
        id: this.props.id,
        episodes: this.props.episodes,
        show: this.props.show,
        selectedLink: queryString.parse(this.props.location.search).link,
        enableEpisodeList: false,
        hideUIonPlay: true,
        disableUi: false,
        enableSettings: false
    }
    firstPlay = true;
    firstLoad = true;

    componentDidMount() {
        let disableUi = false
        if (localStorage.getItem("disableUiPlayer")) {
            disableUi = localStorage.getItem("disableUiPlayer")
        }
        this.setState({disableUi})
    }

    toggleFullscreen = () => {
        // let elem = document.querySelector("video");
        let elem = document.querySelector("video");

        elem.requestFullscreen = elem.requestFullscreen || elem.mozRequestFullscreen
            || elem.msRequestFullscreen || elem.webkitRequestFullscreen;

        if (!document.fullscreenElement) {
            elem.requestFullscreen().then({}).catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            // if (document.exitFullscreen) {
            //     document.exitFullscreen();
            // }
        }
    }

    renderContent = () => {
        let {
            seasonNumber, episode, id, episodes, show,
            selectedLink, enableEpisodeList, hideUIonPlay,
            disableUi, enableSettings
        } = this.state;
        let {history, isRaw} = this.props;
        // Nel caso di episodi raw, il numero si prova a prendere con un parseInt del titolo
        let episodeNumber = episode.episode_number || parseInt(episode.episodeName)
        let episodeName = episode.name || episode.episodeName;
        let selectedId
        let temp2 = []
        let isMobile = false
        if (window.innerWidth < 600) {
            isMobile = true
        }
        for (let j in episodes) {
            let episodeTmp = episodes[j];
            let selectedNumber = episodeTmp.episode_number || parseInt(episodeTmp.episodeName)
            let isSelected = selectedNumber === episodeNumber
            if (isSelected) {
                selectedId = episodeNumber
            }
            temp2.push(<EpisodeCard
                rawEpisode={isRaw}
                liteVersion={true}
                isSelected={isSelected}
                handlePlayEpisode={(selectedLink, isMobile) => {
                    history.push("/dp/" + id + "/" + seasonNumber + "?ep=" + j + "&link=" + selectedLink + (isRaw ? "&isRaw=true" : ""))
                    this.setState({
                        seasonNumber,
                        episode: episodeTmp,
                        id,
                        episodes,
                        show,
                        selectedLink,
                        isMobile
                    })
                }}
                setLoading1={(value) => {
                    this.setState({loadingChild: value})
                }} key={"Internal" + uuid4()} id={id} seasonNumber={seasonNumber}
                episodes={episodes}
                episode={episodeTmp}
                episodeNumber={episodeNumber}
                show={show}/>);
        }

        let flexDirection = "flex-direction:row;height:100%;"
        if (isMobile) {
            // flexDirection = "flex-direction:column;height:100%;"
        }
        setTimeout(() => {
                let elmnt = document.getElementById(selectedId);
                let elmntTE = document.getElementById("testCont");
                if (elmnt && enableEpisodeList) {
                    let elmntCoord = elmnt.getBoundingClientRect()
                    elmntTE.scroll(((elmntCoord.width * 1.1) * (parseInt(selectedId) - 1)) + (selectedId * 5), 0);
                }
            }, 5
        )


        let videoJsOptions = {
            autoplay: false,
            controls: true,
            "data-setup": '{}',
            preload: "auto",
            sources: [
                {
                    src: selectedLink + "#t=0.5",
                    type: getTypeVideo(selectedLink)
                },

            ]
        }


        return <RowContainer customStyle={"width:100%;" + flexDirection}>
            <VideoPlayer videoJsOptions={videoJsOptions}
                         isMobile={isMobile}
                         selectedLink={selectedLink}
                         setRef={(x) => this.childRef = x}
                         onPause={async (videoNode) => {
                             this.setState({hideUIonPlay: !this.state.hideUIonPlay})
                             console.log("ONPAUSE")
                             if (videoNode && this.firstLoad) {
                                 let input = {
                                     idShow: id,
                                     tvShowId: id,
                                     episodeNumber,
                                     seasonNumber,
                                     link: selectedLink,
                                     time: videoNode && videoNode.currentTime
                                 }
                                 if (isRaw) {
                                     getTimeLinkRaw(input).then((x) => {
                                         if (x) {
                                             videoNode.currentTime = x;
                                             this.firstLoad = false;
                                         }
                                     });
                                 } else {
                                     getTimeLink(input).then((x) => {
                                         if (x) {
                                             videoNode.currentTime = x;
                                             this.firstLoad = false;
                                         }
                                     });
                                 }
                             }
                             if (videoNode && videoNode.currentTime) {
                                 let input = {
                                     idShow: id,
                                     tvShowId: id,
                                     episodeNumber,
                                     seasonNumber,
                                     episodeName,
                                     link: selectedLink,
                                     time: videoNode && videoNode.currentTime
                                 }
                                 if (videoNode.currentTime > videoNode.duration * 0.8) {
                                     if (isRaw) {
                                         await rawEpisodeSeen({
                                             idShow: id, link: selectedLink, episodeName,
                                         }, true)
                                     } else {
                                         await episodeSeen({
                                             tvShowId: id,
                                             episodeNumber,
                                             seasonNumber,
                                         }, true)
                                     }
                                 }
                                 if (isRaw) {
                                     await saveTimeLinkRaw(input);
                                 } else {
                                     await saveTimeLink(input);
                                 }
                             }
                         }}
                         onPlay={(videoNode) => {
                             console.log("ONPLAY")
                             this.firstPlay = false
                             this.setState({hideUIonPlay: !this.state.hideUIonPlay})
                             if (videoNode && this.firstLoad) {
                                 let input = {
                                     idShow: id,
                                     episodeName,
                                     tvShowId: id,
                                     episodeNumber,
                                     seasonNumber,
                                     link: selectedLink,
                                     time: videoNode && videoNode.currentTime
                                 }
                                 if (isRaw) {
                                     getTimeLinkRaw(input).then((x) => {
                                         if (x) {
                                             videoNode.currentTime = x;
                                             this.firstLoad = false;
                                         }
                                     });
                                 } else {
                                     getTimeLink(input).then((x) => {
                                         if (x) {
                                             videoNode.currentTime = x;
                                             this.firstLoad = false;
                                         }
                                     });
                                 }
                             }
                             if (this.videoInterval) {
                                 clearInterval(this.videoInterval)
                             }
                             this.videoInterval = setInterval(async () => {
                                 if (videoNode && videoNode.currentTime) {
                                     let input = {
                                         idShow: id,
                                         episodeName,
                                         tvShowId: id,
                                         episodeNumber,
                                         seasonNumber,
                                         link: selectedLink,
                                         time: videoNode && videoNode.currentTime
                                     }

                                     if (videoNode.currentTime > videoNode.duration * 0.8) {
                                         if (isRaw) {
                                             await rawEpisodeSeen({
                                                 idShow: id, link: selectedLink, episodeName,
                                             }, true)
                                         } else {
                                             await episodeSeen({
                                                 tvShowId: id,
                                                 episodeNumber,
                                                 seasonNumber,
                                             }, true)
                                         }
                                     }
                                     if (isRaw) {
                                         await saveTimeLinkRaw(input);
                                     } else {
                                         await saveTimeLink(input);
                                     }
                                 } else {
                                     clearInterval(this.videoInterval)
                                 }
                             }, 3000)

                         }}
                         disableUi={disableUi}
                         toggleUIonPlay={(force) => {
                             if (force) {
                                 this.setState({hideUIonPlay: force})
                             } else {
                                 this.setState({hideUIonPlay: !this.state.hideUIonPlay})
                             }
                         }}
            />
            <VideoPlayerCustomUiContainer
                hideUIonPlay={hideUIonPlay}
                fullWidth={enableEpisodeList}
            >
                <EpisodeListUi
                    enableEpisodeList={enableEpisodeList}
                    isMobile={isMobile}
                    episodeList={temp2}
                />
                <VideoPlayerButtonsUi
                    editStateFromChild={(stateFromChild) => this.setState(stateFromChild)}
                    enableSettings={enableSettings}
                >
                    <EpisodeListButton
                        editStateFromChild={(stateFromChild) => this.setState(stateFromChild)}
                        enableSettings={enableSettings}
                        enableEpisodeList={enableEpisodeList}

                    />
                    <VideoPlayerMobileGridActions
                        disableUi={disableUi}
                        enableSettings={enableSettings}
                        editStateFromChild={(stateFromChild) => this.setState(stateFromChild)}
                    />
                </VideoPlayerButtonsUi>
            </VideoPlayerCustomUiContainer>
        </RowContainer>
    }

    renderActions = () => {
        let {closeVideoPlayerPage} = this.props;
        let {selectedLink, episode, isMobile} = this.state;
        console.log("CHECK EPISODE: ", episode);
        // Nel caso di episodi raw, il numero si prova a prendere con un parseInt del titolo
        let episodeNumber = episode.episode_number || parseInt(episode.episodeName)
        let episodeName = episode.name || episode.episodeName;
        return <RowContainer
            customStyle={"width:100%;height:100%;padding:5px;max-height:50px;z-index:1000;position:fixed;top:0;left:0; background: linear-gradient(90deg, rgba(39, 76, 134, 0.8) 0%, rgba(39, 76, 134, 0) 100%);"}
        >

            <IconButton variant={"contained"} onClick={closeVideoPlayerPage} color="secondary">
                <ArrowBack/>
            </IconButton>
            <Label font={"--text-bold-16"} ellipsis={2}
            >{episodeNumber + ". " + episodeName}</Label>
            <IconButton variant={"contained"} onClick={() => {
                window.open(selectedLink, "_blank")
            }} color="secondary">
                <OpenInNew/>
            </IconButton>
        </RowContainer>
    }

    render() {
        let {hideUIonPlay} = this.state
        return (

            <ColumnContainer customStyle={"width:100%;"}>
                {hideUIonPlay && this.renderActions()}
                {this.renderContent()}
            </ColumnContainer>
        );
    }
}

export default withRouter(VideoPlayerPage);