import React, {Component} from 'react';
import {Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";
import {Favorite, FavoriteBorder} from "@material-ui/icons";
import {saveFollowedToShowShow, showIsFollowed} from "../constants/StorageHelper";

class FollowButton extends Component {
    state = {isFollowed: false, ToWatch: false,}

    async componentDidMount() {
        let {show} = this.props;
        try {

            let isFollowed = await showIsFollowed(show.id)
            this.setState({isFollowed})
        } catch (error) {
            console.error("Va tutto in errore showExists", error, error.response)
        }
    }

    saveToJesoo = async (e) => {
        let {show, refreshData} = this.props;
        let isFollowed = e.target.checked

        saveFollowedToShowShow(show, isFollowed, "follow").then(() => {
            this.setState({isFollowed})
        })
        if (refreshData) {
            refreshData();
        }
    }

    render() {
        let {isFollowed} = this.state;
        let {enableLabel} = this.props;

        return (
            !enableLabel ? <Checkbox
                    style={{padding: 0, color: "#BBBBBB"}}
                    icon={<FavoriteBorder fontSize={"large"}/>}
                    checkedIcon={<Favorite fontSize={"large"}/>}
                    checked={!!isFollowed}
                    onChange={this.saveToJesoo}
                    name="checkedB"
                /> :
                <FormControlLabel

                    control={
                        <Checkbox
                            style={{padding: 0, margin: "5px", color: "#BBBBBB"}}
                            icon={<FavoriteBorder fontSize={"large"}/>}
                            checkedIcon={<Favorite fontSize={"large"}/>}
                            checked={!!isFollowed}
                            onChange={this.saveToJesoo}
                            name="checkedB"
                        />
                    }
                    style={{color: "#BBBBBB"}}
                    label={isFollowed ? "Followed" : "Follow"}
                />
        );
    }
}

export default FollowButton;