import {v4 as uuid4} from "uuid";
import {SESSION_GOOGLE_TOKEN} from "./Config";
import {databasePush, db, getEpisodeLink, momentFormat} from "./StorageHelper";
import axios from "axios";
import moment from "moment";

export const importLinkMassive = async (data) => {

    for (let input of data) {
        let {tvShowId, episodeNumber, seasonNumber, showName, episodeName, link} = input;
        if (!tvShowId) {
            tvShowId = input.id || input.idShow;
        }

        await db().show.put({
            idShow: parseInt(tvShowId),
            showName,
            followed: true,
            inputDate: moment().format(momentFormat)
        });
        await db().season.put({
            idShow: parseInt(tvShowId),
            seasonNumber,
            seasonName: showName
        });
        await db().episode.put({
            idShow: parseInt(tvShowId),
            seasonNumber,
            episodeNumber,
            episodeName
        });
        await db().link.put({
            idShow: parseInt(tvShowId),
            seasonNumber,
            episodeNumber,
            link
        });
    }
    localStorage.setItem("myScreenFilter", "1")
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};

export const readSharedLink = async (link) => {
    try {
        let temp = await axios.get(link, {
            headers: {
                "X-Auth-Token": "aE4Luc4LSG6JMGsiC5ewotJEAlMBkPQllICpCa08J"
            },
        });
        let data = JSON.parse(temp.data.paste.sections[0].contents);
        return data;
    } catch (e) {
        console.error("Error reading shared data", e);
        throw e;
    }

}


export const shareAllSeries = async () => {
    let temp = await db().link.toArray();
    try {
        let response = await axios.request({
            url: "https://api.paste.ee/v1/pastes",
            headers: {
                "X-Auth-Token": "aE4Luc4LSG6JMGsiC5ewotJEAlMBkPQllICpCa08J"
            },
            method: "POST",
            data: {
                sections: [
                    {
                        "name": uuid4(),
                        "contents": JSON.stringify(temp)
                    }
                ]
            }
        })
        return "https://quix.altervista.org/i?sl=" + response.data.id
    } catch (e) {
        console.error("Errore durante la generazione dello snippet", e, e.response)
    }
};


export const readSharedData = async (link) => {
    try {
        let temp = await axios.get(link, {
            headers: {
                "X-Auth-Token": "aE4Luc4LSG6JMGsiC5ewotJEAlMBkPQllICpCa08J"
            },
        });
        let output = null;
        let data = JSON.parse(temp.data.paste.sections[0].contents);
        for (let d of data) {
            let {idShow, episodeNumber, seasonNumber, link} = d;
            await db().link.put({
                idShow: parseInt(idShow),
                seasonNumber,
                episodeNumber,
                link
            });
            output = idShow;
        }
        databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
        return output;
    } catch (e) {
        console.error("Error reading shared data", e);
        throw e;
    }

}


export const shareableLinkData = async (tvShowId, seasonNumber, episodeNumbers) => {
    let temp = []
    for (let e of episodeNumbers) {
        temp.push(...await getEpisodeLink(tvShowId, seasonNumber, e))
    }
    try {


        let response = await axios.request({
            url: "https://api.paste.ee/v1/pastes",
            headers: {
                "X-Auth-Token": "aE4Luc4LSG6JMGsiC5ewotJEAlMBkPQllICpCa08J"
            },
            method: "POST",
            data: {
                sections: [
                    {
                        "name": uuid4(),
                        "contents": JSON.stringify(temp)
                    }
                ]
            }
        })
        return "https://quix.altervista.org/i?sh=" + response.data.id
    } catch (e) {
        console.error("Errore durante la generazione dello snippet", e, e.response)
    }
};
