export const primaryColor = "#274C86";
export const secondaryColor = "#BBBBBB";


export const BASE_THEME_CUSTOMIZATION = {


    palette: {
        primary: {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        }
    },
    typography: {
        fontFamily: '"Helvetica Now Text"',
    },
    overrides: {
        MuiTab: {
            textColorSecondary: {
                color: "var(--title-color)"
            }
        },
        MuiDropzoneArea:{
            root:{
                flex:1,
                overflowY: "auto"
            }
        },
        MuiDialogTitle: {
            root: {
                borderBottom: "1px solid #E6E6E6",
                opacity: 1,
                cursor: "move",
                maxHeight: "80px!important",
                padding: "10px 15px"
            }
        },
        MuiDialogContent: {
            root: {
                fontSize: "10px",
                overflow: "auto"
            }
        },
        MuiDialogActions: {
            root: {
                borderTop: "1px solid #E6E6E6",
                opacity: 1,
                padding: "15px 40px 20px"
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: `2px solid var(--first-color)`,
                },
                '&$focused:after': {
                    borderBottomColor: "var(--first-color)",
                },
                '&$error:after': {
                    borderBottomColor: `var(--second-color)`,
                },
                '&:before': {
                    borderBottom: `1px solid var(--first-color)`,
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: `2px solid var(--first-color)`,
                },
                '&$disabled:before': {
                    borderBottom: `1px dotted var(--first-color)`,
                }
            },
        },
        MuiListItem: {
            root: {
                "&$selected": {backgroundColor: "transparent"},
                "&$selected:hover": {backgroundColor: "transparent"}
            }
        },
        MuiMenuItem: {
            root: {
                "&:hover": {
                    backgroundColor: "transparent",
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: primaryColor,
                fontSize: "large"
            }
        },
        MuiButton: {
            outlinedSizeLarge: {
                fontWeight: "bold",
                border: "2px solid " + primaryColor
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "none"
                },
            }
        },
        MuiPaper: {
            root: {
                color: "#FFFFFF",
                backgroundColor: "#1F2631"
            }
        },

        MuiFilledInput: {
            input: {
                padding: "5px",
                fontSize: "18px",
                color: "var(--text-color)"
            },
        },
        MuiInputBase: {
            root: {
                color: "var(--text-color)",
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "var(--first-color)",

                },
                "&::placeholder": {
                    color: "var(--text-color)"

                }
            },
            formControl: {
                border: "1px solid #BBBBBB",
            },
            inputMultiline: {
                whiteSpace: "nowrap"
            },
            input: {
                padding: "5px",
                fontSize: "18px",
                color: "var(--text-color)"
            },
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "var(--first-color)",
                },
                borderRadius: "10px"
            },
        },
        MuiFormLabel: {
            filled: {
                color: "#AAAAAA"
            }
        },
        '&.Mui-selected': {
            outline: 'none',
        }
    }
};
