import axios from "axios";
import wrapper from 'axios-cache-plugin'


const AUTHORIZATION_PREFIX = "Bearer ";

const BaseAxios = axios.create({

});

// 2. Log della richiesta nell api
BaseAxios.interceptors.request.use(config => {
    console.debug("REQUEST", config.data, config);
    return config;
});

// 3. Log della risposta dall api
BaseAxios.interceptors.response.use(
    response => {
        console.debug("RESPONSE", response);
        return response;
    },
    error => {
        console.error("ERROR_RESPONSE", error, error.response);
        return Promise.reject(error);
    }
);

export default wrapper(BaseAxios,
    {
        maxCacheSize:100,
        ttl:86400000
    });

