import React, {Component} from 'react';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Star, StarBorder} from "@material-ui/icons";
import {saveFollowedToShowShow, showIsToWatch} from "../constants/StorageHelper";

class ToWatchButton extends Component {
    state = {ToWatch: false, isFollowed: false}

    async componentDidMount() {
        let {show} = this.props;
        try {
            let ToWatch = await showIsToWatch(show.id)

            this.setState({ToWatch})
        } catch (error) {
            console.error("Va tutto in errore showExists", error, error.response)
        }
    }

    saveToJesoo = async (e) => {
        let {show, refreshData} = this.props;
        let ToWatchNew = e.target.checked
        let tmp = "T"
        if (ToWatchNew) {
            tmp = "W"
        }

        saveFollowedToShowShow(show, tmp, "toWatch").then(() => {
            this.setState({ToWatch: ToWatchNew})
        })
        if (refreshData) {
            refreshData();
        }
    }


    render() {
        let {ToWatch} = this.state;
        let {enableLabel} = this.props;

        return (
            !enableLabel ? <Checkbox
                    style={{padding: 0, color: "#BBBBBB"}}
                    icon={<StarBorder fontSize={"large"}/>}
                    checkedIcon={<Star fontSize={"large"}/>}
                    checked={!!ToWatch}
                    onChange={this.saveToJesoo}
                    name="checkedB"
                /> :
                <FormControlLabel
                    control={
                        <Checkbox
                            style={{padding: 0, margin: "5px", color: "#BBBBBB"}}
                            icon={<StarBorder fontSize={"large"}/>}
                            checkedIcon={<Star fontSize={"large"}/>}
                            checked={!!ToWatch}
                            onChange={this.saveToJesoo}
                            name="checkedB"
                        />
                    }
                    style={{color: "#BBBBBB"}}
                    label={ToWatch ? "Watching" : "To watch"}
                />
        );
    }
}

export default ToWatchButton;