import React, {Component} from 'react';
import style from "../assets/BaseRouter.module.css";
import QRCode from "qrcode.react";
import {v4 as uuid4} from "uuid";
import QrReader from "react-qr-reader";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Airplay from "@material-ui/icons/Airplay";
import DeleteForever from "@material-ui/icons/DeleteForever";
import AddToHomeScreen from "@material-ui/icons/AddToHomeScreen";
import Peer from 'peerjs';
import {GlobalContext} from "../constants/CommonConstants";
import {withRouter} from "react-router-dom";
import {peerOptions, peerOptionsDefault} from "../screens/ShareScreen/ShareScreen";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {Label, RowContainer} from "./ComponentManager/ComponentManager";

class QrHandler extends Component {



    state = {
        enableQr: false,
        enableQrReader: false,
        enableShowConnection: false,

    }

    componentDidMount() {
        let connection = this.contextUnwrap("connection")

        if (connection) {
            this.setState({enableShowConnection: true})
        }
    }

    handleScan = data => {
        if (data) {
            let connection = this.contextUnwrap("connection")
            if (!connection) {
                this.contextWrapperMultiple({hostId: data}, () => this.setupClient())
            }
        }
    }

    handleError = err => {
        try {

        } catch (e) {
            console.error("Errore durante la lettura del qr", err)
        }
    }

    contextWrapperMultiple = (obj, callback) => {
        let peerVar = this.context.getGlobalVariable("peerVar")
        this.context.setGlobalVariables({peerVar: {...peerVar, ...obj}}, callback)
    }

    contextUnwrap = (key) => {
        let peerVar = this.context.getGlobalVariable("peerVar")
        return peerVar[key]
    }
    contextUnwrapMultiple = () => {
        return this.context.getGlobalVariable("peerVar")
    }

    setupClient = () => {
        let clientId = this.contextUnwrap("clientId")
        let peer = this.contextUnwrap("peer")
        if (!clientId) {
            clientId = uuid4()
            peer = new Peer(clientId, peerOptions)

        }
        this.setupPeers(peer)
    }
    setupPeers = (peer) => {
        try {
            // on open will be launch when you successfully connect to PeerServer
            peer.on('open', (id) => {
                let obj = {peer}
                let isHost = this.contextUnwrap("isHost")
                let callback = () => {
                }
                if (isHost) {
                    obj = {...obj, hostId: id}
                } else {
                    obj = {...obj, clientId: id}
                    callback = () => {
                        let hostId = this.contextUnwrap("hostId")
                        let conn = peer.connect(hostId);
                        conn.on('open', () => {
                            let data = {
                                action: "CONN",
                                peerId: id,
                            }
                            conn.send(data)
                            this.contextWrapperMultiple({connection: conn})

                        });
                    }
                }
                this.contextWrapperMultiple(obj, callback)
            });
            peer.on("error", (e) => {
                console.error("errore connessione peer", e, e.response)
                if (e && String(e).includes("Lost connection to server")) {
                    let clientId = this.contextUnwrap("clientId")
                    if (!clientId) {
                        clientId = uuid4()
                    }
                    let peer = new Peer(clientId, peerOptionsDefault)
                    this.setupPeers(peer)
                } else {
                    console.error("The service is not available right now, we are sorry, try later!")
                }
            })

            peer.on('disconnect', () => {
                //TODO gestire la disconnessione dalla stanza comunicandolo a tutti gli altri
                // Se il creatore della stanza esce -> se era l'host lo passa ad uno a caso, gli altri continuano a guardare ma nessuno può joinare
                // Se esce uno stronzo random lo comunica agli altri e lo leva dalla lista utenti in stanza e dalle connessioni
            })


            peer.on('connection', (conn) => {
                conn.on('open', () => {
                    // Receive messages
                    conn.on("error", (e) => {
                        console.error("errore apertura connessione", e)
                    })
                    conn.on('data', (data) => {
                        let {connection, peerId, isHost} = this.contextUnwrapMultiple();
                        // Se non ho una connessione aperta con questo peer, procedo a connettermi con lo stesso
                        if (!connection) {
                            // I peer sono connessi e sono pronti a scambiare i dati
                            let connectToReceivedPeer = peer.connect(data.peerId);
                            connectToReceivedPeer.on('open', () => {
                                // La connessione è stata aperta

                                if (data.action === "CONN") {
                                    let newPeer = {
                                        action: "CONNECTED",
                                        peerId
                                    };
                                    connectToReceivedPeer.send(newPeer)
                                }
                                this.contextWrapperMultiple({connection: connectToReceivedPeer}, () => this.setState({enableShowConnection: true}))
                            });
                        }

                        // Sono arrivati dei dati da un peer, li gestisco
                        this.handleTransaction(data);
                    });
                });
            });

        } catch (e) {
            console.error("ERROR: ", e)

        }

    }

    handleTransaction = (data) => {

        if (data) {
            let {action, peerId, src, value} = data;
            let isHost = this.contextUnwrap("isHost")
            let player = this.contextUnwrap("player")

            switch (action) {
                case "CONN":
                    this.props.history.push("/qv")
                    break;
                case "CONNECTED":
                    this.setState({enableShowConnection: true})
                    break;
                case "LINK":
                    this.contextWrapperMultiple({selectedLink: src})
                    break;
                case "PLAY":
                    player.play()
                    break;
                case "PAUSE":
                    player.pause()
                    break;
                case "BACK":
                    player.currentTime(player.currentTime() - value)
                    break;
                case "FORWARD":
                    player.currentTime(player.currentTime() + value)
                    break;
                case "CLOSE_CONNECTION":
                    if (isHost) {
                        this.props.history.push("/")
                        this.resetConnection()
                    }
                    break;
                default:
                    break;
            }

        }


    }

    resetConnection = () => {
        this.contextUnwrap("connection").close()
        this.context.setGlobalVariables({
            peerVar: {
                selectedLink: "",
                hostId: null,
                clientId: null,
                isHost: false,
                connection: null,
                peer: null
            }
        }, () => {
            this.setState({enableShowConnection: false})
        })
    }

    confirmCloseConnection = () => {
        let {setDialogState, resetDialogState} = this.context

        setDialogState({
            dialogIsOpen: true,
            paperSizeIsSmallest: true,
            title: "Close connection",
            content: <Label font={"--text-font-h5"}>{"Are you sure you want to close the connection?"}</Label>,
            rightButtonText: "Terminate",
            leftButtonText: "Cancel",
            handleClose: () => resetDialogState(),
            handleSubmit: () => {
                let data = {
                    action: "CLOSE_CONNECTION",
                }
                let connection = this.contextUnwrap("connection")
                connection.send(data)
                setTimeout(() => this.resetConnection(),
                    1000
                )
                resetDialogState()
            },

        })
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget})
    }

    handleClose = () => {

        this.setState({anchorEl: null})
    }

    render() {
        let {enableQr, enableQrReader, enableShowConnection, anchorEl, camera} = this.state
        let hostId = this.contextUnwrap("hostId")
        let isHost = this.contextUnwrap("isHost")
        let menuOpen = Boolean(anchorEl);
        return (
            enableShowConnection ?
                <RowContainer
                    className={style.menuItemContainer}
                    justifyContent={"flex-start"}
                > <RowContainer
                    onClick={isHost ? () => {
                        this.props.history.push("/qv")
                    } : () => {
                    }}
                    justifyContent={"flex-start"}
                >
                    <Airplay/>
                    <Label cursor={"pointer"} font={"--text-font-h5"}
                           className={style.menuItemContainerSelected}
                    >{
                        isHost ? "Host" : "Client"
                    }</Label>
                </RowContainer>
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        onClick={() => {
                            this.confirmCloseConnection()
                        }}>
                        <DeleteForever/>
                    </Button>
                </RowContainer>
                : <>
                    <RowContainer
                        className={style.menuItemContainer}
                    >
                        <Button color={"secondary"} variant={"contained"} onClick={() => {

                            let hostId = this.contextUnwrap("hostId")
                            let peer = this.contextUnwrap("peer")
                            if (!hostId) {
                                hostId = uuid4()
                                peer = new Peer(hostId, peerOptions)
                                this.contextWrapperMultiple({peer, hostId, isHost: true}, () => this.setupPeers(peer))
                            }
                            this.setState({
                                enableQr: !this.state.enableQr,
                                enableQrReader: false,
                            },)
                        }}><AddToHomeScreen/></Button>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <div>
                                    <Button style={{flex: 1}} color={"secondary"} variant={"contained"}
                                            aria-controls="simple-menu" aria-haspopup="true"
                                            {...bindTrigger(popupState)}

                                            onClick={this.handleClick}

                                    ><PhotoCamera/>
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        keepMounted

                                        {...bindMenu(popupState)}
                                        anchorEl={anchorEl}
                                        open={menuOpen}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            horizontal: 'left',
                                            vertical: "bottom"
                                        }}

                                    >
                                        <MenuItem onClick={() => {
                                            this.handleClose();
                                        }}>
                                            <Button
                                                fullWidth
                                                color={"secondary"}
                                                variant={"contained"}
                                                onClick={() => {
                                                    this.contextWrapperMultiple({isHost: false}, () => this.setState({
                                                        enableQrReader: true,
                                                        enableQr: false,
                                                        camera: "user"
                                                    }))
                                                }}
                                            >
                                                Frontal camera
                                            </Button>

                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            this.handleClose();
                                        }}>
                                            <Button
                                                fullWidth
                                                color={"secondary"}
                                                variant={"contained"}
                                                onClick={() => {
                                                    this.contextWrapperMultiple({isHost: false}, () => this.setState({
                                                        enableQrReader: true,
                                                        enableQr: false,
                                                        camera: "environment"
                                                    }))
                                                }}
                                            >
                                                Back camera
                                            </Button>
                                        </MenuItem>

                                    </Menu>
                                </div>
                            )}</PopupState>

                    </RowContainer>
                    {enableQr && <RowContainer
                        className={style.menuItemContainer}
                        justifyContent={"center"}
                    >
                        <QRCode
                            size={200}
                            includeMargin={true}
                            value={hostId}/>
                    </RowContainer>}
                    {enableQrReader &&
                    <QrReader
                        facingMode={camera || "user"}
                        delay={500}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{width: '100%'}}
                    />
                    }
                </>
        );
    }
}

QrHandler.contextType = GlobalContext;
export default withRouter(QrHandler);

