import Label from "./components/Label";
import ContentMainContainer from "./containers/ContentMainContainer";
import ScrollableContainer from "./containers/ScrollableContainer";
import RowContainer from "./containers/RowContainer";
import ColumnContainer from "./containers/ColumnContainer";
import AdvancedGridContainer from "./containers/AdvancedGridContainer";

export const randomIdGenerator = (id, name) => {
    let output;
    if (!id) {
        id = Math.floor((Math.random() * (9999 - 1) + 1) * 10);
        output = name + id;
    } else {
        output = id+Math.floor((Math.random() * (9999 - 1) + 1) * 10) + name;
    }
    return output;
};

export {
    Label,
    ContentMainContainer,
    ScrollableContainer,
    RowContainer,
    ColumnContainer,
    AdvancedGridContainer
}