import React, {Component} from 'react';

class VideoPlayerCustomUiContainer extends Component {
    render() {
        let{children,hideUIonPlay,fullWidth}=this.props
        return (
            hideUIonPlay &&<div style={{
                display: "flex",
                position: "absolute",
                bottom: "40px",
                left: 0,
                width: fullWidth ? "100vw" : "auto",
                flexDirection: "column",
            }}
            >
                {children}
            </div>
        );
    }
}

export default VideoPlayerCustomUiContainer;