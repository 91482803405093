import React, {Component} from 'react';
import Fab from "@material-ui/core/Fab"
import Settings from "@material-ui/icons/Settings"
import {RowContainer} from "../ComponentManager/ComponentManager";

class VideoPlayerButtonsUi extends Component {


    render() {
        let {children,enableSettings,editStateFromChild} = this.props
        return (
            <RowContainer customStyle={"width:100vw;margin-bottom:10px;"} justifyContent={"flex-start"}>
                <Fab color="primary" aria-label="add"
                     style={{width: "56px", height: "56px", marginLeft: "10px", zIndex: 1000}}
                     onClick={() => editStateFromChild({
                         enableSettings: !enableSettings,
                         enableEpisodeList: false
                     })}
                >
                    <Settings/>
                </Fab>
                {children}
            </RowContainer>
        );
    }
}

export default VideoPlayerButtonsUi;