import React, {Component} from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import {v4 as uuid4} from "uuid";
import {Label} from "./ComponentManager/ComponentManager";

export default class UploadButton extends Component {
    state = {}

    render() {
        let {
            sizeXS = 12, uploadButtonOnClick = () => {
            }, removeButtonOnClick = () => {
            }, customSize
        } = this.props
        let {uploadValue} = this.state;

        let value = uploadValue;
        if (uploadValue === "") {
            value = uploadValue
        }
        return <Grid
            key={uuid4}
            id={"uploadButton"}
            container
            spacing={2}
            item xs={sizeXS}
            {...customSize}>
            <Grid item xs={12} sm={12} md={4} style={{display: "flex", justifyContent: "flex-start"}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    component={"label"}
                >
                    ADD
                    <input
                        accept="file/!*,application/*,audio/*,video/*,image/*"
                        style={{display: "none"}}
                        id="icon-button-file"
                        type="file"
                        name="imageProfilePath"
                        onChange={async (event) => {
                            let uploadValue = await uploadButtonOnClick(event);
                            this.setState({uploadValue})
                        }}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                    />
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} style={{display: "flex", justifyContent: "flex-start"}}>
                <Label
                    font={"--text-font-h8"}
                    ellipsis={3}
                >{value}</Label>
            </Grid>
            {value && value !== "" &&
            <Grid item xs={12} sm={12} md={4} style={{display: "flex", justifyContent: "flex-start"}}>
                <Button onClick={async (event) => {
                    let uploadValue = await removeButtonOnClick(event);
                    this.setState({uploadValue})
                }} variant="text"
                        color={"secondary"}>
                    Remove</Button>
            </Grid>
            }
        </Grid>
    }
}