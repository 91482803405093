import React from 'react';
import queryString from "query-string";
import BaseAxios from "../../constants/ApiHelpers/BaseAxios";
import {TreeItem, TreeView} from "@material-ui/lab";
import {ChevronRight, ExpandMore} from "@material-ui/icons";
import {v4 as uuid4} from "uuid";
import {withRouter} from "react-router-dom";
import BaseComponent from "../../interfaces/BaseComponent";
import HeaderTop from "../../components/HeaderTop";
import QuickVideoPage from "../QuickVideoPage/QuickVideoPage";
import {getUncategorized} from "../../constants/StorageHelper";
import {ColumnContainer} from "../../components/ComponentManager/ComponentManager";

class ExternalMediaserver extends BaseComponent {
    state = {
        treeData: [],
        mediaserver: queryString.parse(this.props.location.search).m
    };

    async componentDidMount() {
        await getUncategorized()
        await this.viewFiles()
        let scrollingGrid = document.getElementById("container")
        scrollingGrid.addEventListener("scroll", this.handleHeaderOnScroll)
    }


    viewFiles = async () => {
        try {
            this.setLoading(true, "ExternalMediaserver - viewFiles")
            let {mediaserver} = this.state;
            let treeData = [];
            let searchParams = new URLSearchParams(mediaserver.substr(mediaserver.indexOf("?")));
            let embyBasePath = new URL(mediaserver.substr(0, mediaserver.indexOf("?") - 1));
            let response = await BaseAxios.get(embyBasePath + "/Items?Recursive=true&Fields=ProviderIds,MediaStreams,Path&SortBy=Sortname&MediaType=video&Filters=IsNotFolder&ExcludeItemTypes=Person&IncludeItemTypes=series,movie,episode", {
                headers: {
                    "X-Emby-Token": searchParams.get("token"),
                    "Authorization": "Emby UserId=\"" + uuid4() + "\",Client=\"" + navigator.oscpu + "\", Device=\"" + MediaDeviceInfo.label + "\", DeviceId=\"" + MediaDeviceInfo.deviceId + "\", Version=\"1.0.0.0\""
                }
            });
            for (let x of response.data.Items) {
                if(x.Path) {
                    let paths = x.Path.split("/").slice(-3)
                    for (let i = 0; i < paths.length; i++) {
                        let path = paths[i];
                        if (i === 0) {
                            let element = this.pathHandler(treeData, path)
                            if (!element) {
                                treeData.push({
                                    name: path,
                                    parent: null,
                                    files: [],
                                    directories: []
                                })
                            }
                        } else if (i < paths.length - 1) {
                            let element = this.pathHandler(treeData, path);
                            if (!element) {
                                let parentElement = this.pathHandler(treeData, paths[i - 1]);
                                parentElement.directories.push({
                                    name: path,
                                    parent: paths[i - 1],
                                    files: [],
                                    directories: []
                                })
                            }
                        } else {
                            let parentElement = this.pathHandler(treeData, paths[i - 1]);
                            parentElement.files.push(x)
                        }
                    }
                }else{
                    console.warn("File missing path: ",x)
                }
            }

            this.setState({treeData});
        } catch (e) {
            console.error("ERRORE: ", e);
        } finally {
            this.setLoading(false, "ExternalMediaserver - viewFiles")
        }
    }

    pathHandler = (treeData, name) => {
        for (let i of treeData) {
            if (i.name == name) {
                return i;
            } else {
                let result = this.pathHandler(i.directories, name)
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    renderTree = (tree) => {
        let output = [];
        let {mediaserver} = this.state;
        let searchParams = new URLSearchParams(mediaserver.substr(mediaserver.indexOf("?")));
        let embyBasePath = new URL(mediaserver.substr(0, mediaserver.indexOf("?") - 1));
        for (let directory of tree.directories) {
            output.push(this.renderTree(directory));
        }
        tree.files.sort((a, b) => {//TODO capire se è possibile ordinarli in un qualche modo
            try {
                let tmp = a.Name.replace(/ +/g, "").split("-")
                let tmp1 = b.Name.replace(/ +/g, "").split("-")
                if (tmp.length > 1 && tmp1.length > 1) {
                    return tmp[1] - tmp1[1]
                }
                tmp = a.Name.replace(/ +/g, "").split("_")
                tmp1 = b.Name.replace(/ +/g, "").split("_")
                if (tmp.length > 0 && tmp1.length > 0) {
                    return tmp[0] - tmp1[0]
                }

                tmp = a.Name.replace(/ +/g, "").split(".")
                tmp1 = b.Name.replace(/ +/g, "").split(".")
                if (tmp.length > 3 && tmp1.length > 3) {
                    return tmp[2] - tmp1[2]
                }

                tmp = a.Name.replace(/ +/g, "").split(".")
                tmp1 = b.Name.replace(/ +/g, "").split(".")
                if (tmp.length > 0 && tmp1.length > 0 && tmp.length < 3 && tmp1.length < 3) {
                    return tmp[0] - tmp1[0]
                }


            } catch (e) {
                console.error("errore durante il sorting", e)
            }
        })
        for (let file of tree.files) {
            output.push(<TreeItem
                key={"nodeItem" + uuid4()}
                nodeId={uuid4()} onLabelClick={() => {
                    //TODO Far seleziore l'audio prendendo il campo "MediaStreams" dall'API embyBasePath + /Items?Fields=MediaStreams&Ids=2995
                this.setState({selectedLink: embyBasePath + "/Videos/"+file.Id+"/master.m3u8?api_key="+searchParams.get("token")+"&videocodec=h264&audiocodec=mp3,aac&AudioStreamIndex=1"})
            }} label={<h5>{file.Name}</h5>}/>)
        }


        return <TreeItem nodeId={uuid4()} key={uuid4()} label={<h5>{tree.name}</h5>}>
            {output}
        </TreeItem>
    }


    handleHeaderOnScroll = () => {
        let {lastScrollTop} = this.state;
        let header = document.getElementById("hidableHeader")
        let scrollingGrid = document.getElementById("container")
        let scrollTop = scrollingGrid.scrollTop;
        if (scrollTop > lastScrollTop) {
            header.style.top = "-73px"
        } else {
            header.style.top = "0"
        }
        this.state.lastScrollTop = scrollTop

    }

    render() {
        let {treeData, selectedLink} = this.state;

        if (!treeData) {
            return null
        }

        return selectedLink ?
            <QuickVideoPage
                externalMediaserver={() => {
                    this.setState({selectedLink: null})
                }}
                selectedLink={selectedLink}
            />
            :
            <ColumnContainer id={"container"}
                             customStyle={"width:100%;padding-top:100px;padding-left:5px;overflow:auto;"}>
                <HeaderTop id={"hidableHeader"}/>
                <TreeView
                    style={{width: "90%"}}
                    defaultCollapseIcon={<ExpandMore color={"secondary"}/>}
                    defaultExpandIcon={<ChevronRight color={"secondary"}/>}
                >
                    {treeData.map(x => this.renderTree(x))}
                </TreeView>

            </ColumnContainer>
    }
}

export default withRouter(ExternalMediaserver);