import React, {Component} from 'react';
import style from "../assets/BaseRouter.module.css";
import logo from "../assets/images/Logo.png";
import packageJson from "../../package.json";
import styled from "styled-components";
import {Close, Dns, People, PolicyOutlined, Search, Settings, Storage, VideoLibrary,ImportContacts} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import LoginGoogle from "./LoginGoogle";
import {withRouter} from "react-router-dom";
import {GlobalContext} from "../constants/CommonConstants";
import QrHandler from "./QrHandler";
import {getMediaServer} from "../constants/StorageHelper";
import {ColumnContainer, Label, RowContainer} from "./ComponentManager/ComponentManager";

const Logo = styled.img`
  height: 60px;
`;


class HeaderTop extends Component {

    state = {
        drawerIsOpen: false,
        menuListIsOpen: false,
        sharePageListIsOpen: true,
        mediaServers: []
    }

    async componentDidMount() {
        let mediaServers = await getMediaServer()

        this.setState({mediaServers})
    }

    componentDidUpdate(prevProps) {
        let {renderShareRoomContent} = this.props
        if (renderShareRoomContent && !prevProps.renderShareRoomContent) {
            this.toggleDrawer(true)
        }
    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({drawerIsOpen: open});
    };

    renderMenuList = () => {
        let {mediaServers} = this.state
        let path = window.location.pathname
        return <ColumnContainer className={style.drawer}>
            <RowContainer className={style.loginGoogleContainer} justifyContent={"center"}>
                <LoginGoogle/>
            </RowContainer>
            <QrHandler/>
            {navigationStruct.map((item, index) => {

                let checkSelected = path === item.value
                return <RowContainer key={"Navigation" + index}
                                     onClick={() => {
                                         this.props.history.push(item.value)
                                     }}
                                     className={checkSelected ? style.menuItemContainerSelected : style.menuItemContainer}
                                     justifyContent={"flex-start"}>
                    {item.icon}
                    <Label cursor={"pointer"} font={"--text-font-h5"}>{item.label}</Label>
                </RowContainer>
            })}
            {mediaServers.length > 0 && <>
                <RowContainer
                    className={style.menuItemContainerExternal}
                    justifyContent={"flex-start"}
                >
                    <Dns/>
                    <Label font={"--text-font-h5"}>
                        External mediaserver
                    </Label>
                </RowContainer>
                {mediaServers.map((media, index) => {
                    return <RowContainer
                        key={"Mediaservers" + index}
                        onClick={() => {
                            this.props.history.push("/ms?m=" + media.host)
                        }}
                        customStyle={"margin-left:20px;width:90%;"}
                        className={path === "/ms" ? style.menuItemContainerSelected : style.menuItemContainer}
                        justifyContent={"flex-start"}
                    >
                        <Storage/>
                        <Label cursor={"pointer"} font={"--text-font-h5"}>{(new URL(media.host)).hostname.replace('www.', '')}</Label>
                    </RowContainer>
                })}
            </>}
        </ColumnContainer>
    }

    renderShareRoomMenu = () => {
        let {sharePageListIsOpen, menuListIsOpen} = this.state
        let {renderShareRoomContent} = this.props
        return <>

            <Button
                style={{margin:"10px"}}
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                    this.setState({
                        sharePageListIsOpen: !this.state.sharePageListIsOpen,
                        menuListIsOpen: !this.state.menuListIsOpen,

                    })
                }}>
                {!menuListIsOpen ? "Open QuiX Menù" :
                    "Open Room Menù"}
            </Button>
            <Collapse in={menuListIsOpen} timeout={1000}>
                {this.renderMenuList()}
            </Collapse>


            <Collapse in={sharePageListIsOpen} timeout={1000}>
                {renderShareRoomContent}
            </Collapse>

        </>
    }

    render() {
        let {drawerIsOpen} = this.state
        let {renderShareRoomContent, history} = this.props

        return (
            <nav className={style.header} id={"hidableHeader"}>
                <a
                    onClick={() => {
                        history.push("/")
                    }}
                    style={{textDecoration: "none", display: "flex", alignItems: "flex-end"}}>
                    <Logo src={logo}
                          alt={"logo"}/>
                    <Label font={"--text-normal-12"}
                           customStyle={{
                               container: "position: absolute;left: 105px;top: 50px;"
                           }}
                    >v{packageJson.version}</Label>
                </a>
                <LoginGoogle
                    disableLogout={() => {
                        this.setState({drawerIsOpen: true})
                    }}/>
                <Drawer variant="temporary"
                        anchor="right"
                        open={drawerIsOpen} onClose={this.toggleDrawer(false)}>
                    <Button
                        style={{margin:"10px"}}
                        color={"secondary"}
                        onClick={this.toggleDrawer(false)}
                    startIcon={<Close color={"secondary"} fontSize={"large"}/>}
                    >
                        Close menu
                    </Button>
                    {renderShareRoomContent ?
                        this.renderShareRoomMenu()

                        :
                        this.renderMenuList()
                    }
                </Drawer>
            </nav>
        );
    }
}


HeaderTop.contextType = GlobalContext;
export default withRouter(HeaderTop);


const navigationStruct = [
    {
        label: "My collection",
        value: "/",
        icon: <VideoLibrary/>
    },
    {
        label: "New series",
        value: "/s",
        icon: <Search/>
    },
    // {
    //     label: "New manga",
    //     value: "/m",
    //     icon: <ImportContacts/>
    // },
    {
        label: "Friends room",
        value: "/r",
        icon: <People/>
    }, {
        label: "Settings",
        value: "/c",
        icon: <Settings/>
    },
    {
        label: "Privacy Policy",
        value: "/pp",
        icon: <PolicyOutlined/>
    },
]