import React from "react";
import {Provider} from "react-redux";
import store from "./redux/store";
import {BreakpointProvider} from "react-socks"
import {IonApp} from "@ionic/react"
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {BASE_THEME_CUSTOMIZATION} from "./assets/theme/ThemeConstants";
import packageJson from "../package.json";
import CacheBuster from "./CacheBuster";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import BaseRouter from "./routing/BaseRouter";
import {GlobalContext} from "./constants/CommonConstants";

import {IntlProvider} from "react-intl";
import en from "./translations/en.json"
import {SETTINGS_AUTO_SYNC} from "./constants/Config";
import CommonDialog from "./components/CommonDialog/CommonDialog";

global.appVersion = packageJson.version;
const customTheme = createMuiTheme(BASE_THEME_CUSTOMIZATION);

class App extends React.Component {

    state = {
        language: "en",
        gb: {
            messages: {en: en},
            peerVar: {
                selectedLink: "",
                hostId: null,
                clientId: null,
                isHost: false,
                connection: null,
                peer: null,
                player: null
            }
        },
        dialogProps: {dialogIsOpen: false},
        loadingState: true,
    };

componentDidMount() {
    //TODO Creare impostazioni
    sessionStorage.setItem(SETTINGS_AUTO_SYNC, "true");
}

    resetDialogState = () => {
        this.setState(p => {
            //TODO trovare una soluzione più elegante
            // 0. Prima la nascondo
            p.dialogProps.dialogIsOpen = false;
            // 1. Dopo 200ms la resetto, questo per evitare il glitch grafico che avviene se resetto e nascondo tutto insieme
            setTimeout(() => {
                this.setState({
                    dialogProps: {
                        dialogIsOpen: false
                    }
                })
            }, 200)
            return p
        })
    };


    showDialog = (state, callback) => {
        this.setState(p => {
            p.dialogProps.dialogIsOpen = state;
            return p;
        }, () => {
            if (callback) {
                callback();
            }
        })
    };

    setDialogState = ({
                          dialogIsOpen, rightButtonColor, customStyle,
                          leftButtonColor, leftButtonVariant, rightButtonVariant,
                          handleClose, paperSizeIsSmall, paperSizeIsFullScreen, handleSubmit, leftButtonText,
                          rightButtonText, leftButtonOnClick, rightButtonOnClick, title, content,
                          customContent, customActions, rightStartIcon, leftStartIcon, paperSizeIsSmallest, paperSizeIsBig,
                      }, callback) => {
        this.showDialog(false, () => {
            this.setState({
                dialogProps: {
                    dialogIsOpen,
                    handleClose,
                    handleSubmit,
                    leftButtonText,
                    rightButtonText,
                    leftButtonOnClick,
                    rightButtonOnClick,
                    paperSizeIsBig,
                    title,
                    content,
                    customContent,
                    customActions,
                    rightStartIcon,
                    leftStartIcon,
                    rightButtonColor,
                    leftButtonColor,
                    paperSizeIsSmall,
                    paperSizeIsFullScreen,
                    paperSizeIsSmallest,
                    rightButtonVariant,
                    leftButtonVariant,
                    customStyle,
                }
            }, callback)
        })

    };
    setGlobalVariable = (key, value, callback = () => {
    }) => {
        console.debug("CHeck the change state: ", key, value, {gb: {[key]: value}});
        this.setState({gb: {...this.state.gb, [key]: value}}, callback);
    };
    setGlobalVariables = (obj, callback = () => {
    }) => {
        console.debug("CHeck the change state: ", obj);
        this.setState({gb: {...this.state.gb, ...obj}}, callback);
    };

    getGlobalVariable = key => {
        console.debug("CHeck the change state: GET ", key, this.state.gb);
        return this.state.gb[key];
    };
    setProfileData = (data) => {
        this.setState({gb: {...this.state.gb, profileData: data}});
    };
    getLanguage = () => {
        return this.state.language;
    };
    setLanguage = language => {
        this.setState({language});
    };


    render() {
        let {
            dialogProps: {
                dialogIsOpen, handleClose, handleSubmit,
                leftButtonText, rightButtonColor, customStyle,
                leftButtonColor, rightButtonVariant, paperSizeIsBig, leftButtonVariant, rightButtonText, paperSizeIsSmall, paperSizeIsFullScreen, paperSizeIsSmallest, leftButtonOnClick, rightButtonOnClick, title, content, customContent, customActions, rightStartIcon, leftStartIcon
            }, gb
        } = this.state;

        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <IntlProvider
                            onError={async (err) => {
                                try {

                                } catch (e) {
                                    console.error("ERRORE traduzione", e.response)
                                }
                            }}
                            locale={this.getLanguage()}
                            messages={gb.messages[this.getLanguage()]}
                        >
                            <Provider store={store}>
                                <ThemeProvider theme={customTheme}>
                                    <BreakpointProvider>
                                        <GlobalContext.Provider
                                            value={{
                                                setGlobalVariable: this.setGlobalVariable,
                                                setGlobalVariables: this.setGlobalVariables,
                                                getGlobalVariable: this.getGlobalVariable,
                                                setDialogState: this.setDialogState,
                                                resetDialogState: this.resetDialogState,
                                                showDialog: this.showDialog,
                                                setProfileData: this.setProfileData,
                                                gb
                                            }}
                                        >
                                            <IonApp>
                                                <BaseRouter/>
                                            </IonApp>
                                            <CommonDialog
                                                paperSizeIsBig={paperSizeIsBig}
                                                open={dialogIsOpen}
                                                handleClose={handleClose}
                                                handleSubmit={handleSubmit}
                                                leftButtonText={leftButtonText}
                                                rightButtonText={rightButtonText}
                                                leftButtonOnClick={leftButtonOnClick}
                                                rightButtonOnClick={rightButtonOnClick}
                                                title={title}
                                                content={content}
                                                customContent={customContent}
                                                customActions={customActions}
                                                rightStartIcon={rightStartIcon}
                                                leftStartIcon={leftStartIcon}
                                                paperSizeIsSmall={paperSizeIsSmall}
                                                paperSizeIsSmallest={paperSizeIsSmallest}
                                                paperSizeIsFullScreen={paperSizeIsFullScreen}
                                                rightButtonVariant={rightButtonVariant}
                                                leftButtonVariant={leftButtonVariant}
                                                rightButtonColor={rightButtonColor}
                                                leftButtonColor={leftButtonColor}
                                                customStyle={customStyle}
                                            />
                                        </GlobalContext.Provider>
                                    </BreakpointProvider>
                                </ThemeProvider>
                            </Provider></IntlProvider>);
                }}
            </CacheBuster>
        );
    }
}

export default App;

