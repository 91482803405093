import React, {Component} from "react";
import {useCurrentWidth} from "react-socks";

import SnackBar from "../components/SnackBar.js";
export const getFillWidth = () => {
    try {
        if (getBrowserInfo().name.toLowerCase().includes("firefox")) {
            return "-moz-available";
        } else {
            return "-webkit-fill-available";
        }
    } catch (e) {
        console.error("Failed to identify the browser: ", e);
        return "-webkit-fill-available";
    }
}
export const getBrowserInfo = () => {
    let ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE ', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/)
        if (tem != null) {
            return {name: 'Opera', version: tem[1]};
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0] || "",
        version: M[1]
    };
}
class InterfaceComponent extends Component {
    fields = [];
    deniedAccessMobile;
    deniedAccessDesktop;
    loadingSystem;
    loading = false;
    snackBar = {
        sb: {
            vertical: "top",
            horizontal: "right",
            open: false,
            autoHideDuration: 30000,
            message: "No message"
        },
        sendToDevMessage: "Send to dev",
        sendToDevMessageSuccess: "sendato con successo",
    };
    minHeightMobile;
    minHeightDesktop;
    customStyleMobile;
    customStyle;

    setState = (state, callback) => {
        super.setState(state, callback);
    };

    componentDidUpdate(prevProps) {
        let toUpdate = false;
        for (let field in this.fields) {
            if (this.props[this.fields[field].propsToMonitor] != prevProps[this.fields[field].propsToMonitor]) {
                if (this.fields[field].handler) {
                    this.fields[field].handler(this.props[this.fields[field].propsToMonitor], prevProps[this.fields[field].propsToMonitor]);
                }
                toUpdate = true;
            }
        }
        if (toUpdate) {
            this.forceUpdate();
        }
    }

    getLoading = () => {
        return this.loading;
    };

    setLoading = (loading, setterName) => {
        // if (this.loading !== loading) {
        console.debug("[InterfaceComponent] - setLoading " + loading + " from: " + setterName);
        this.loading = loading;
        this.forceUpdate()

        // } else {
        //     console.debug("[InterfaceComponent] - setLoading is already " + loading + ", called by: " + setterName);
        // }
    };

    setSnackbarSmartOpen = ({...params}) => {
        this.snackBar = {
            ...this.snackBar,
            sb: {
                ...params
            }
        }
        this.forceUpdate()
    }


    setSnackbarOpen = (
        vertical,
        horizontal,
        open,
        autoHideDuration,
        message,
        type,
        uuid
    ) => {

        if (type === "error") {

            this.snackBar = {
                ...this.snackBar,
                sb: {
                    vertical,
                    horizontal,
                    open,
                    autoHideDuration: autoHideDuration,
                    message: <div style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start"
                    }}>
                        <label>{message}</label>
                        <label>{uuid}</label>
                    </div>,
                    type
                }
            };
        } else {
            this.snackBar = {
                ...this.snackBar,
                sb: {
                    vertical,
                    horizontal,
                    open,
                    autoHideDuration,
                    message,
                    type
                }
            };
        }
        this.forceUpdate()
    };


    renderMobile = (globalContext) => {
    };
    renderDesktop = (globalContext) => {
    };

    render2(globalContext) {
        let {lockMobile, lockDesktop} = this.props;
        let {sb: {vertical, horizontal, open, autoHideDuration, message, type, customColor}} = this.snackBar;

        let placeholderMissingComponentMobile = () => {
            return "Componente mancante mobile"
        };
        let placeholderMissingComponentDesktop = () => {
            return "Componente mancante desktop"
        };

        if (lockMobile) {
            if (this.deniedAccessMobile) {

                placeholderMissingComponentMobile = () => {
                    return this.deniedAccessMobile;
                }
            }
        } else {
            placeholderMissingComponentMobile = this.renderMobile;
        }
        if (lockDesktop) {
            if (this.deniedAccessDesktop) {
                placeholderMissingComponentDesktop = () => {
                    return this.deniedAccessDesktop;
                }
            }
        } else {
            placeholderMissingComponentDesktop = this.renderDesktop;
        }



        return (<>
                {this.loading ?
                    this.loadingSystem : null}
                <BreakpointCustom
                    style={{
                        overflow: "hidden",
                        flex: 1,
                        maxHeight: "100%", display: "flex", width: getFillWidth(),
                        minHeight: this.minHeightDesktop,
                        ...this.customStyle
                    }}
                    styleMobile={{
                        overflow: "hidden",
                        flex: 1,
                        maxHeight: "100%", display: "flex", width: getFillWidth(),
                        minHeight: this.minHeightMobile,
                        ...this.customStyleMobile
                    }}
                    globalContext={globalContext}
                    placeholderMissingComponentDesktop={placeholderMissingComponentDesktop}
                    placeholderMissingComponentMobile={placeholderMissingComponentMobile}
                />
                {/*<Portal> */}
                <SnackBar
                    open={open}

                    vertical={vertical}
                    horizontal={horizontal}
                    autoHideDuration={autoHideDuration}
                    type={type}
                    message={message}
                    customColor={customColor}
                    handleClose={() => {
                        this.snackBar = {sb: {open: false}};
                        this.forceUpdate()
                    }}
                />
                {/*</Portal>*/}
            </>
        )
    }

    render() {
        return (this.render2());
    }

    setListener = (propsToMonitor, handler) => {
        this.fields.push({propsToMonitor, handler});
    };

    removeListener = (propsToMonitor) => {
        for (let field in this.fields) {
            if (this.fields[field].propsToMonitor == propsToMonitor) {
                this.fields.pop(this.fields[field]);
                return
            }
        }

    }
}

export default InterfaceComponent;


export const BreakpointCustom=(props)=> {
    let width = useCurrentWidth()
    let {style,styleMobile, placeholderMissingComponentMobile, placeholderMissingComponentDesktop, globalContext} = props
    if (width <= 868) {
        return <div id={"breakpointMobile"} style={styleMobile}>{placeholderMissingComponentMobile(globalContext)}</div>;
    } else {
        return <div id={"breakpointDesktop"} style={style}>{placeholderMissingComponentDesktop(globalContext)}</div>;
    }
}
