import Dexie from "dexie";
import {importInto} from "dexie-export-import";
import moment from "moment";
import axios from "axios";
import {SESSION_GOOGLE_TOKEN} from "./Config";
import {importLinkMassive} from "./ShareLibraryHelper";
import Queue from "../components/Queue/Queue";

export const momentFormat = "YYYY/DD/MM-HH:mm:ss"

export const DB_NAME = "Definitive2.3"

export const db = () => {

    let output = new Dexie(DB_NAME);

    output.version(1).stores({
        show: '[idShow],idShow, showName,inputDate,followed',
        season: '[idShow+seasonNumber],idShow,seasonNumber,seasonName,inputDate',
        episode: '[idShow+seasonNumber+episodeNumber],seasonNumber,idShow,episodeNumber,episodeName,seen, seenDate',
        link: '[idShow+seasonNumber+episodeNumber+link],idShow,seasonNumber,episodeNumber,link,inputDate,[idShow+seasonNumber+episodeNumber]'
    });
    output.version(2).stores({});
    output.version(3).stores({});
    output.version(4).stores({});
    output.version(5).stores({
        rawEpisode: '[idShow+link],idShow,link,inputDate,episodeName,seen,seenDate',
        uncategorizedData: '[fileName+link],fileName,link,mimeType',
    });
    output.version(6).stores({
        mediaServer: '[host]'
    });
    output.version(7).stores({
        show: '[idShow],idShow, showName,inputDate,followed,tagFilter',
    });
    output.version(8).stores({
        mediaServer: '++id,host'
    });
    return output;
};

export const updateData = async (link) => {
    try {

        let config = {
            method: 'GET',
            url: link
        };
        let temp = await axios(config);
        let blob = new Blob([
            temp.data.files[0].content
        ], {
            type: 'application/json'
        });
        await databaseUpload(blob);
    } catch (e) {
        console.log("Error updating data", e);
        throw e;
    }

}

export const dateExist = async () => {
    if (await db().show.count() > 0) {
        return true
    } else {
        return false;
    }

}
export const saveUrlEpisode = async (input) => {
    await importLinkMassive([input]);
};

export const saveUncategorized = async (input) => {
    if (Array.isArray(input)) {
        for (let i of input) {
            let {fileName, mimeType, link} = i;

            await db().uncategorizedData.put({
                fileName, mimeType, link
            });
        }
    } else {
        let {fileName, mimeType, link} = input;

        await db().uncategorizedData.put({
            fileName, mimeType, link
        });
    }
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};
export const getRawEpisode = async (idShow) => {
    try {
        return (await db().rawEpisode.where("idShow").equals(parseInt(idShow)).toArray()).sort((a, b) => {
            if (parseInt(a.episodeName) < parseInt(b.episodeName)) return -1;  // any negative number works
            if (parseInt(a.episodeName) > parseInt(b.episodeName)) return 1;   // any positive number works
            return 0; // equal values MUST yield zero
        });
    } catch (e) {
        console.error(e)
    }
};
export const deleteAllRawEpisode = async (idShow) => {
    try {
        return await db().rawEpisode.where("idShow").equals(parseInt(idShow)).delete();
    } catch (e) {
        console.error(e)
    }
};
export const haveSeenRawEpisode = async (tvShowId, link) => {
    let temp = await db().rawEpisode.where({idShow: tvShowId, link}).first();
    return temp && temp.seen;
};
export const rawEpisodeSeen = async (input, seen) => {

    let {idShow, link, episodeName} = input;
    await db().rawEpisode.put({
        idShow,
        link,
        episodeName,
        seen,
        seenDate: moment().format(momentFormat)
    });
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};
export const saveRawEpisode = async (input) => {
    if (Array.isArray(input)) {
        for (let i of input) {
            let {idShow, link, inputDate, episodeName, seen, seenDate} = i;

            await db().rawEpisode.put({
                idShow, link, inputDate, episodeName, seen, seenDate
            });
        }
    } else {
        let {idShow, link, inputDate, episodeName, seen, seenDate} = input;

        await db().rawEpisode.put({
            idShow, link, inputDate, episodeName, seen, seenDate
        });
    }
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};
export const getTimeLinkRaw = async (input) => {
    let {idShow, link} = input;

    let temp = await db().rawEpisode.where({idShow: parseInt(idShow), link}).toArray();
    if (temp && temp[0] && temp[0].time) {
        return temp[0].time;
    } else {
        return null;
    }
};
export const saveTimeLinkRaw = async (input) => {
    let {idShow, episodeName, link, time} = input;
    await db().rawEpisode.put({
        idShow: parseInt(idShow),
        episodeName,
        link,
        time
    });
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};

export const saveTimeLink = async (input) => {
    let {tvShowId, episodeNumber, seasonNumber, link, time} = input;
    if (!tvShowId) {
        tvShowId = input.id || input.idShow;
    }

    await db().link.put({
        idShow: parseInt(tvShowId),
        seasonNumber,
        episodeNumber,
        link,
        time
    });

    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};
export const getTimeLink = async (input) => {
    let {tvShowId, episodeNumber, seasonNumber, link} = input;
    if (!tvShowId) {
        tvShowId = input.id || input.idShow;
    }
    let temp = await db().link.where({idShow: parseInt(tvShowId), seasonNumber, link, episodeNumber}).toArray();
    if (temp && temp[0] && temp[0].time) {
        return temp[0].time;
    } else {
        return null;
    }
};


export const deleteLink = async (tvShowId, link, seasonNumber, episodeNumber) => {
    let temp = await db().link.where({idShow: parseInt(tvShowId), seasonNumber, link, episodeNumber}).delete();
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
    return temp;
};

export const deleteSeriesAndLink = async (tvShowId, seasonNumber) => {
    await db().season.where({idShow: parseInt(tvShowId), seasonNumber}).delete();
    await db().episode.where({idShow: parseInt(tvShowId), seasonNumber}).delete();
    await db().link.where({idShow: parseInt(tvShowId), seasonNumber}).delete();
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};

export const getEpisodeLink = async (tvShowId, seasonNumber, episodeNumber) => {
    return await db().link.where({idShow: parseInt(tvShowId), seasonNumber, episodeNumber}).toArray();
};

export const getToWatchSeries = async (text = "", page) => {
    let temp = await db().show.orderBy("inputDate").filter(j => {
        let description = j.showName || "";
        return description.toLowerCase().includes(text.toLowerCase()) && j.tagFilter === "W"
    }).distinct();
    let total_results = await temp.count();
    let results = await temp.offset(0).reverse().toArray();
    return {results, total_results};
};


export const getSeries = async (text = "", page) => {
    let temp = await db().show.orderBy("inputDate").filter(j => {
        let description = j.showName || "";
        return description.toLowerCase().includes(text.toLowerCase()) && j.followed
    }).distinct();
    let total_results = await temp.count();
    let results = await temp.offset(0).reverse().toArray();

    return {results, total_results};
};


export const getEpisodeSeenSeries = async (text = "", page) => {
    let episodes = await db().episode.filter(x => x.seen).toArray();
    episodes = episodes.map(x => "" + (x.idShow))
    let temp = await db().show.orderBy("inputDate").filter(j => {
        let description = j.showName || "";
        return description.toLowerCase().includes(text.toLowerCase()) && episodes.includes("" + j.idShow)
    }).distinct();
    let total_results = await temp.count();
    let results = await temp.offset(0).reverse().toArray();
    return {results, total_results};
};


export const getSeriesWithLinks = async (text = "", page) => {
    let links = await db().link.toArray();
    links = links.map(x => "" + (x.idShow))
    let temp = await db().show.orderBy("inputDate").filter(j => {
        let description = j.showName || "";
        return description.toLowerCase().includes(text.toLowerCase()) && links.includes("" + j.idShow)
    }).distinct();
    let total_results = await temp.count();
    let results = await temp.offset(0).reverse().toArray();
    return {results, total_results};
};


export const getSeriesFromMediaserver = async (text = "", page) => {
    let links = await db().rawEpisode.toArray();
    let idShows = []
    links.forEach(x => {
        if (!idShows.some(y => y.idShow === x.idShow)) {
            idShows.push(x)
        }
    })
    let total_results = idShows.length;
    let results = idShows;
    return {results, total_results};
};


export const databasePull = async (token) => {
    try {
        let list = await axios.get("https://www.googleapis.com/drive/v3/files?q=name+%3d+%27" + DB_NAME + ".json%27", {
            headers: {
                Authorization: "Bearer " + token
            }
        });
        if (list != null && list.data.files && list.data.files[0]) {
            console.log("ID FILE SYNC Db pull: ", list.data.files[0])
            let getResponse = await axios.get("https://www.googleapis.com/drive/v3/files/" + list.data.files[0].id + "?alt=media", {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            let content = getResponse.data;
            let blob = new Blob([
                JSON.stringify(content)
            ], {
                type: 'text/json'
            });
            console.log("REMOTE SIZE: ", blob.size)
            await importInto(db(token), blob, {
                overwriteValues: true,
                acceptVersionDiff: true,
                clearTablesBeforeImport: true
            });
        } else {
            await databasePush(token)
        }
    } catch (e) {
        console.log("FAILED IMPORT: ", e)
        throw e;
    }

};

//TODO Gestire l'upload con una classe che fa partire l'upload solo dopo qualche scrittura
export const databasePush = async (token) => {
    if (!token) {
        return;
    }
    // try {
    //     promises.add(async () => {
    //         let blob = await exportDB(db(), {noTransaction: true});
    //         let list = await axios.get("https://www.googleapis.com/drive/v3/files?q=name+%3d+%27" + DB_NAME + ".json%27", {
    //             headers: {
    //                 Authorization: "Bearer " + token
    //             }
    //         });
    //         if (list != null && list.data.files && list.data.files[0]) {
    //             console.log("ID FILE SYNC DB push: ", list.data.files[0])
    //             let updateResponse = await axios.patch("https://www.googleapis.com/upload/drive/v3/files/" + list.data.files[0].id + "?uploadType=resumable", {
    //                 "name": DB_NAME + ".json",
    //                 "name": DB_NAME + ".json",
    //                 "properties": {key: "uniqueId", value: DB_NAME + ".json"}
    //             }, {
    //                 headers: {
    //                     Authorization: "Bearer " + token
    //                 }
    //             });
    //             await axios.put(updateResponse.headers.location, blob, {
    //                 headers: {
    //                     Authorization: "Bearer " + token, "Content-Type": "multipart/form-data"
    //                 }
    //             })
    //         } else {
    //             let createResponse = await axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable", {
    //                 "name": DB_NAME + ".json",
    //                 "properties": {key: "uniqueId", value: DB_NAME + ".json"}
    //             }, {
    //                 headers: {
    //                     Authorization: "Bearer " + token
    //                 }
    //             });
    //             await axios.put(createResponse.headers.location, blob, {
    //                 headers: {
    //                     Authorization: "Bearer " + token, "Content-Type": "multipart/form-data"
    //                 }
    //             })
    //         }
    //     })
    // } catch (e) {
    //     console.error("Error uploading data: ", e.response)
    // }
}

export const databaseUpload = async (file) => {
    try {
        await importInto(db(), file, {
            overwriteValues: true,
            acceptVersionDiff: true
        });
    } catch (e) {
        console.log("FAILED IMPORT: ", file, e)
        throw e;
    }

};

//Gestione serie personali
export const episodeSeen = async (input, seen) => {
    let {tvShowId, episodeNumber, seasonNumber} = input;
    if (!tvShowId) {
        tvShowId = input.id;
    }
    await db().episode.put({
        idShow: tvShowId,
        episodeNumber,
        seasonNumber,
        seen,
        seenDate: moment().format(momentFormat)
    });
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))

};
//Salva lo show quando clicchi follow toShow
export const saveFollowedToShowShow = async (input, value, key) => {
    let {id, name, original_name} = input;
    let followed = value, tagFilter = value
    if (key === "follow") {
        tagFilter = "T"
        if (await showIsToWatch(id)) {
            tagFilter = "W"
        }
    } else if (key === "toWatch") {
        followed = await showIsFollowed(id)
    }
    await db().show.put({
        idShow: parseInt(id),
        showName: name || original_name,
        followed,
        tagFilter,
        inputDate: moment().format(momentFormat)
    });
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))

};

let promises = new Queue(true);


export const haveSeen = async (tvShowId, seasonNumber, episodeNumber) => {
    let temp = await db().episode.where({idShow: tvShowId, seasonNumber, episodeNumber}).first();
    return temp && temp.seen;
};

export const showIsFollowed = async (tvShowId,) => {
    let temp = await db().show.where({idShow: tvShowId}).first();
    return temp && temp.followed;
};
export const showIsToWatch = async (tvShowId,) => {
    let temp = await db().show.where({idShow: tvShowId}).first();
    return temp && temp.tagFilter === "W";
};


export const saveMediaServer = async (mediaserver) => {
    await db().mediaServer.put({
        host: mediaserver
    });
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};
export const deleteMediaServer = async (id) => {
    await db().mediaServer.where({id: id}).delete();
    databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
};
export const getMediaServer = async () => {
    return await db().mediaServer.toArray();
};
export const getUncategorized = async () => {
    return await db().uncategorizedData.filter(j => {
        return j.link.toLowerCase().includes("gant")
    }).toArray();
};

export const updateShowName = async (idShow, newName) => {
    let show = await db().show.where({idShow}).first();
    if (show.showName !== newName) {
        await db().show.put({...show, showName: newName});
        databasePush(sessionStorage.getItem(SESSION_GOOGLE_TOKEN))
    }
}