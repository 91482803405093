import React from "react";
import {
    deleteLink,
    episodeSeen,
    getEpisodeLink,
    haveSeen,
    haveSeenRawEpisode,
    rawEpisodeSeen,
    saveUrlEpisode
} from "../constants/StorageHelper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {Avatar, Grid} from "@material-ui/core";
import placeholder from "../assets/images/Logo.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DialogActions from "@material-ui/core/DialogActions";
import styled from "styled-components";
import {v4 as uuid4} from "uuid"
import * as moment from "moment";

import BaseComponent from "../interfaces/BaseComponent";
import {DeleteForever, PlayCircleFilled, RadioButtonUnchecked, Visibility} from "@material-ui/icons";
import MobileButtonList from "./MobileButtonList";
import {Label, RowContainer} from "./ComponentManager/ComponentManager";


const CategoryHeader = styled.h3`
  font: var(--text-normal-32);
  line-height: 37px;
  color: #AAAAAA;
`;

const UrlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
`;

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'// port and path
        , 'i'); // fragment locator
    return !!pattern.test(str);
}

class EpisodeCard extends BaseComponent {

    state = {
        insertDialog: false,
        videoDialog: false,
        episodesLink: [],
        seen: false,
        loading: true,
        iframeLink: false,
        id: null,
        steps: [],
        seasonNumber: 0
    }
    firstLoad = true;
    videoPlayer = null;
    customStyle = {overflow: "unset"}
    customStyleMobile = {overflow: "unset", minWidth: "160px"}


    async componentDidMount() {
        await this.reloadData();
    }

    reloadData = async () => {
        // this.setLoading(true)
        try {

            let {seasonNumber, episode, id, rawEpisode} = this.props;
            let result;
            if (rawEpisode) {
                result = await haveSeenRawEpisode(id, episode.link);
            } else {
                result = await haveSeen(id, seasonNumber, episode.episode_number);
            }
            let seen = result > 0;


            // this.setState({seen}, async () => {
            //     let episodesLink = await getEpisodeLink(id, seasonNumber, episode.episode_number);
            //     this.setState({episodesLink})
            // })

            let episodesLink;
            if (rawEpisode) {
                episodesLink = [episode];
            } else {
                episodesLink = await getEpisodeLink(id, seasonNumber, episode.episode_number);
            }
            this.setState({seen, episodesLink})
        } catch (e) {
            console.error("Error while reloading data", e.response)
        } finally {
            // this.setLoading(false)
        }
    }


    renderDesktop = () => {
        return this.renderCommon()
    }
    renderMobile = () => {
        return this.renderCommon(true)
    }


    renderCommon = (isMobile) => {
        let {seasonNumber, show, episode, isSelected, id, liteVersion, handlePlayEpisode, rawEpisode} = this.props;
        let {insertDialog, episodesLink, seen} = this.state;

        // Nel caso di episodi raw, il numero si prova a prendere con un parseInt del titolo
        let episodeNumber = episode.episode_number || parseInt(episode.episodeName)
        let episodeName = episode.name || episode.episodeName;

        let description = episodeNumber + ". " + episodeName;

        let size = {
            xs: 12,
            sm: 5,
            md: 3,
            lg: 2,
            xl: 2
        }
        if (liteVersion) {
            size = {xs: 12}
        }
        let mainContainerStyle = {
            // padding: "10px 0",
            display: "flex",
            width: "100%"
        }
        let buttonListStyle = {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%"
        }


        if (liteVersion) {
            buttonListStyle = {
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
            }
            mainContainerStyle = {
                padding: "10px 0",
                display: "flex",
                width: "100%",
                margin: "10px",
                minWidth: "144px"
            }
        }
        if (isSelected && liteVersion) {
            mainContainerStyle = {
                display: "flex",
                padding: "10px 0",
                background: "linear-gradient(0deg, rgba(39,76,134,1) 0%, rgba(39,76,134,1) 50%, rgba(39,76,134,1) 100%)",
                width: "100%",
                margin: "10px",
                minWidth: "144px"
            }

        }
        if (isMobile) {
            mainContainerStyle = {
                // padding: "5px",
                display: "flex",
                minWidth: "144px"
            }

            if (isSelected && liteVersion) {
                mainContainerStyle = {
                    minWidth: "144px",
                    display: "flex",
                    padding: "5px",
                    background: "linear-gradient(0deg, rgba(39,76,134,1) 0%, rgba(39,76,134,1) 50%, rgba(39,76,134,1) 100%)",
                }

            }
        }


        let temp = episodesLink && episodesLink.map((x) => (
                <RowContainer customStyle={
                    episodesLink.length === 1 ?
                        "width:100%;" :
                        "width:100%;margin-bottom:10px;"
                }>
                    <Button
                        variant={"contained"}
                        fullWidth
                        style={{margin: liteVersion ? "0 10px" : undefined}}
                        color={"primary"}
                        target="_blank"
                        onClick={() => {
                            if (!seen) {
                                let checked = true;
                                if (rawEpisode) {
                                    let input = {
                                        idShow: id,
                                        episodeName: episodeName,
                                        link: x.link,
                                    }
                                    rawEpisodeSeen(input, checked).then(() => {
                                        this.setState({seen: checked})
                                    });
                                } else {
                                    let input = {
                                        tvShowId: id,
                                        seasonNumber,
                                        showName: show.name,
                                        showDetail: JSON.stringify(show),
                                        episodeNumber: episode.episode_number,
                                        episodeName: episode.name,
                                        episodeImage: episode.still_path,
                                    }
                                    episodeSeen(input, checked).then(() => {
                                        this.setState({seen: checked})
                                    });
                                }
                            }
                            handlePlayEpisode(x.link, isMobile)
                        }}>
                        {x.time ? " (" + moment.utc(x.time * 1000).format('mm:ss') + ")" : (new URL(x.link)).hostname.replace('www.', '').substring(0, isMobile ? 5 : 20)}
                    </Button>
                    {!liteVersion && <IconButton onClick={async () => {
                        if (rawEpisode) {
//TODO Elimina singolo link e anche episodio quindi, ha senso?
                        } else {
                            await deleteLink(id, x.link, seasonNumber, episode.episode_number);
                        }
                        this.setState({
                            episodesLink: await getEpisodeLink(id, seasonNumber, episodeNumber)
                        })
                    }}><DeleteForever color={"secondary"}/></IconButton>}
                </RowContainer>
            )
        )
        if (temp && temp.length === 1) {
            mainContainerStyle = {...mainContainerStyle, cursor: "pointer"}
        }

        if (!!seen && !isSelected) {
            mainContainerStyle = {
                ...mainContainerStyle,
                background: "linear-gradient(0deg, rgba(39,76,134,0.8) 0%, rgba(39,76,134,0.5) 50%, rgba(39,76,134,0.2) 100%)",
            }
        }
        //2. Render the item
        return <div key={uuid4()} style={mainContainerStyle} id={episodeNumber} onClick={(event) => {
            if (temp && temp.length === 1 && !insertDialog) {
                handlePlayEpisode(episodesLink[0].link, isMobile)
            }
        }}>
            <Grid container spacing={0}>
                <Grid item xs={12} style={{padding: "5px"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Checkbox
                            style={{color: "#BBBBBB"}}
                            size={"small"}
                            checkedIcon={<Visibility fontSize={"small"}/>}
                            icon={<RadioButtonUnchecked fontSize={"small"}/>}
                            checked={!!seen}
                            onClick={(event) => {
                                event.cancelBubble = true;
                                event.stopPropagation()
                                event.preventDefault();
                            }}
                            onChange={async event => {

                                try {
                                    let checked = event.target.checked;
                                    if (rawEpisode) {
                                        let input = {
                                            idShow: id,
                                            episodeName: episodeName,
                                            link: episode.link,
                                        }
                                        await rawEpisodeSeen(input, checked);
                                    } else {
                                        let input = {
                                            tvShowId: id,
                                            seasonNumber,
                                            showName: show.name,
                                            showDetail: JSON.stringify(show),
                                            episodeNumber: episode.episode_number,
                                            episodeName: episode.name,
                                            episodeImage: episode.still_path,
                                        }
                                        await episodeSeen(input, checked);
                                    }

                                    this.setState({seen: checked}, () => {
                                        if (liteVersion) {

                                        }
                                    })
                                } catch (e) {
                                    console.error("Failed to store data:", e)
                                }
                            }}
                        />
                        {!liteVersion && <IconButton aria-label={"star" + description} onClick={async (event) => {
                            event.cancelBubble = true;
                            event.stopPropagation()
                            this.setState({
                                insertDialog: true,
                                episodesLink: await getEpisodeLink(id, seasonNumber, episode.episode_number)
                            })
                        }}>
                            <CloudUploadIcon color={"secondary"}/>
                        </IconButton>}
                        <ListItemAvatar>
                            <Avatar alt={"item" + episode.name}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholder
                                    }}
                                    src={episode.still_path ? "https://image.tmdb.org/t/p/w500" + (episode.still_path) : null}/>
                        </ListItemAvatar>
                        {!liteVersion && <IconButton aria-label={"star" + description} onClick={async (event) => {
                            event.cancelBubble = true;
                            event.stopPropagation()

                            let {setDialogState, resetDialogState} = this.context
                            setDialogState({
                                dialogIsOpen: true,
                                paperSizeIsSmallest: true,
                                title: "Delete all links",
                                content: <Label font={"--text-normal-20"}>Are you sure you
                                    want to delete all the links for this episode?</Label>,
                                rightButtonText: "DELETE",
                                leftButtonText: "Close",
                                handleClose: () => resetDialogState(),
                                handleSubmit: async (e) => {
                                    e.preventDefault();
                                    for (let index in episodesLink) {
                                        let x = episodesLink[index]
                                        await deleteLink(id, x.link, seasonNumber, episode.episode_number)
                                    }
                                    resetDialogState()
                                    await this.reloadData()
                                },

                            })


                        }}>
                            <DeleteForever color={"secondary"}/>
                        </IconButton>}
                        {liteVersion && <><Label font={"--text-normal-16"}>
                            {episodeNumber}
                        </Label>
                            {!liteVersion && <Label font={"--text-normal-16"} ellipsis={1}>
                                {". " + episodeName}
                            </Label>}
                        </>}
                    </div>
                </Grid>
                {!liteVersion && <Grid item xs={12} style={{padding: "10px"}}>
                    <Label font={"--text-normal-16"} ellipsis={1}
                           cursor={temp && temp.length === 1 ? "pointer" : undefined}>
                        {episodeNumber}{!liteVersion && ". " + episodeName}
                    </Label>
                </Grid>}
                <Grid item xs={12} style={{...buttonListStyle, height: "46px"}}>

                    {temp && temp.length > 1 ?
                        <>
                            {!liteVersion && <Label font={"--text-normal-16"}>
                                Watch now!
                            </Label>}
                            <MobileButtonList
                                customStyle={{
                                    rightButton: {
                                        width: "100%",
                                        backgroundColor: isSelected && liteVersion ? "#1F2631" : liteVersion ? "#274C86" : "#1F2631"

                                    }
                                }}
                                buttonsArray={temp}
                            /></> :
                        temp && temp.length === 1 ? <>
                                {episodesLink[0].time ? <Label font={"--text-normal-16"} ellipsis={1}
                                                               cursor={temp && temp.length === 1 ? "pointer" : undefined}
                                                               customStyle={{container: "padding-right:10px;"}}
                                    >
                                        {" (" + moment.utc(episodesLink[0].time * 1000).format('mm:ss') + ")"} </Label>
                                    : !liteVersion && <Label font={"--text-normal-16"} ellipsis={1}
                                                             cursor={temp && temp.length === 1 ? "pointer" : undefined}
                                                             customStyle={{container: "padding-right:10px;"}}
                                >
                                    "Watch now!"
                                </Label>}
                                <PlayCircleFilled fontSize={"large"} color={"secondary"} style={{marginRight: "10px"}}/>
                            </>
                            :
                            <Label font={"--text-normal-16"} ellipsis={1}
                                   cursor={temp && temp.length === 1 ? "pointer" : undefined}
                                   customStyle={{container: "padding-right:10px;"}}
                            >
                                {"No links yet :("}
                            </Label>}
                </Grid>
            </Grid>


            <Dialog open={insertDialog} onClose={() => {
                this.setState({insertDialog: false,})
            }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"> <CategoryHeader>Link handler</CategoryHeader>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color={"secondary"}>
                        Add a link and press "plus" or "enter"
                        <ul>
                            {episodesLink.map((x, number) => <li
                                key={"li" + uuid4()}
                            ><a target="_blank"
                                href={x.link}>{(number + 1) + ". " + episode.name + " (" + (new URL(x.link)).hostname.replace('www.', '') + ")"}</a>
                                <IconButton onClick={async () => {
                                    await deleteLink(id, x.link, seasonNumber, episode.episode_number);
                                    this.setState({
                                        episodesLink: await getEpisodeLink(id, seasonNumber, episodeNumber)
                                    })
                                }}>
                                    <DeleteForeverIcon color={"secondary"}/>
                                </IconButton>
                            </li>)}
                        </ul>
                    </DialogContentText>
                    <UrlContainer>

                        <TextField
                            autoFocus
                            id="linkInput"
                            fullWidth
                            color={"secondary"}
                        />
                        <Button onClick={async () => {
                            let inputUrl = document.getElementById("linkInput").value
                            if (validURL(inputUrl)) {
                                let input = {
                                    tvShowId: id,
                                    episodeNumber,
                                    seasonNumber,
                                    showName: show.name,
                                    episodeName,
                                    link: inputUrl
                                }
                                await saveUrlEpisode(input);

                                this.setState({
                                    episodesLink: await getEpisodeLink(id, seasonNumber, episodeNumber)
                                })
                            }

                        }} color="primary">
                            <AddBoxIcon color={"secondary"}/>
                        </Button>
                    </UrlContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({insertDialog: false,})
                    }} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>;
    }
}

export default EpisodeCard;