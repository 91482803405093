import React, {Component} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import SearchScreen from "../screens/SearchScreen/SearchScreen";

import ConfScreen from "../screens/ConfScreen/ConfScreen";
import {Container} from "../components/StyledDiv";
import SearchDetailScreen from "../screens/SearchScreen/ShowDetailScreen";
import MyVideoScreen from "../screens/MyVideoScreen/MyVideoScreen";
import ShareScreen from "../screens/ShareScreen/ShareScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicy/PrivacyPolicyScreen";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ImportScreen from "../screens/ImportScreen/ImportScreen";
import QuickVideoPage from "../screens/QuickVideoPage/QuickVideoPage";
import ExternalMediaserver from "../screens/ExternalMediaserver/ExternalMediaserver";
import MangaScreen from "../screens/MangaScreen/MangaScreen";

class BaseRouter extends Component {

    state = {

        loading: true,
        checkFirstTime: false,
    };


    componentDidMount() {
        let checkFirstTime = localStorage.getItem("FIRST_TIME_IN_QUIX")

        if (!checkFirstTime || checkFirstTime !== "false") {
            this.setState({checkFirstTime: true})
        }

    }


    render() {
        let {checkFirstTime} = this.state;


        return (
            <BrowserRouter>
                <Container>
                    <Route exact path={"/"}
                    >
                        <MyVideoScreen/>
                    </Route>
                    <Route exact path={"/c"}>
                        <ConfScreen/>
                    </Route>
                    <Route exact path={"/i"}>
                        <ImportScreen/>
                    </Route>
                    <Route exact path={"/r"}>
                        <ShareScreen/>
                    </Route>
                    <Route exact path={"/m"}>
                        <MangaScreen/>
                    </Route>
                    <Route exact path={"/pp"}>
                        <PrivacyPolicyScreen/>
                    </Route>
                    <Route exact path={"/s"}>
                        <SearchScreen/>
                    </Route>
                    <Route exact path={"/qv"}>
                        <QuickVideoPage/>
                    </Route>
                    <Route exact path={"/ms"}>
                        <ExternalMediaserver/>
                    </Route>
                    <Route exact path={"/dp/:id/:seasonNumber"}>
                        <SearchDetailScreen/>
                    </Route>
                </Container>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={checkFirstTime}
                    onClose={() => {
                        localStorage.setItem("FIRST_TIME_IN_QUIX", "false")
                        this.setState({checkFirstTime: false}
                        )
                    }} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        WELCOME TO QuiX!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText color={"secondary"}>
                            Hi guys, we are developing a web portal to track tv series/film/anime/manga and more.
                            Now we are in pre-apha phase but we want to share some result with the community.<br/><br/>
                            The project name it's "QuiX", but it can change.<br/>
                            The proposal of the project is to develop a website like "trakt.tv" or "simkl.com" but
                            with serverless architecture.<br/>
                            The principle is simple, you have a list of media data, taken from TMDB, you can follow
                            a tv serie and check if you have seen episodes.<br/>
                            Then you can add to any episode a list of streaming link like netflix, youtube or other
                            media.<br/>
                            If you add a link with a direct mp4 file you can actually reproduce it in the website.<br/>
                            And finally you can share all the links of the tv series you added to your collection
                            with friends with a simple click.<br/>
                            For now all the data are stored locally in your PC so until you login with google all
                            the data is on a single device.<br/>
                            The login with google will request the permissions to read/write on google drive, thanks
                            to that all your data is saved on your personal google drive account and nothing remains
                            in the server.<br/>
                            This project is focused on privacy so no data are stored in the server, all the
                            operations are made client side (research,saving and other).<br/><br/>

                            As said in the beginning we are in pre-alpha at the moment, so you need to send us your
                            gmail address and we will grant you the permissions to login with your google
                            account.<br/><br/>

                            In the near future we are going to work on other features like:<br/>
                            - Share screen/video with friends and see tv show togheter (Peer-to-peer technology)<br/>
                            - Better interface and personalized interface<br/>
                            - Add specific support to other DB and not only tv show from tmdb (Like anime from
                            MyAnilist)<br/><br/>

                            Other project feature will be:<br/>
                            - Support for p2p direct streaming (It's a little bit tricky in a web app maybe a
                            desktop version)<br/>
                            - Android app<br/>
                            - Integration of official link of tv show like netfilx/primevideo<br/>
                            - Auto fetch and import from other service like trakt.tv<br/>
                            - Save data not only on google drive but other service (Personal drive, home nas,
                            onedrive and other service)<br/><br/><br/>


                            We have many other ideas, we just need time.<br/>
                            The project maybe in the future will become open source, but for now it's private just
                            because we have no time to maintain an official project on github.<br/>
                            We would like to keep the service ads-free, also because thanks to the serverless
                            architecture we actually don't have fee to pay.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            onClick={() => {
                                localStorage.setItem("FIRST_TIME_IN_QUIX", "false")
                                this.setState({checkFirstTime: false})
                            }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </BrowserRouter>
        )
    }
}

export default BaseRouter
