import BaseAxios from "./ApiHelpers/BaseAxios";
import {GET_ANIME_DETAIL_LINK, GET_ANIME_PICTURES_LIST} from "./ApiHelpers/ApiConst";


export const TMDB_KEY = "0e6573c5f576e34d2837b9ffe1f4421f";
export const LANGUAGE = (navigator.language && navigator.language.substr(0,2).toLowerCase()) || "en";
export const SESSION_GOOGLE_TOKEN = "SESSION_GOOGLE_TOKEN";
export const SETTINGS_AUTO_SYNC = "SETTINGS_AUTO_SYNC";


export const FILTER = [
    {
        field: "first_air_date",
        label: "Year"
    }

];

/* API path*/
// export const API_BASE_PATH = "https://api.jikan.moe";
export const API_BASE_PATH = "https://anime-channel-bk.developermmazzocchetti.dx.am/api/";
// export const DEFAULT_HOME_API = "/v3/top/anime/1/airing";
export const DEFAULT_HOME_API = "latest.php?subtype=airing";


/*
*  Search in jikan or other external API
*/
export const SEARCH_API = (searchString,source) => {
    return "search.php?q=" + searchString + "&limit=30&source="+source;
};
export const SOURCES_API = () => {
    return "sources.php";
};

/*
*  Search in our DB if the anime is present
*/
export const LIST_API = (searchString) => {
    if (searchString) {
        return "list.php?q=" + searchString + "&limit=30";
    } else {
        return "list.php?limit=30";
    }
};


/**
 *
 * TODO Place the scructure inside the param tag
 * The structure of the returned object must contain:
 * mal_id: id (String)
 * type: String
 * title: String
 * score: Numeric from 0 to 5 (Optional)
 * synopsis: String (Optional)
 * type: String (Optional)
 * episodes: Numeric (Optional)
 * start_date: date (Optional, momentjs library supported format)
 * end_date: date (Optional, momentjs library supported format)
 *
 * @param data
 * @returns {Array<MockFunctionResult>|CLIEngine.LintResult[]|string|number|SpeechRecognitionResultList|*}
 * @constructor
 */
export const RESULT_MAPPER = (data) => {

    if (data.data.results) {
        return data.data.results;
    } else if (data.data.top) {
        return data.data.top;
    }
};


/**
 *
 * episode_id String
 * title String
 *
 * @param id The mal_id taken from the result mapper
 * @returns {Promise<void>}
 * @constructor
 */
export const GET_ANIME_DETAIL = async (id) => {
    let res = await BaseAxios.request({
        url: GET_ANIME_DETAIL_LINK(id),
        method: "GET"
    });

    return res.data.internal;
};

/**
 *
 * @param id The mal_id taken from the result mapper
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_PICTURE_REQUEST = async (id) => {
    let res = await BaseAxios.request({
        url: GET_ANIME_PICTURES_LIST(id),
        method: "GET"
    });

    return res.data.pictures;
};






