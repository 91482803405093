import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PaperDraggable from "./PaperDraggable";
import PaperDraggableSmall from "./PaperDraggableSmall";
import PaperFullScreen from "./PaperFullScreen";
import PaperDraggableSmallest from "./PaperDraggableSmallest";
import style from "./CommonDialog.module.css"


const CommonDialog = (props) => {

    let {
        open,
        handleClose,
        title,
        customContent,
        customActions,
        paperSizeIsSmall,
        paperSizeIsSmallest,
        paperSizeIsBig,
        paperSizeIsFullScreen,
        leftButtonText,
        rightButtonText,
        leftButtonOnClick,
        rightButtonOnClick,
        leftButtonColor,
        leftButtonVariant,
        leftStartIcon,
        rightButtonColor,
        rightButtonVariant,
        rightStartIcon,
        rightButtonDisabled,
        handleSubmit,
        content,
        customStyle,
    } = props;
    let [isClickDisabled, setIsClickDisabled] = useState(false)

    const renderDefaultActions = () => {

        return <DialogActions
        >
            <Button
                startIcon={leftStartIcon ? leftStartIcon : undefined}
                onClick={leftButtonOnClick ? leftButtonOnClick : handleClose}
                color={leftButtonColor || "secondary"}
                variant={leftButtonVariant || "text"}
            >
                {leftButtonText || ""}
            </Button>
            <Button
                startIcon={rightStartIcon ? rightStartIcon : undefined}
                type="submit"
                disabled={rightButtonDisabled}
                variant={rightButtonVariant || "contained"}
                color={rightButtonColor || "primary"}
                onClick={rightButtonOnClick ? (e) => {
                    e.preventDefault()
                    if (!isClickDisabled) {
                        rightButtonOnClick(e)
                        setIsClickDisabled(true)
                    }
                } : undefined}
            >
                {rightButtonText || ""}
            </Button>
        </DialogActions>;
    };

    const renderDefaultContent = () => {

        return <form
            className={style.form}
            onSubmit={e => {
                e.preventDefault()
                if (!isClickDisabled) {
                    if (!rightButtonOnClick) {
                        handleSubmit(e);
                    }
                    setIsClickDisabled(true)
                }
            }}
        >
            <DialogContent
                // style={customStyle && customStyle.dialogContent ? customStyle.dialogContent : {
                //     maxHeight: "70vh",
                //     paddingBottom: "20px"
                // }}
                sx={{
                    maxWidth: "50vw",
                    ...customStyle?.content

                }}
            >
                {content}
            </DialogContent>
            {customActions ?
                <DialogActions
                >
                    {customActions}
                </DialogActions> :
                renderDefaultActions()
            }
        </form>
    };


    let paper;
    if (paperSizeIsFullScreen) {
        paper = PaperFullScreen
    }  else if (paperSizeIsSmall) {
        paper = PaperDraggableSmall
    } else if (paperSizeIsSmallest) {
        paper = PaperDraggableSmallest
    } else {
        paper = PaperDraggable
    }

    const renderDefault = () => {

        return <><DialogTitle
            id="draggable-dialog-title"
        >
            {title}
        </DialogTitle>
            {customContent ?
                <DialogContent
                >
                    {customContent}
                </DialogContent>
                : renderDefaultContent()
            }
            {customContent && customActions ?
                <DialogActions>
                    {customActions}
                </DialogActions>
                : customContent &&
                renderDefaultActions()
            }</>
    }


    return <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={paper}
        scroll='paper'
        aria-labelledby="draggable-dialog-title"
        id={"mainContainerCommonDialog"}
        maxWidth={customStyle && customStyle.maxWidth ? customStyle.maxWidth : undefined}
        fullWidth={customStyle && customStyle.fullWidth}
        fullScreen={customStyle && customStyle.fullScreen}
        className={style.dialogSafeArea}
    >
        {renderDefault()}
    </Dialog>

}
export default CommonDialog