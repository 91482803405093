import React from "react";
import styled from 'styled-components';
import {Title} from '../../components/StyledDiv';
import Button from "@material-ui/core/Button";
import {importLinkMassive, readSharedData, readSharedLink} from "../../constants/ShareLibraryHelper";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import {withRouter} from "react-router-dom";
import BaseComponent from "../../interfaces/BaseComponent";
import LoadingSystem from "../../components/LoadingSystem";


const DetailContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px; //THIS make scrolling vertical work in other browser flex tips and trick https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
  flex-direction: column;
`;
const SourceSelector = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px; //THIS make scrolling vertical work in other browser flex tips and trick https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  align-self: center;
`;

const externalLink="https://api.paste.ee/v1/pastes/"

class ImportScreen extends BaseComponent {


    state = {
        externalLink: "",
        loading: true
    };

    async componentDidMount() {
        let {location, history} = this.props
        let parameterShareSimpleSeries = new URLSearchParams(location.search).get('sh');
        try {

            if (parameterShareSimpleSeries) {
                let idShow = await readSharedData( externalLink+ parameterShareSimpleSeries)
                if (idShow) {
                    history.push("/dp/" + idShow + "/0")
                } else {
                    history.push("/")
                }
            }
        } catch (e) {
            console.error("Errore durante l'import dei dati", e.response)
        }
        try {
            let parameter = new URLSearchParams(location.search).get('sl');
            console.log("ShareLink: ", parameter)
            if (parameter) {
                let data = await readSharedLink(externalLink + parameter)
                console.log("ShareLink: ", data)
                this.setState({
                    importedLink: data,
                    externalLink: externalLink + parameter,
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }

        } catch (e) {
            console.error("Errore durante l'import dei dati totali", e.response)
        }

    }

    renderMobile = () => {
        return this.renderDesktop()
    }

    renderDesktop=()=> {
        let {importedLink, externalLink, loading} = this.state
        if (loading) {
            return <LoadingSystem/>
        }

        return (<DetailContainer>
                <SourceSelector>
                    <Title>Here you can manage data to import</Title>
                    <Divider orientation="horizontal"/>

                    <span>
                        <h5>Insert a valid URL to download json data: </h5>
                        <TextField
                            autoFocus
                            id="link"
                            variant={"filled"}
                            fullWidth
                            required
                            value={externalLink}
                            onChange={(e) => {
                                this.setState({externalLink: e.target.value})
                            }}
                            color={"secondary"}
                            style={{marginBottom: "10px", maxHeight: "200px"}}
                        />
                        <Button disabled={!externalLink} variant={"contained"} color={"primary"} onClick={async () => {
                            try {
                                this.setLoading(true,"ImportScreen - import all")
                                let data = await readSharedLink(externalLink)
                                console.log("DATA: ", data)
                                this.setState({importedLink: data})
                            } catch (e) {
                                console.log("ERRORE: ", e)
                                // alert("Errore \n" + e)
                            } finally {
                                this.setLoading(false,"ImportScreen - import all")
                            }
                        }}>
                            Update list
                        </Button>
                    </span>
                    {importedLink && <>
                        <h5>
                            You have {importedLink.length} record to import
                        </h5>
                        <Button variant={"contained"} color={"primary"} onClick={async () => {
                            try {
                                console.log("DATA: ", importedLink)
                                this.setLoading(true,"ImportScreen - import all")
                                await importLinkMassive(importedLink)
                                this.props.history.push("/")
                            } catch (e) {
                                console.log("ERRORE: ", e)
                            } finally {
                                this.setLoading(false,"ImportScreen - import all")
                            }
                        }}>
                            Import all and go to episode list
                        </Button>
                    </>}
                </SourceSelector>
            </DetailContainer>
        )
    }

}


export default withRouter(ImportScreen);
