import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex:1;
height: 100%;
width: 100%;
flex-direction:column;
justify-content:space-between;
background: #1F2631;

`;

export const Title = styled.h1`
font: var(--text-normal-32);
line-height: 36px;
`;
export const LastTitle = styled.h6`
font: var(--text-normal-14);
line-height: 19px;
color: #FFFFFF;
`;
