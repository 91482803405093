import React from "react";
import {withRouter} from "react-router-dom";
import {Grid, MenuItem, Select} from '@material-ui/core';
import {FILTER} from "../../constants/Config";
import {
    getEpisodeSeenSeries,
    getSeries,
    getSeriesFromMediaserver,
    getSeriesWithLinks,
    getToWatchSeries
} from "../../constants/StorageHelper";
import ShowList from "../../components/ShowList";
import BaseComponent from "../../interfaces/BaseComponent";
import SearchBar from "../../components/SearchBar";
import {ColumnContainer} from "../../components/ComponentManager/ComponentManager";


class MyVideoScreen extends BaseComponent {
    state = {
        selected: FILTER[0].field,
        source: "tmdb",
        animes: [],
        total_results: 1,
        page: 1,
        selectedFilter: parseInt(localStorage.getItem("myScreenFilter")) || 0,
        loading: true
    };
    lock = false

    searchTmnd = (q, page = 1) => {
        let {selectedFilter} = this.state;
        localStorage.setItem("myScreenFilter", selectedFilter)
        switch (selectedFilter) {
            case 0:
                return getToWatchSeries(q, page);
            case 2:
                return getEpisodeSeenSeries(q, page);
            case 3:
                return getSeriesWithLinks(q, page);
            case 4:
                return getSeriesFromMediaserver(q, page);
            case 1:
            default:
                return getSeries(q, page);
        }
    }

    refreshData = (searchString = this.state.searchString, page = this.state.page, forceEmptyState=false) => {
        if (this.lock) {
            return
        }
        try {
            this.lock = true
            if (forceEmptyState) {
                page = 1
            }
            this.searchTmnd(searchString, page).then((response) => {
                let tmp = []
                response.results.forEach((tmpNew) => {
                    if (forceEmptyState || !this.state.animes.some((tmpOld) => tmpOld.idShow === tmpNew.idShow)) {
                        tmp.push(tmpNew)
                    }
                })
                let animes = [...this.state.animes, ...tmp]
                if (forceEmptyState) {
                    animes = [...tmp]
                }
                this.setState({
                    animes,
                    total_results: response.total_results,
                    page,
                    loading: false
                }, () => this.lock = false);
            });
        } catch (e) {
            console.error("Errore nel recupero dei tuoi dati", e.response)
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleHeaderOnScroll)
        this.refreshData();
    }


    handleChangeSource = (e) => {
        let source = e.target.value;
        this.setState({source})
    };


    renderDesktop = () => {
        return this.renderCommon()
    }
    renderMobile = () => {
        return this.renderCommon(true)
    }

    renderCommon = (isMobile) => {
        let {shareRoom} = this.props;
        let {loading, animes, selectedFilter, searchString, total_results} = this.state;

        if (loading) {
            return null
        }

        return <ColumnContainer customStyle={"width:100%;"}>
            <ShowList
                shareRoom={shareRoom}
                path={""}
                isSearching={!!searchString}
                toolbar={<Grid container item xs={12}>
                    <Grid item xs={12} sm={8} md={6} lg={4}
                          style={{padding: "5px", transition: "all 1s ease-in-out"}}>
                        <SearchBar
                            refreshData={async (searchString) => {
                                this.setState({
                                        searchString,
                                        page: 1,
                                        animes: []
                                    },
                                    async () => await this.refreshData(searchString, 1)
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}
                          style={{padding: "5px", transition: "all 1s ease-in-out"}}>
                        <Select
                            id="filter"
                            fullWidth
                            variant={"outlined"}
                            style={{height: "40px", border: "3px solid #777777",}}
                            value={selectedFilter}
                            onChange={(e) => {
                                this.setState({
                                    selectedFilter: e.target.value,
                                    page: 1,
                                    animes: []
                                }, () => this.refreshData(searchString, 1))
                            }}
                        >
                            <MenuItem value={0}>
                                <em>Watching</em>
                            </MenuItem>
                            <MenuItem value={1}>
                                <em>Followed</em>
                            </MenuItem>
                            <MenuItem value={2}>
                                <em>With seen episodes</em>
                            </MenuItem>
                            <MenuItem value={3}>
                                <em>With episode links</em>
                            </MenuItem>
                            <MenuItem value={4}>
                                <em>Raw episodes from mediaserver</em>
                            </MenuItem>
                        </Select>
                    </Grid>
                </Grid>}
                loadMore={() => {
                    if (total_results > animes.length) {
                        this.refreshData(searchString, this.state.page + 1)
                    }
                }}
                hideLoadMore={total_results <= animes.length}
                refreshData={() => this.refreshData("", this.state.page, true)}
                shows={animes}
            />


        </ColumnContainer>

    }


}

export default withRouter((MyVideoScreen));
