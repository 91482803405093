import React from "react";
import {withRouter} from "react-router-dom";
import styled from "styled-components";


const Container = styled.div`
display: flex;
flex: 1;
min-height: 0px; //THIS make scrolling vertical work in other browser flex tips and trick https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
flex-direction:row;
align-items:center;
`;
const ProfileImage = styled.img`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const NameText = styled.label`
font: var(--text-font-h6);
color: #CCCCCC;
margin-right: 6px;
overflow:hidden;
text-overflow: ellipsis;
`;

class ProfilePageHeader extends React.Component {
    render() {
        let {name, profileImage} = this.props;
        return (
            <Container>
                {/*<NameText>{name}</NameText>*/}
                <ProfileImage src={profileImage}/>
            </Container>
        )
    }
}

export default withRouter((ProfilePageHeader));
