import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import {randomIdGenerator} from "../ComponentManager"; // ES6

const LabelContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    
     ${props => {
    let propsStyles = ``;

    if (props.ellipsis) {
        if (Number.isInteger(props.ellipsis)) {
            propsStyles += `-webkit-line-clamp: ` + props.ellipsis + `;`;
        } else {
            propsStyles += `-webkit-line-clamp: 1;`;
        }
    }

    if (props.color) {
        propsStyles += `color: var(` + props.color + `);`
    } else {
        propsStyles += `color: var(--title-color);`
    }
    if (props.textAlign) {
        propsStyles += `text-align: ` + props.textAlign + `;`
    } else {
        propsStyles += `text-align: left;`
    }

    if (props.flex) {
        propsStyles += `flex: ` + props.flex + `;`
    }   
    if (props.margin) {
        propsStyles += `margin: ` + props.margin + `;`
    }
    if (props.cursor) {
        propsStyles += `cursor: ` + props.cursor + `;`
    }

    if (props.customStyle) {
        propsStyles += props.customStyle;
    }

    return propsStyles
}}
`;
const Text = styled.label`
    
   
    ${props => {
    let propsStyles = ``;
    if (props.font) {
        propsStyles += `font: var(` + props.font + `);`
    } else {
        propsStyles += `font: var(--text-default-font);`
    }

    if (props.color) {
        propsStyles += `color: var(` + props.color + `);`
    } else {
        propsStyles += `color: var(--text-default-color);`
    }
    if (props.cursor) {
        propsStyles += `cursor: ` + props.cursor + `;`
    }
    
    if (props.customStyle) {
        propsStyles += props.customStyle
    }
    return propsStyles
}}
`;


class Label extends Component {
    render() {
        let {children, customStyle, id, ellipsis, color, font, textAlign, flex,cursor} = this.props;

        return (
            <LabelContainer
                {...this.props}
                id={randomIdGenerator(id, "LabelContainer")}
                ellipsis={ellipsis}
                color={color}
                textAlign={textAlign}
                flex={flex}
                cursor={cursor}
                customStyle={customStyle && customStyle.container}
            >
                <Text
                    cursor={cursor}
                    id={randomIdGenerator(id, "Label")}
                    color={color}
                    font={font}
                    customStyle={customStyle && customStyle.label}
                >{children}</Text></LabelContainer>
        );
    }
}

/*
* props
*
* ellipsis, se non specificato il numero setta il clamp ad 1, altrimenti aggiunge il valore numerico passato
*
* color, nome della variabile css da usare come colore della label es: --text-default-color
*
* font, nome della cariabile css da usare come font della label es: --text-default-font
*
* textAlign, nome dell'allineamento del testo es: right,left,center,justify
*
*/
Label.propTypes = {
    id: PropTypes.string,
    ellipsis: PropTypes.number,
    color: PropTypes.string,
    font: PropTypes.string,
    customStyle: PropTypes.object,
    flex: PropTypes.number,
    textAlign: PropTypes.string

};
export default Label