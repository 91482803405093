import React from "react";
import styled from 'styled-components';
import {Title} from '../../components/StyledDiv';
import Button from "@material-ui/core/Button";
import {
    deleteMediaServer,
    getMediaServer,
    saveMediaServer,
    saveRawEpisode,
    saveUncategorized,
} from "../../constants/StorageHelper";
import {importLinkMassive, shareAllSeries} from "../../constants/ShareLibraryHelper";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import BaseAxios from "../../constants/ApiHelpers/BaseAxios";
import {FileCopy} from "@material-ui/icons";
import {RowContainer} from "../SearchScreen/SearchScreen";
import BaseComponent from "../../interfaces/BaseComponent";
import HeaderTop from "../../components/HeaderTop";
import {TreeItem} from "@material-ui/lab";
import {LANGUAGE, TMDB_KEY} from "../../constants/Config";
import {v4 as uuid4} from "uuid";
import {withRouter} from "react-router-dom";
import {copyToClipboard} from "../../constants/CommonUtils";
import {DropzoneArea} from "material-ui-dropzone";


const DetailContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px; //THIS make scrolling vertical work in other browser flex tips and trick https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
  flex-direction: column;
  padding-top: 73px;
  overflow: auto;
`;
const SourceSelector = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px; //THIS make scrolling vertical work in other browser flex tips and trick https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  align-self: center;
`;

class ConfScreen extends BaseComponent {
    state = {
        tabSelected: 0,
        mediaserver: "",
        mediaServers: [],
        files:[]
    };

    async componentDidMount() {
        await this.refreshData()
    }

    refreshData = async () => {
        let res = await getMediaServer()
        this.setState({
            mediaServers: res
        })

    }

    selectTab = (event, tabSelected) => {
        this.setState({tabSelected});
    };

    renderTree = (tree) => {
        let output = [];
        for (let directory of tree.directories) {
            output.push(this.renderTree(directory));
        }
        for (let file of tree.files) {
            output.push(<TreeItem nodeId={uuid4()} onLabelClick={() => {
                console.log("CHECK CLICK", file.absolutePath);
            }} label={<h5>{file.name}</h5>}/>)
        }

        return <TreeItem nodeId={uuid4()} label={<h5>{tree.name}</h5>}>
            {output}
        </TreeItem>
    }

    pathHandler = (treeData, name) => {
        for (let i of treeData) {
            if (i.name == name) {
                return i;
            } else {
                let result = this.pathHandler(i.directories, name)
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }


    // readImportedData = async (array) => {
    //     //TODO Leggere un array di metadati prodotti dal jar o un csv insomma
    //     try {
    //         this.setLoading(true, "ConfScreen - scanMediaServer")
    //         let addedRecord = [];
    //         let uncategorized = [];
    //         let rawRecord = [];
    //
    //         if (mediaserver.includes("?")) {
    //             let searchParams = new URLSearchParams(mediaserver.substr(mediaserver.indexOf("?")));
    //             let embyBasePath = new URL(mediaserver.substr(0, mediaserver.indexOf("?") - 1));
    //             let res = await BaseAxios.get(embyBasePath + "/Items?Recursive=true&Fields=ProviderIds,MediaStreams,Path&SortBy=Sortname&MediaType=video&Filters=IsNotFolder&ExcludeItemTypes=Person&IncludeItemTypes=series,movie", {
    //                 headers: {
    //                     "X-Emby-Token": searchParams.get("token"),
    //                     "Authorization": "Emby UserId=\"" + uuid4() + "\",Client=\"" + navigator.oscpu + "\", Device=\"" + MediaDeviceInfo.label + "\", DeviceId=\"" + MediaDeviceInfo.deviceId + "\", Version=\"1.0.0.0\""
    //                 }
    //             });
    //             for (let x of res.data.Items) {
    //                 let name = x.Name;
    //                 try {
    //                     let re = new RegExp('.\\d[x|E]\\d\\d');
    //                     let episode = name.match(re);
    //                     let extrapolatedInfo = episode["0"].replace(".", "");
    //                     let showName = episode.input.substr(0, episode.index - 1).replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ');
    //                     let seasonNumber = extrapolatedInfo.substr(0, extrapolatedInfo.toLowerCase().includes("x") ? extrapolatedInfo.toLowerCase().indexOf("x") : extrapolatedInfo.toLowerCase().indexOf("e"))
    //                     let episodeNumber = extrapolatedInfo.substr((extrapolatedInfo.toLowerCase().includes("x") ? extrapolatedInfo.toLowerCase().indexOf("x") : extrapolatedInfo.toLowerCase().indexOf("e")) + 1, extrapolatedInfo.length)
    //                     let detailResponse = await BaseAxios.get("https://api.themoviedb.org/3/search/tv?api_key=" + TMDB_KEY + "&language=" + LANGUAGE + "&query=" + showName + "&include_adult=true")
    //                     if (detailResponse.data && detailResponse.data.results && detailResponse.data.results[0]) {
    //                         let detail = (await BaseAxios.get("https://api.themoviedb.org/3/tv/" + detailResponse.data.results[0].id + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE)).data
    //                         if ((detail.number_of_seasons - 1) < (parseInt(seasonNumber) - 1)) {
    //                             let input = {
    //                                 idShow: detailResponse.data.results[0].id,
    //                                 inputDate: new Date(),
    //                                 episodeName: name.replace(/\.[^/.]+$/, ""),
    //                                 seen: false,
    //                                 link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
    //                             }
    //                             rawRecord.push(input);
    //                         }
    //                         let input = {
    //                             tvShowId: "" + detailResponse.data.results[0].id,
    //                             episodeNumber: parseInt(episodeNumber),
    //                             seasonNumber: parseInt(seasonNumber) - 1,
    //                             showName: detailResponse.data.results[0].name,
    //                             episodeName: "",
    //                             link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
    //                         }
    //                         if (isNaN(parseInt(seasonNumber))) {
    //                             console.error("ERROR TO SEE: ", extrapolatedInfo, extrapolatedInfo.substr(0, extrapolatedInfo.toLowerCase().includes("x") ? extrapolatedInfo.toLowerCase().indexOf("x") : extrapolatedInfo.toLowerCase().indexOf("e")));
    //                         }
    //                         addedRecord.push(input);
    //                     }
    //                 } catch (e) {
    //                     try {
    //                         let parentFolder = x.Path.split("/")[x.Path.split("/").length - 2]
    //                         let detailResponse = await BaseAxios.get("https://api.themoviedb.org/3/search/tv?api_key=" + TMDB_KEY + "&language=" + LANGUAGE + "&query=" + parentFolder + "&include_adult=true")
    //                         if (detailResponse.data && detailResponse.data.results && detailResponse.data.results[0]) {
    //                             let input = {
    //                                 idShow: detailResponse.data.results[0].id,
    //                                 inputDate: new Date(),
    //                                 episodeName: name.replace(/\.[^/.]+$/, ""),
    //                                 seen: false,
    //                                 link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
    //                             }
    //                             rawRecord.push(input);
    //                         } else {
    //                             let input = {
    //                                 fileName: name.replace(/\.[^/.]+$/, ""),
    //                                 mimeType: x.Container,
    //                                 link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
    //                             }
    //                             uncategorized.push(input);
    //                         }
    //                     } catch (e2) {
    //                         console.error("File skipped still not supported: ", e2, name);
    //                     }
    //                 }
    //
    //             }
    //             await importLinkMassive(addedRecord);
    //             await saveUncategorized(uncategorized);
    //             await saveRawEpisode(rawRecord);
    //             await saveMediaServer(mediaserver);
    //             this.setState({addedRecord, uncategorized, rawRecord});
    //         }
    //     } catch (e) {
    //         console.log("ERRORE: ", e);
    //     } finally {
    //         this.setLoading(false, "ConfScreen - scanMediaServer")
    //     }
    // }


    scanMediaServer = async (mediaserver) => {
        try {
            this.setLoading(true, "ConfScreen - scanMediaServer")
            let addedRecord = [];
            let uncategorized = [];
            let rawRecord = [];

            if (mediaserver.includes("?")) {
                let searchParams = new URLSearchParams(mediaserver.substr(mediaserver.indexOf("?")));
                let embyBasePath = new URL(mediaserver.substr(0, mediaserver.indexOf("?") - 1));
                let res = await BaseAxios.get(embyBasePath + "/Items?Recursive=true&Fields=ProviderIds,MediaStreams,Path&SortBy=Sortname&MediaType=video&Filters=IsNotFolder&ExcludeItemTypes=Person&IncludeItemTypes=series,movie", {
                    headers: {
                        "X-Emby-Token": searchParams.get("token"),
                        "Authorization": "Emby UserId=\"" + uuid4() + "\",Client=\"" + navigator.oscpu + "\", Device=\"" + MediaDeviceInfo.label + "\", DeviceId=\"" + MediaDeviceInfo.deviceId + "\", Version=\"1.0.0.0\""
                    }
                });
                for (let x of res.data.Items) {
                    let name = x.Name;
                    try {
                        let re = new RegExp('.\\d[x|E]\\d\\d');
                        let episode = name.match(re);
                        let extrapolatedInfo = episode["0"].replace(".", "");
                        let showName = episode.input.substr(0, episode.index - 1).replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ' ');
                        let seasonNumber = extrapolatedInfo.substr(0, extrapolatedInfo.toLowerCase().includes("x") ? extrapolatedInfo.toLowerCase().indexOf("x") : extrapolatedInfo.toLowerCase().indexOf("e"))
                        let episodeNumber = extrapolatedInfo.substr((extrapolatedInfo.toLowerCase().includes("x") ? extrapolatedInfo.toLowerCase().indexOf("x") : extrapolatedInfo.toLowerCase().indexOf("e")) + 1, extrapolatedInfo.length)
                        let detailResponse = await BaseAxios.get("https://api.themoviedb.org/3/search/tv?api_key=" + TMDB_KEY + "&language=" + LANGUAGE + "&query=" + showName + "&include_adult=true")
                        if (detailResponse.data && detailResponse.data.results && detailResponse.data.results[0]) {
                            let detail = (await BaseAxios.get("https://api.themoviedb.org/3/tv/" + detailResponse.data.results[0].id + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE)).data
                            if ((detail.number_of_seasons - 1) < (parseInt(seasonNumber) - 1)) {
                                let input = {
                                    idShow: detailResponse.data.results[0].id,
                                    inputDate: new Date(),
                                    episodeName: name.replace(/\.[^/.]+$/, ""),
                                    seen: false,
                                    link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
                                }
                                rawRecord.push(input);
                            }
                            let input = {
                                tvShowId: "" + detailResponse.data.results[0].id,
                                episodeNumber: parseInt(episodeNumber),
                                seasonNumber: parseInt(seasonNumber) - 1,
                                showName: detailResponse.data.results[0].name,
                                episodeName: "",
                                link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
                            }
                            if (isNaN(parseInt(seasonNumber))) {
                                console.error("ERROR TO SEE: ", extrapolatedInfo, extrapolatedInfo.substr(0, extrapolatedInfo.toLowerCase().includes("x") ? extrapolatedInfo.toLowerCase().indexOf("x") : extrapolatedInfo.toLowerCase().indexOf("e")));
                            }
                            addedRecord.push(input);
                        }
                    } catch (e) {
                        try {
                            let parentFolder = x.Path.split("/")[x.Path.split("/").length - 2]
                            let detailResponse = await BaseAxios.get("https://api.themoviedb.org/3/search/tv?api_key=" + TMDB_KEY + "&language=" + LANGUAGE + "&query=" + parentFolder + "&include_adult=true")
                            if (detailResponse.data && detailResponse.data.results && detailResponse.data.results[0]) {
                                let input = {
                                    idShow: detailResponse.data.results[0].id,
                                    inputDate: new Date(),
                                    episodeName: name.replace(/\.[^/.]+$/, ""),
                                    seen: false,
                                    link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
                                }
                                rawRecord.push(input);
                            } else {
                                let input = {
                                    fileName: name.replace(/\.[^/.]+$/, ""),
                                    mimeType: x.Container,
                                    link: embyBasePath + "/Videos/" + x.Id + "/stream.mp4?api_key="+searchParams.get("token")
                                }
                                uncategorized.push(input);
                            }
                        } catch (e2) {
                            console.error("File skipped still not supported: ", e2, name);
                        }
                    }

                }
                await importLinkMassive(addedRecord);
                await saveUncategorized(uncategorized);
                await saveRawEpisode(rawRecord);
                await saveMediaServer(mediaserver);
                this.setState({addedRecord, uncategorized, rawRecord});
            }
        } catch (e) {
            console.log("ERRORE: ", e);
        } finally {
            this.setLoading(false, "ConfScreen - scanMediaServer")
        }
    }

    renderMobile = () => {
        return this.renderDesktop();
    }

    handleChange(files) {
        this.setState({
            files: files
        });
    }
    renderDesktop = () => {
        let {tabSelected, shareLink, mediaserver, mediaServers, files} = this.state
        return (<DetailContainer>
                <HeaderTop id={"hidableHeader"}/>
                <Tabs
                    value={tabSelected}
                    indicatorColor="primary"
                    textColor="secondary"
                    onChange={this.selectTab}
                    style={{alignSelf: "center"}}
                >
                    <Tab label="Share"/>
                    <Tab label="Emby server"/>
                    {/*<Tab label="Import data"/>*/}
                </Tabs>
                <SourceSelector>
                    <Title>Configure your data</Title>
                    <Divider orientation="horizontal"/>

                    {tabSelected == 2 && <>
                        <span
                            style={{width: "80%", display: "flex", flexDirection: "column"}}
                        >
                            <DropzoneArea
                                dropzoneText={"Trascina qui il file oppure premi"}
                                onChange={this.handleChange.bind(this)}
                                acceptedFiles={['application/json']}
                                filesLimit={1}
                                maxFileSize={10000000}
                                showFileNames
                                useChipsForPreview
                                showPreviewsInDropzone={true}
                            />
                            {files && files[0] && <Button
                                     variant={"contained"}
                                     style={{alignSelf: "flex-end", marginTop: 10}} color={"secondary"}
                                     onClick={async () => {
                                         try {

                                             console.log("DATA TO READ: ", await this.state.files[0].text())
                                             // await databaseUpload(this.state.files[0]);
                                             // this.props.history.push("/")
                                         } catch (e) {
                                             alert("Something get wrong! \n" + e)
                                         } finally {
                                             // this.setState({
                                             //     files: []
                                             // })
                                         }
                                     }}>
                                Importa i dati
                            </Button>}
                </span>
                    </>}
                    {tabSelected == 0 && <>
                        <Button variant={"contained"} style={{marginBottom: "10px"}} color={"primary"} onClick={() => {
                            shareAllSeries().then(r => this.setState({shareLink: r}));
                        }}>
                            Share all your episode with a link
                        </Button>
                        <Divider orientation="horizontal"/>
                        {shareLink && <span><h6>Copy to clipboard:</h6>
                            {shareLink && <RowContainer>
                                <h3 style={{fontSize: 14}}>{shareLink}</h3>
                                <Button color={"secondary"} startIcon={<FileCopy/>}
                                        onClick={ () => {
                                             copyToClipboard(shareLink)
                                        }}
                                >Copy to clipboard!</Button>
                            </RowContainer>}
                        </span>}
                    </>}
                    {tabSelected == 1 && <>
                        <h5>Insert here the path of your emby server (Es. "https://[[DOMAIN]]/?token=[[API_TOKEN]]"): </h5>
                        <TextField
                            autoFocus
                            id="mediaserver"
                            variant={"filled"}
                            fullWidth
                            required
                            value={mediaserver}
                            onChange={(e) => {
                                this.setState({mediaserver: e.target.value})
                            }}
                            color={"secondary"}
                            style={{marginBottom: "10px", maxHeight: "200px"}}
                        />
                        <Button variant={"contained"} color={"primary"} onClick={async () => {
                            await this.scanMediaServer(mediaserver);
                            await this.refreshData()
                        }}>
                            Import metadata
                        </Button>
                        {mediaServers && mediaServers.length > 0 && (<h5>Already imported mediaserver:</h5>)}
                        {mediaServers && mediaServers.map((x, index) => {
                                return (<RowContainer key={"mediaserver" + index}>
                                        <h5>{x.host.includes("?") ? x.host.substr(0, x.host.indexOf("?")) : x.host}</h5>
                                        <Button style={{marginLeft: "20px"}} variant={"contained"} color={"primary"}
                                                onClick={async () => {
                                                    await this.scanMediaServer(x.host);
                                                }
                                                }>Update</Button>
                                        <Button style={{marginLeft: "20px"}} variant={"contained"} color={"secondary"}
                                                onClick={async () => {
                                                    await deleteMediaServer(x.id);
                                                    await this.refreshData();
                                                }
                                                }>Delete</Button>
                                    </RowContainer>
                                )
                            }
                        )}
                    </>}
                </SourceSelector>
            </DetailContainer>
        )
    }

}


export default withRouter(ConfScreen);
