import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Settings from "@material-ui/icons/Settings";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Close from "@material-ui/icons/Close";
import VideogameAsset from "@material-ui/icons/VideogameAsset";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Save from "@material-ui/icons/Save";
import Switch from "@material-ui/core/Switch";
import Peer from 'peerjs';
import {v4 as uuid4} from "uuid"
import VideoPlayer, {getTypeVideo} from "../../components/VideoPlayer";
import HeaderTop from "../../components/HeaderTop";
import moment from "moment";
import {withRouter} from "react-router-dom";
import {Collapse, Grid} from "@material-ui/core";
import style from "../../assets/Share.module.css"
import {DeleteForever} from "@material-ui/icons";
import {GlobalContext} from "../../constants/CommonConstants";
import MobileButtonListV2 from "../../components/MobileButtonListV2";
import BaseComponent from "../../interfaces/BaseComponent";
import {getRawEpisode} from "../../constants/StorageHelper";
import {LANGUAGE, TMDB_KEY} from "../../constants/Config";
import EpisodeCard from "../../components/EpisodeCard";
import MyVideoScreen from "../MyVideoScreen/MyVideoScreen";
import BaseAxios from "../../constants/ApiHelpers/BaseAxios";
import BackArrowComponent from "../../components/BackArrowComponent";
import {copyToClipboard} from "../../constants/CommonUtils";
import LoadingSystem from "../../components/LoadingSystem";
import {ColumnContainer, Label} from "../../components/ComponentManager/ComponentManager";
import {RowContainer} from "../SearchScreen/SearchScreen";


export const peerOptions = {
    // host: "app.drakofeature.cf",
    // port: '443',
    // path: '/myapp',
    // secure: true
}
export const peerOptionsDefault = {

}

class ShareScreen extends BaseComponent {
    state = {
        linkSrc: localStorage.getItem("linkSrc") || "https://server16.streamingaw.online/DDL/ANIME/AvatarLaLeggendaDiAang/AvatarLaLeggendaDiAang_Ep_45_ITA.mp4",
        peer: null,
        sources: [],
        connections: [],
        inRoomConnections: [],
        userInRoomList: [],
        roomName: null,
        isHost: true,
        nickName: localStorage.getItem("nickName") || "",
        pageStatus: "L",
        enablePw: false,
        roomPw: "",
        forcePlayFromHost: false,
        enableUserListSettings: false,
        steps: [],
        seasonNumber: 0,
        choosingEpisodeFromList: false,
        disableShowList: false
    };
    childRef = React.createRef();
    blockSync = false

    seasonDetail = async (state = this.state) => {
        try {
            let {seasonNumber, id} = state;
            let response = await this.getEpisodes(seasonNumber, id);
            let episodes = response.data.episodes;


            this.setState({...state, seasonDetail: response.data, episodes})
        } catch (e) {
            console.error("Error while recovering seasonDetail", e.response)
        } finally {
            this.setLoading(false, "ShowDetailScreen - refreshEpisodes")

        }

    };

    getDetailTmdb = (id) => {
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    getEpisodes = (seasonNumber, id) => {
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "/season/" + (seasonNumber + 1) + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    getSimilarTmdb = (id) => {
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "/similar?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    getProviderTmdb = (id) => {
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "/watch/providers?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };
    refreshEpisodes = async (id) => {
        try {
            this.setLoading(true, "ShowDetailScreen - refreshEpisodes")
            let response = await this.getDetailTmdb(id);
            let responseProvider = await this.getProviderTmdb(id);
            let rawEpisodes = await getRawEpisode(id);

            let steps = [];
            for (let i = 0; i < response.data.number_of_seasons; i++) {
                steps.push(i + 1);
            }


            let provider = responseProvider.data.results
            await this.seasonDetail({
                steps,
                id,
                show: response.data,
                loading: false,
                rawEpisodes,
                disableShowList: true,
                seasonNumber: this.state.seasonNumber,
                provider: provider[LANGUAGE.toUpperCase()] || provider[Object.keys(provider)[0]]
            })

        } catch (e) {
            console.error("Error while refreshing Episodes", e, e.response)
        }
    };

    async componentDidMount() {
        let scrollingGrid = document.getElementById("griddonaShareRoom")
        scrollingGrid.addEventListener("scroll", this.handleHeaderOnScroll)
        let pageStatus
        let peerIdFromUrl = this.getPeerIdFromUrl()
        if (peerIdFromUrl) {
            pageStatus = "SC"
        } else {
            pageStatus = "SH"
        }

        this.setState({pageStatus})

    }

    setupPeers = (peer) => {
        try {
            // on open will be launch when you successfully connect to PeerServer
            peer.on('open', (id) => {
                let peerIdFromUrl = this.getPeerIdFromUrl()
                this.setState(p => {
                    p.peer = peer
                    p.peerId = id
                    if (peerIdFromUrl) {
                        p.roomName = peerIdFromUrl
                        p.isHost = false

                    } else {
                        p.roomName = id
                        p.isHost = true
                        p.userInRoomList.push({nickName: p.nickName, peerId: id, syncTiming: 500})
                    }
                    return p
                }, () => {

                    let conn = peer.connect(peerIdFromUrl);

                    conn.on('open', () => {
                        let data = {
                            action: "CONN",
                            delay: JSON.stringify(moment()),
                            peerId: id,
                            userInfo: {nickName: this.state.nickName, peerId: id, syncTiming: 500}
                        }
                        conn.send(data)
                        this.handleTransaction({...data, conn, isClient: true})

                    });

                });
            });
            peer.on("error", (e) => {
                console.error("errore connessione peer" + e, e)
                if (e && String(e).includes("Lost connection to server")) {
                    let {roomName} = this.state
                    let peer = new Peer(roomName, peerOptionsDefault)
                    this.setupPeers(peer)
                } else {
                    console.error("The service is not available right now, we are sorry, try later!")
                }
            })

            peer.on('disconnect', (e) => {
                console.error("cosa succede in questa disconnessione peer", e)
                //TODO gestire la disconnessione dalla stanza comunicandolo a tutti gli altri
                // Se il creatore della stanza esce -> se era l'host lo passa ad uno a caso, gli altri continuano a guardare ma nessuno può joinare
                // Se esce uno stronzo random lo comunica agli altri e lo leva dalla lista utenti in stanza e dalle connessioni
            })


            peer.on('connection', (conn) => {
                conn.on('open', () => {
                    // Receive messages
                    conn.on("error", (e) => {
                        console.error("errore apertura conn", e)
                    })
                    conn.on('data', (data) => {
                        let {connections, peerId, isHost} = this.state;
                        // Se non ho una connessione aperta con questo peer, procedo a connettermi con lo stesso
                        if (!connections.some(x => x.peer === data.peerId)) {
                            // I peer sono connessi e sono pronti a scambiare i dati
                            let connectToReceivedPeer = peer.connect(data.peerId);
                            connectToReceivedPeer.on('open', () => {
                                // La connessione è stata aperta
                                let {inRoomConnections, nickName, userInRoomList} = this.state;

                                if (data.action === "CONN") {


                                    // Gestione connessione
                                    if (!this.state.enablePw) {
                                        if (!userInRoomList.some(x => x.peerId === data.userInfo.peerId)) {
                                            userInRoomList.push(data.userInfo)
                                        }
                                        inRoomConnections.forEach((connection) => {
                                            let newPeer = {
                                                action: "NEW_PEER",
                                                peerId,
                                                newPeerId: data.peerId,
                                                userInRoomList,
                                                delay: JSON.stringify(moment()),
                                            };
                                            connection.send(newPeer)
                                        })


                                        if (!inRoomConnections.some(x => x.peer === data.peerId)) {
                                            inRoomConnections.push(connectToReceivedPeer)
                                        }
                                        //Se è una stanza senza pw
                                        if (this.state.sources.length > 0) {
                                            // Se ho già una stanza con un video caricato
                                            let currentTime = this.childRef.player.currentTime()
                                            let newPeer = {
                                                action: "LINK",
                                                src: this.state.sources[0].src,
                                                currentTime,
                                                peerId,
                                                delay: JSON.stringify(moment()),
                                                forcePlay: !this.childRef.player.paused()
                                            };
                                            connectToReceivedPeer.send(newPeer)
                                        } else {
                                            // Mi connetto normalmente

                                            let newPeer = {
                                                action: "CONNECTED",
                                                delay: JSON.stringify(moment()),
                                                userInRoomList,
                                                peerId
                                            };
                                            connectToReceivedPeer.send(newPeer)
                                        }


                                    } else {
                                        // È una stanza con pw
                                        let newPeer = {//TODO non worka la stanza con la pw
                                            action: "SEND_PW",
                                            delay: JSON.stringify(moment()),
                                            peerId
                                        };
                                        connectToReceivedPeer.send(newPeer)
                                    }

                                }

                                this.setState(p => {
                                    if (!p.connections.some(x => x.peer === data.peerId)) {
                                        p.connections.push(connectToReceivedPeer)
                                    }
                                    p.inRoomConnections = inRoomConnections
                                    p.userInRoomList = userInRoomList
                                    return p
                                })
                            });
                        }

                        // Sono arrivati dei dati da un peer, li gestisco
                        this.handleTransaction(data);
                    });
                });
            });
            // peer.on('call', (call) => {//TODO implementare sharing
            //     call.answer();
            //     call.on('stream', (remoteStream) => {
            //         this.setState({enableVideo: true}, () => {
            //
            //             let video = document.getElementById("screenSharing")
            //             video.srcObject = remoteStream
            //             video.play()
            //         })
            //     });
            // });
        } catch (e) {
            console.error("ERROR: ", e)

        }

    }

    setupClient = () => {
        let id = uuid4()
        let peer = new Peer(id, peerOptions)
        this.setupPeers(peer)
    }

    renderSetupClient = () => {
        let {nickName} = this.state

        return <Grid container spacing={0}
                     style={{padding: "10px", overflow: "auto"}}>
            <Grid item xs={12} style={{padding: "10px"}}>
                <Label font={"--text-normal-20"}>
                    Welcome to QuiX room sharing, you are going to connect to the room: "{this.getPeerIdFromUrl()}"
                </Label>
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <Label font={"--text-normal-16"}>
                    Pick your nickname
                </Label>
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <TextField id={"nicknameTextField"}
                           value={nickName}
                           name={"nicknameTextField"}
                           fullWidth
                           onChange={(e) => {
                               let nickName = e.target.value
                               this.setState({nickName})
                           }}
                />
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <Button onClick={() => {

                    let {nickName} = this.state
                    if (nickName.length >= 1) {
                        localStorage.setItem("nickName", nickName)
                        this.setupClient()
                        //TODO mettere un worker che conta, se passano più di 2 secondi, riprovare a riconnettersi
                        this.setState({pageStatus: "L"})
                    }
                }} color={"primary"} variant={"contained"}>Connect</Button>
            </Grid>
        </Grid>
    }

    renderSetupHost = () => {
        let {nickName, enablePw} = this.state

        let roomsAlreadyCreated = JSON.parse(localStorage.getItem("rooms")) || []
        let roomList = false
        if (roomsAlreadyCreated && roomsAlreadyCreated.length > 0) {
            roomList = roomsAlreadyCreated.map((room, index) => {
                return <Grid item xs={12} key={"roomButton" + room + uuid4()} style={{padding: "10px 0"}}>
                    <RowContainer customStyle={"width:100%;"}><Button variant={"contained"}
                                                                      fullWidth
                                                                      onClick={() => {
                                                                          this.setState({roomName: room}, () => this.handleSubmitFormSetupHost(roomsAlreadyCreated))
                                                                      }}
                                                                      color={"secondary"}>{room}</Button>
                        <Button color={"secondary"}

                                onClick={() => {
                                    roomsAlreadyCreated.splice(index, 1)
                                    localStorage.setItem("rooms", JSON.stringify(roomsAlreadyCreated))
                                    this.forceUpdate()
                                }}>
                            <DeleteForever/>
                        </Button></RowContainer>
                </Grid>
            })
        }

        return <form className={style.form} onSubmit={(e) => {
            e.preventDefault()
            this.handleSubmitFormSetupHost(roomsAlreadyCreated)

        }}><Grid container spacing={0}
                 style={{padding: "10px", overflow: "auto"}}>
            <Grid item xs={12} style={{padding: "10px"}}>
                <Label font={"--text-normal-20"}>
                    Welcome to QuiX room sharing
                </Label></Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <Label font={"--text-normal-16"}>
                    Pick your nickname
                </Label>
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <TextField id={"nicknameTextField"}
                           name={"nicknameTextField"}
                           required
                           value={nickName}
                           fullWidth
                           onChange={(e) => {
                               let nickName = e.target.value
                               this.setState({nickName})
                           }}
                />
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>

                <Collapse in={this.state.nickName.length > 0} timeout={1000}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{padding: "10px 0"}}>

                            <Label font={"--text-normal-16"}>
                                Choose a name for the room
                            </Label>
                        </Grid>
                        <Grid item xs={12} style={{padding: "10px 0"}}>

                            <TextField id={"roomTextField"} name={"roomTextField"} fullWidth
                                       required
                                       onChange={(e) => {
                                           let roomName = e.target.value
                                           this.setState({roomName})
                                       }}
                            />
                        </Grid>
                        {roomList && <><Grid item xs={12} style={{padding: "10px 0"}}>

                            <Label font={"--text-normal-16"}>
                                Or pick an existing one
                            </Label>
                        </Grid>
                            <Grid container spacing={0}>
                                {roomList}
                            </Grid>
                        </>}
                    </Grid>
                </Collapse>
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <RowContainer font={"--text-normal-16"} justifyContent={"flex-start"}>
                    <Label>With password</Label>
                    <Switch checked={enablePw}
                            color={"secondary"}
                            onChange={(event, checked) => {
                                this.setState({enablePw: checked})
                            }} name="enablePw"/>
                </RowContainer>
            </Grid>
            <Grid item xs={12} style={{padding: "10px"}}>
                <Collapse in={enablePw} timeout={1000}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{padding: "10px 0"}}>
                            <Label font={"--text-normal-16"}>
                                Choose a password
                            </Label>
                        </Grid>
                        <Grid item xs={12} style={{padding: "10px 0"}}>
                            {enablePw && <TextField id={"roomTextField"} name={"roomTextField"} fullWidth
                                                    required
                                                    onChange={(e) => {
                                                        let roomPw = e.target.value
                                                        this.setState({roomPw})
                                                    }}
                            />}
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>

            <Grid item xs={12} style={{padding: "10px", display: "flex", justifyContent: "flex-end"}}>
                <Button type={"submit"} color={"primary"} variant={"contained"}>Create</Button>
            </Grid>

        </Grid></form>

    }

    handleSubmitFormSetupHost = (roomsAlreadyCreated) => {
        let {roomName, nickName, enablePw, roomPw} = this.state
        if (enablePw && roomPw.length === 0) {
            enablePw = false
        }
        if (!roomsAlreadyCreated.includes(roomName)) {
            roomsAlreadyCreated.push(roomName)
            localStorage.setItem("rooms", JSON.stringify(roomsAlreadyCreated))
        }
        let peer = new Peer(roomName, peerOptions)
        localStorage.setItem("nickName", nickName)
        this.setupPeers(peer)
        this.setState({pageStatus: "H", enablePw,choosingEpisodeFromList:true})
    }

    getPeerIdFromUrl = () => {
        let {location} = this.props
        return new URLSearchParams(location.search).get('fr');
    }

    setPlayerCurrentTime = (currentTime) => {
        if (currentTime) {
            this.childRef.player.currentTime(currentTime / 1000)
        }
    }

    showHideVideoControls = () => {
        let {isHost} = this.state
        if (isHost) {
            this.childRef.player.controlBar.playToggle.show()
            this.childRef.player.bigPlayButton.show()
            this.childRef.player.controlBar.progressControl.seekBar.show()
        } else {
            this.childRef.player.controlBar.playToggle.hide()
            this.childRef.player.bigPlayButton.hide()
            this.childRef.player.controlBar.progressControl.seekBar.hide()
        }
    }

    handleTransaction = (data) => {

        if (data) {
            let {action, delay, peerId, forcePlay, currentTime, src, pw, userInRoomList, syncTiming} = data;
            let currentTimeInMs
            let hostCurrentTimeInMs
            let delayInMs = parseInt(moment().subtract(delay).format("SSS"))
            let {setDialogState, resetDialogState} = this.context
            switch (action) {
                case "CONN":
                    if (data.isClient) {
                        this.setState(p => {
                            if (!p.connections.some(x => x.peer === data.peerId)) {
                                p.connections.push(data.conn)
                            }
                            if (!p.inRoomConnections.some(x => x.peer === data.peerId)) {
                                p.inRoomConnections.push(data.conn)
                            }
                            if (!p.userInRoomList.some(x => x.peerId === data.userInfo.peerId)) {
                                p.userInRoomList.push(data.userInfo)
                            }

                            p.isHost = false
                            return p
                        })
                    }
                    break;
                case "LINK":
                    this.setState(p => {
                            p.sources = [//TODO switch case con controlli in abbondaza
                                {
                                    src: src,
                                    type: getTypeVideo(src)
                                },

                            ]

                            if (!p.isHost) {
                                if (currentTime !== "") {
                                    p.forcePlayFromHost = currentTime
                                }
                                p.pageStatus = "C"
                            } else {
                                p.linkSrc = src
                                p.choosingEpisodeFromList = false;
                                p.seasonNumber = 0
                            }

                            return p;
                        }, () => {
                            if (!this.state.isHost) {
                                this.handleTransaction({
                                    action: forcePlay ? "PLAY" : "PAUSE",
                                    currentTime: this.state.forcePlayFromHost,
                                    delay: JSON.stringify(moment()),
                                    peerId: this.state.peerId
                                })
                            }
                            if (this.childRef && this.childRef.player) {
                                this.showHideVideoControls()
                            }
                        }
                    )

                    break;
                case "PLAY":
                    hostCurrentTimeInMs = (Math.round(currentTime * 100) / 100) * 1000
                    this.setPlayerCurrentTime(hostCurrentTimeInMs)
                    this.childRef.player.play()
                    // this.childRef.player.muted(false);
                    break;
                case "PAUSE":
                    hostCurrentTimeInMs = (Math.round(currentTime * 100) / 100) * 1000
                    this.setPlayerCurrentTime(hostCurrentTimeInMs)
                    this.childRef.player.pause()
                    break;
                case "EDIT_SYNC_SETTINGS":
                    this.setState(p => {
                        let clientUser = userInRoomList.filter(user => user.peerId === p.peerId)[0]
                        p.userInRoomList = [clientUser, ...userInRoomList.filter(user => user.peerId !== p.peerId)]
                        return p
                    })
                    break;
                case "SYNC":
                    if (syncTiming !== "disabled") {
                        currentTimeInMs = (Math.round(this.childRef.player.currentTime() * 100) / 100) * 1000
                        hostCurrentTimeInMs = (Math.round(currentTime * 100) / 100) * 1000
                        let checkDesync = currentTimeInMs - hostCurrentTimeInMs - delayInMs
                        if (checkDesync < -syncTiming || checkDesync > syncTiming) {
                            this.setPlayerCurrentTime(hostCurrentTimeInMs + delayInMs)
                        }
                    }
                    break;

                case "NEW_PEER":
                    let newConnection = this.state.peer.connect(data.newPeerId);


                    newConnection.on('open', () => {
                        let connectData = {
                            action: "CONNECTED_NEW_PEER",
                            userInRoomList,
                            peerId: this.state.peerId,
                        }
                        newConnection.send(connectData)

                    });

                    this.setState(p => {
                        if (!p.inRoomConnections.some(x => x.peer === data.newPeerId)) {
                            p.inRoomConnections.push(newConnection)
                        }
                        if (!p.connections.some(x => x.peer === data.newPeerId)) {
                            p.connections.push(newConnection)
                        }
                        let clientUser = userInRoomList.filter(user => user.peerId === p.peerId)[0]
                        p.userInRoomList = [clientUser, ...userInRoomList.filter(user => user.peerId !== p.peerId)]
                        return p
                    })
                    break;
                case "CONNECTED_NEW_PEER":
                    let newConnection2 = this.state.peer.connect(data.peerId);
                    newConnection2.on('open', () => {
                        this.setState(p => {
                                if (!p.inRoomConnections.some(x => x.peer === data.peerId)) {
                                    p.inRoomConnections.push(newConnection2)
                                }
                                if (!p.connections.some(x => x.peer === data.peerId)) {
                                    p.connections.push(newConnection2)
                                }
                                let clientUser = userInRoomList.filter(user => user.peerId === p.peerId)[0]
                                p.userInRoomList = [clientUser, ...userInRoomList.filter(user => user.peerId !== p.peerId)]
                                return p
                            }
                        )

                    });

                    break;
                case "PING":
                    break;
                case "NICKNAME_CHANGE":
                    this.setState(p => {
                            let clientUser = userInRoomList.filter(user => user.peerId === p.peerId)[0]
                            p.userInRoomList = [clientUser, ...userInRoomList.filter(user => user.peerId !== p.peerId)]
                            return p
                        }
                    )
                    break
                case "GIVE_HOST":
                    this.setState(p => {
                            p.isHost = !p.isHost
                            if (p.isHost) {
                                p.pageStatus = "H"
                            } else {
                                p.pageStatus = "C"
                            }
                            if (this.childRef && this.childRef.player) {
                                this.showHideVideoControls()
                            }
                            return p
                        }
                    )
                    break;
                case "PW_OK":
                    resetDialogState()
                    this.handleTransaction({
                        action: "CONNECTED",
                        currentTime,
                        src,
                        forcePlay,
                        delay: JSON.stringify(moment()),
                        userInRoomList,
                        peerId: this.state.peerId
                    })
                    break;
                case "CONNECTED":
                    this.setState(p => {
                            p.pageStatus = "C"
                            let clientUser = userInRoomList.filter(user => user.peerId === p.peerId)[0]
                            p.userInRoomList = [clientUser, ...userInRoomList.filter(user => user.peerId !== p.peerId)]
                            if (src) {
                                this.handleTransaction({
                                    action: "LINK",
                                    src,
                                    delay: JSON.stringify(moment()),
                                    currentTime,
                                    forcePlay,
                                    peerId: this.state.peerId
                                })
                            }
                            return p
                        }
                    )
                    break;
                case "SEND_PW":
                    this.setState(p => {
                            if (!p.isHost) {

                                setDialogState({
                                    dialogIsOpen: true,
                                    paperSizeIsSmallest: true,
                                    title: "Password required to join",
                                    handleSubmit: () => {
                                    },
                                    handleClose: () => {
                                        resetDialogState()
                                        this.setState({pageStatus: "SC"})
                                    },
                                    customContent: <Grid container spacing={0}
                                                         style={{padding: "10px"}}>
                                        <Grid item xs={12} style={{padding: "10px"}}>
                                            <Label font={"--text-normal-16"}>
                                                Password
                                            </Label>
                                        </Grid>
                                        <Grid item xs={12} style={{padding: "10px"}}>
                                            <TextField id={"passwordTextField"}
                                                       name={"passwordTextField"}
                                                       fullWidth
                                            />
                                        </Grid>
                                    </Grid>,
                                    customActions: <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={() => {
                                            let tempPW = document.getElementById("passwordTextField").value
                                            p.connections.forEach((connection) => {
                                                let data = {
                                                    action: "CHECK_PW",
                                                    pw: tempPW,
                                                    userInfo: {
                                                        nickName: p.nickName,
                                                        peerId: p.peerId,
                                                        syncTiming: 500
                                                    },
                                                    delay: JSON.stringify(moment()),
                                                    peerId: p.peerId
                                                };
                                                connection.send(data)
                                            })
                                        }}
                                    >
                                        Send
                                    </Button>
                                })
                            }
                            return p
                        }
                    )

                    break;
                case "CHECK_PW":
                    this.setState(p => {
                            if (p.isHost) {
                                if (pw === p.roomPw) {

                                    if (!p.userInRoomList.some(x => x.peerId === data.userInfo.peerId)) {
                                        p.userInRoomList.push(data.userInfo)
                                    }

                                    p.connections.forEach((connection) => {
                                        if (peerId === connection.peer) {

                                            let newData = {
                                                action: "PW_OK",
                                                delay: JSON.stringify(moment()),
                                                userInRoomList: p.userInRoomList,

                                                peerId: p.peerId
                                            };
                                            if (p.sources.length > 0 && p.sources[0].src) {
                                                newData = {
                                                    ...newData,
                                                    currentTime: this.childRef.player.currentTime(),
                                                    src: this.state.sources[0].src,
                                                    forcePlay: !this.childRef.player.paused()
                                                };
                                            }


                                            connection.send(newData)
                                            if (!p.inRoomConnections.some(x => x.peer === newData.peerId)) {
                                                p.inRoomConnections.push(connection)
                                            }
                                        }
                                    })
                                } else {
                                    p.connections.forEach((connection) => {
                                        if (peerId === connection.peer) {
                                            let newData = {
                                                action: "PW_WRONG",
                                                delay: JSON.stringify(moment()),
                                                peerId: p.peerId
                                            };
                                            connection.send(newData)
                                        }
                                    })
                                }
                            }
                            return p
                        }
                    )

                    break;
                case "PW_WRONG":
                    alert("Wrong password")
                    break;
                case "ASK_TO_HOST":
                default:
                    break;
            }

        }


    }


    renderSideBarContent = () => {

        let {
            roomName,
            enableEditNickName,
            peerId,
            inRoomConnections,
            linkSrc,
            isHost,
            userInRoomList,
            enableUserListSettings
        } = this.state
        let urlToShare = "https://quix.altervista.org/r?fr="

        if (process.env.REACT_APP_DEV === "true") {
            urlToShare = "http://localhost:3069/r?fr="
        }
        return <>
            <ColumnContainer className={style.roomNameContainer}>
                <Label color={"--second-color"} font={"--text-normal-16"}>
                    Room name
                </Label>
                <InputBase
                    fullWidth
                    disabled={true}
                    endAdornment={<Button variant={"contained"} color={"secondary"}
                                          onClick={ () => {
                                              copyToClipboard(urlToShare + roomName)
                                          }
                                          }>Copy</Button>}
                    value={roomName} label={"Room name"} variant={"outlined"}

                />
            </ColumnContainer>
            {isHost &&
            <>
                <ColumnContainer className={style.roomNameContainer}>
                    <Label color={"--second-color"} font={"--text-normal-16"}>Link to episode</Label>
                    <InputBase value={linkSrc || ""}
                               startAdornment={<IconButton
                                   color={"secondary"}
                                   onClick={() => this.setState({choosingEpisodeFromList: true})}
                               >
                                   <PlaylistAdd color={"secondary"} fontSize={"large"}/>
                               </IconButton>}
                               endAdornment={<Button
                                   variant={"contained"}
                                   color={"secondary"}
                                   onClick={() => {
                                       try {
                                           inRoomConnections.forEach((connection) => {
                                               let data = {
                                                   action: "LINK",
                                                   src: linkSrc,
                                                   peerId
                                               };
                                               connection.send(data)
                                               this.handleTransaction(data);
                                           })
                                       } catch (e) {
                                           console.log("ERROR sending link", e)
                                       }
                                   }}>Start</Button>}
                               onChange={(e) => {
                                   let linkSrc = e.target.value
                                   localStorage.setItem("linkSrc", linkSrc)
                                   this.setState({linkSrc})
                               }}/>

                    {/*<Button variant={"contained"} color={"primary"} onClick={() => {*/}

                    {/*    try {*/}
                    {/*        connections.forEach((connection, index) => {*/}

                    {/*            let data = {*/}
                    {/*                action: "PING",
                         delay: JSON.stringify(moment()),*/}
                    {/*                peerId*/}
                    {/*            };*/}
                    {/*            connection.send(data)*/}
                    {/*        })*/}
                    {/*    } catch (e) {*/}
                    {/*        console.log("ERROR testing ping", e)*/}
                    {/*    }*/}

                    {/*}}>Ping</Button>*/}
                </ColumnContainer>

            </>
            }
            {userInRoomList && userInRoomList.length > 0 &&
            <ColumnContainer className={style.uiContainerSamePadding}>
                <RowContainer customStyle={"width:100%;"}>
                    <Label color={"--second-color"} font={"--text-normal-16"}>User in the room</Label>
                    {isHost && <IconButton color={"secondary"} onClick={() => {
                        this.setState({
                            enableUserListSettings: !this.state.enableUserListSettings
                        })
                    }}>
                        {enableUserListSettings ? <Close/> : <Settings/>}
                    </IconButton>}
                </RowContainer>
                {userInRoomList.map(user => {
                    let userIsMe = user.peerId === peerId
                    let editButton = <IconButton color={"secondary"}
                                                 onClick={() => this.setState({enableEditNickName: true}, () => {
                                                     document.getElementById("userNameInput").focus()
                                                 })}><Edit/></IconButton>

                    if(enableEditNickName){
                        editButton = <IconButton color={"secondary"}
                                                 type={"submit"}
                                                 >
                            <Save/></IconButton>
                    }
                    let giveHostButton = <Button color={"secondary"}
                                                 variant={"contained"}
                                                 endIcon={<VideogameAsset/>}
                                                 onClick={() => {
                                                     inRoomConnections.forEach((connection) => {
                                                         if (connection.peer === user.peerId) {
                                                             let data = {
                                                                 action: "GIVE_HOST",
                                                                 delay: JSON.stringify(moment()),
                                                                 peerId
                                                             };
                                                             connection.send(data)
                                                             this.handleTransaction(data)
                                                         }
                                                     })
                                                 }}>
                        Give host
                    </Button>

                    let disableSync = <TextField
                        id="standard-select-currency"
                        select
                        fullWidth
                        style={{marginRight: "10px"}}
                        value={user.syncTiming}
                        onChange={(e) => {
                            let value = e.target.value
                            inRoomConnections.forEach((connection) => {
                                userInRoomList.forEach((item, index) => {
                                    if (user.peerId === item.peerId) {
                                        userInRoomList[index].syncTiming = value
                                    }
                                })
                                let data = {
                                    action: "EDIT_SYNC_SETTINGS",
                                    delay: JSON.stringify(moment()),
                                    userInRoomList: userInRoomList,
                                    peerId
                                };
                                connection.send(data)
                                this.handleTransaction(data)
                            })
                        }}
                    >
                        {[{label: "Disable", value: "disabled"},
                            {label: "500ms", value: 500},
                            {label: "1000ms", value: 1000},
                            {label: "1500ms", value: 1500},
                            {label: "2000ms", value: 2000}].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>


                    let endAdornment
                    if (userIsMe) {
                        endAdornment = editButton
                    } else if (!userIsMe && isHost) {
                        endAdornment = <MobileButtonListV2
                            customStyle={{
                                rightButton: {
                                    width: "40px",
                                    backgroundColor: "var(--second-color)"
                                },
                                mainContainer: "margin:0;padding:15px;"
                            }}
                            buttonsArray={[giveHostButton]}
                        />

                    }

                    return !userIsMe && enableUserListSettings && isHost ?
                        <RowContainer key={uuid4()} customStyle={"width:100%;"} className={style.userItemContainer}>
                            {disableSync}
                            {endAdornment}
                        </RowContainer> : <form
                            style={{display:"flex",width:"100%"}}
                            key={uuid4()}
                            onSubmit={(e)=>{
                            try {
                                let nickName=e.target["userNameInput"].value
                                this.setState({nickName,
                                    enableEditNickName: false
                                })
                                localStorage.setItem("nickName", nickName)
                                userInRoomList.forEach((item, index) => {
                                    if (item.peerId === peerId) {
                                        userInRoomList[index].nickName = nickName
                                    }
                                })
                                inRoomConnections.forEach((connection) => {
                                    let data = {
                                        action: "NICKNAME_CHANGE",
                                        delay: JSON.stringify(moment()),
                                        userInRoomList: userInRoomList,
                                        peerId
                                    };
                                    connection.send(data)
                                    this.handleTransaction(data)
                                })
                            } catch (e) {
                                console.error("Errore nel submit della form cambia nickname",e,e.response)
                            }
                        }
                        }><InputBase
                            id={"userNameInput"}
                            name={"userNameInput"}
                            className={style.userItemContainer}
                            fullWidth
                            disabled={!enableEditNickName}
                            startAdornment={userIsMe ? <Person color={"secondary"}/> :
                                <PersonAdd color={"secondary"}/>}
                            endAdornment={endAdornment}
                            defaultValue={user.nickName}
                            variant={"outlined"}
                        />
                        </form>
                })}
            </ColumnContainer>}
        </>
    }

    renderUIroom = () => {
        let {
            peerId, inRoomConnections, isHost, sources, forcePlayFromHost,
            episodes, seasonNumber, show, id, steps, disableShowList, choosingEpisodeFromList
        } = this.state

        let videoJsOptions = {
            autoplay: false,
            controls: true,
            controlBar: {
                playToggle: true,
                captionsButton: true,
                chaptersButton: true,
                subtitlesButton: true,
                remainingTimeDisplay: true,
                progressControl: {
                    seekBar: {
                        playProgressBar: true,
                        loadProgressBar: true,
                        mouseTimeDisplay: true,
                    }
                },
                fullscreenToggle: true,
            },
            "data-setup": '{}',
            preload: "auto",
            muted: false,
            sources
        }


        return <>
            {isHost && choosingEpisodeFromList && <>
                {!disableShowList &&
                <MyVideoScreen
                    shareRoom={async (showId) => {
                        await this.refreshEpisodes(showId)
                    }}
                />
                }
                {steps && steps.length > 0 && <><Grid item
                                                      xs={12}
                                                      style={{padding: "10px", transition: "all 1s ease-in-out"}}
                >
                    <BackArrowComponent
                        customBack={() => this.setState({disableShowList: false, episodes: [], steps: []})}
                    />
                </Grid>
                    <Grid item
                          xs={12}
                          style={{padding: "10px", transition: "all 1s ease-in-out"}}
                    >

                        <Label font={"--text-font-h4"}>Seasons</Label>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            {steps.map((label, index) => {
                                let isSelected = seasonNumber === index
                                return (
                                    <Grid item key={label + uuid4()}
                                          style={{padding: "10px", transition: "all 1s ease-in-out"}} xs={4} sm={3}
                                          md={2} lg={1} xl={1}>
                                        <RowContainer customStyle={"width:100%;"} justifyContent={"flex-start"}>
                                            <div
                                                style={{
                                                    height: "2px",
                                                    width: "100%",
                                                    background: "var(--second-color)"
                                                }}
                                            />
                                            <Button
                                                color={"primary"}
                                                variant={"contained"}
                                                style={{
                                                    margin: "0 5px",
                                                    borderRadius: "20px",
                                                    height: "40px",
                                                    maxHeight: "40px",
                                                    minHeight: "40px",
                                                    width: "40px",
                                                    maxWidth: "40px",
                                                    minWidth: "40px",
                                                    padding: 0,
                                                    backgroundColor: isSelected ? "var(--second-color)" : "var(--first-color)",
                                                    color: isSelected ? "var(--first-color)" : "var(--second-color)",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        seasonNumber: index,
                                                        episodes: null
                                                    }, this.seasonDetail)
                                                }}>
                                                {label}
                                            </Button>
                                            <div
                                                style={{
                                                    height: "2px",
                                                    width: "100%",
                                                    background: "var(--second-color)"
                                                }}
                                            />
                                        </RowContainer>
                                    </Grid>
                                );
                            })}</Grid></Grid>
                </>}

                {episodes && episodes.length > 0 &&
                episodes.map((episode, index) => {

                    return <Grid item xs={12} sm={6} md={3} lg={2} xl={2}
                                 style={{
                                     transition: "all 1s ease-in-out",
                                     padding: "3px",

                                 }}
                                 key={uuid4()}>
                        <div
                            style={{
                                background: "linear-gradient(0deg, rgba(39,76,134,0.7) 0%,  rgba(31,38,49,0) 100%)",
                                height: "144px"

                            }}
                        >

                            <EpisodeCard
                                setLoading={(value) => {
                                    this.setState({loadingChild: value})
                                }} id={id}
                                handlePlayEpisode={(selectedLink, isMobile) => {

                                    try {


                                        inRoomConnections.forEach((connection) => {
                                            let data = {
                                                action: "LINK",
                                                src: selectedLink,
                                                peerId
                                            };
                                            connection.send(data)
                                            this.handleTransaction(data);
                                        })
                                    } catch (e) {
                                        console.log("ERROR sending link", e)
                                    }


                                }}
                                seasonNumber={seasonNumber}
                                episodes={episodes}
                                episode={episode}
                                show={show}/>
                        </div>
                    </Grid>
                })


                }


            </>}


            {((isHost && !choosingEpisodeFromList && videoJsOptions && videoJsOptions.sources && videoJsOptions.sources.length > 0) || (!isHost && videoJsOptions && videoJsOptions.sources && videoJsOptions.sources.length > 0)) &&
            <Grid item xs={12} style={{
                display: "flex",
                position: "absolute",
                bottom: 0,
                left: 0,

            }}><VideoPlayer
                forcePlayFromHost={forcePlayFromHost}
                setRef={(x) => this.childRef = x}
                videoJsOptions={videoJsOptions}
                disableCenterPlay={!isHost}
                disableTouchEvents={!isHost}
                disableUi={true}
                onPlay={(videoNode) => {
                    if (isHost) {
                        inRoomConnections.forEach((connection) => {
                            let data = {
                                action: "PLAY",
                                currentTime: videoNode.currentTime,
                                delay: JSON.stringify(moment()),
                                peerId
                            };
                            connection.send(data)
                        })

                        if (this.videoInterval) {
                            clearInterval(this.videoInterval)
                        }
                        this.videoInterval = setInterval(async () => {
                            let blockSync = false
                            if (videoNode && videoNode.paused) {
                                blockSync = true
                            }


                            if (videoNode && videoNode.currentTime) {
                                inRoomConnections.forEach((connection) => {
                                    let syncTiming = 500
                                    this.state.userInRoomList.forEach((item, index) => {
                                        if (item.peerId === connection.peer) {
                                            syncTiming = item.syncTiming
                                        }
                                    })
                                    let data = {
                                        action: "SYNC",
                                        currentTime: videoNode.currentTime,
                                        delay: JSON.stringify(moment()),
                                        peerId,
                                        syncTiming
                                    };
                                    connection.send(data)
                                })
                                if (blockSync) {
                                    clearInterval(this.videoInterval)

                                }

                            } else {

                                clearInterval(this.videoInterval)
                            }
                        }, 3000)
                    }
                }}
                onPause={(videoNode) => {
                    if (isHost) {
                        inRoomConnections.forEach((connection) => {
                            let data = {
                                action: "PAUSE",
                                currentTime: videoNode.currentTime,
                                delay: JSON.stringify(moment()),
                                peerId
                            };
                            connection.send(data)
                        })
                    }
                }}
            />

            </Grid>}
        </>
    }

    handleHeaderOnScroll = () => {
        let {lastScrollTop} = this.state;


        let header = document.getElementById("hidableHeader")
        let scrollingGrid = document.getElementById("griddonaShareRoom")
        let scrollTop = scrollingGrid.scrollTop;
        if (scrollTop > lastScrollTop) {
            header.style.top = "-73px"
        } else {
            header.style.top = "0"
        }
        this.state.lastScrollTop = scrollTop


    }

    render() {
        let {pageStatus, choosingEpisodeFromList, isHost, disableShowList} = this.state


        let content, renderShareRoomContent;
        switch (pageStatus) {

            case "SC":
                // SetupClient
                content = this.renderSetupClient()
                break;
            case "SH":
                // SetupHost
                content = this.renderSetupHost()

                break;
            case "H":
            // HostUI
            case "C":
                // ClientUI
                content = this.renderUIroom()
                renderShareRoomContent = this.renderSideBarContent()
                break;
            case "L":
            // Loading
            default:
                content = <LoadingSystem/>
                break;
        }

        let overflow = "auto",
            padding = "73px 10px 10px 10px"
        if (isHost && choosingEpisodeFromList && !disableShowList) {
            overflow = "hidden"
            padding = 0
        }
        return (<Grid container
                      id={"griddonaShareRoom"}
                      style={{
                          padding,
                          overflow,
                          background: "linear-gradient(0deg, rgba(39,76,134,0.4) 0%,  rgba(39,76,134,0.4) 100%)"
                      }} spacing={0}>
                <HeaderTop
                    id={"hidableHeader"}
                    renderShareRoomContent={renderShareRoomContent}
                />
                {content}


            </Grid>
        )
    }

}


ShareScreen.contextType = GlobalContext;
export default withRouter(ShareScreen);
