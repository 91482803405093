import React, {Component} from 'react';
import {ColumnContainer} from "../components/ComponentManager/ComponentManager";
import {Button, ButtonGroup, ClickAwayListener, Paper, Popover} from "@material-ui/core";
import {ArrowDropDown} from "@material-ui/icons";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";

class MobileButtonList extends Component {

    state = {
        open: false,
    }

    handleToggle = () => {
        this.setState({open: !this.state.open});
    };

    handleClose = event => {
        this.setState({open: false});
    };

    render() {
        let {buttonsArray, customStyle, disabled,buttonText} = this.props;
        let {open} = this.state;
        return (
            <ColumnContainer
                customStyle={"margin:0 10px 0 10px;" + (customStyle && customStyle.mainContainer ? customStyle.mainContainer : "")}>
                <PopupState variant="popover">
                    {popupState => (<>
                        <ButtonGroup
                            {...bindTrigger(popupState)}
                            style={customStyle && customStyle.buttonGroup ? customStyle.buttonGroup : undefined}
                            variant="contained"
                            disabled={disabled}
                            color="primary"
                            aria-label="split button">
                            <Button
                                style={customStyle && customStyle.rightButton ? customStyle.rightButton : undefined}
                                color="primary"
                                size="small"
                                disabled={disabled}
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                endIcon={<ArrowDropDown/>}
                                onClick={this.handleToggle}>
                                {buttonText}

                            </Button>
                        </ButtonGroup>
                        <Popover
                            {...bindPopover(popupState)}
                            open={open}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <div id="split-button-menu" style={{
                                        padding: " 10px",
                                        alignItems: "flex-start"
                                    }}>
                                        {buttonsArray.map((button, index) => {
                                            return <React.Fragment
                                                key={"button" + index}
                                                // style={{width: "100%", padding: 0}}
                                            >
                                                {button}
                                            </React.Fragment>
                                        })}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Popover></>)}</PopupState>
            </ColumnContainer>
        );
    }
}

export default MobileButtonList;
