import React from "react";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import StarGrey from "./../../assets/images/StarGrey.png"
import StarYellow from "./../../assets/images/StarYellow.png"
import {LANGUAGE, TMDB_KEY} from "../../constants/Config";
import BaseAxios from "../../constants/ApiHelpers/BaseAxios";
import placeholder from "../../assets/images/Logo.png";
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from "@material-ui/core/Button";
import {deleteAllRawEpisode, deleteSeriesAndLink, getRawEpisode, saveUrlEpisode,} from "../../constants/StorageHelper";
import {shareableLinkData} from "../../constants/ShareLibraryHelper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EpisodeCard from "../../components/EpisodeCard";
import {v4 as uuid4} from "uuid"
import {Avatar, Collapse, IconButton, LinearProgress} from "@material-ui/core";
import {DeleteForever, FileCopy, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {RowContainer} from "./SearchScreen";
import FollowButton from "../../components/FollowButton";
import ShowList from "../../components/ShowList";
import BaseComponent from "../../interfaces/BaseComponent";
import VideoPlayerPage from "../../components/VideoPlayerPage";
import queryString from "query-string";
import BackArrowComponentSmart from "../../components/BackArrowComponentSmart";
import HeaderTop from "../../components/HeaderTop";
import ToWatchButton from "../../components/ToWatchButton";
import RemoteController from "../../components/RemoteController";
import {copyToClipboard} from "../../constants/CommonUtils";
import UploadButton from "../../components/UploadButton";

import axios from "axios";
import {Label} from "../../components/ComponentManager/ComponentManager";

const DEFAULT_PADDING = 2;

class ShowDetailScreen extends BaseComponent {
    state = {
        loading: true,
        loadingChild: false,
        show: [],
        episodesLink: [],
        id: null,
        steps: [],
        seasonNumber: parseInt(localStorage.getItem(this.props.match.params.id)) || 0,
        massiveInsertDialog: false,
        tabMassiveValue: 0,
        inputPadding: DEFAULT_PADDING,
        insertDialog: false,
        enableDescription: false,
        enableVideoPlayerPage: false,
        VideoPlayerPageProps: {},
        enableEpisodesButton: false,
        imageSrcAvatarWidth: "https://image.tmdb.org/t/p/original",

    }
    scrollerDiv;

    async componentDidMount() {
        let seasonNumberFromLocalStorage = parseInt(localStorage.getItem(this.props.match.params.id))
        let seasonNumberFromUrl = parseInt(this.props.match.params.seasonNumber)
        if (seasonNumberFromLocalStorage && seasonNumberFromLocalStorage !== seasonNumberFromUrl) {
            this.props.history.push("/dp/" + this.props.match.params.id + "/" + seasonNumberFromLocalStorage)
        }
        await this.refreshEpisodes()
    };


    async componentDidUpdate(prevProps) {
        if (this.props.match.params && this.props.match.params.id && prevProps.match.params && prevProps.match.params.id && this.props.match.params.id !== prevProps.match.params.id) {
            await this.refreshEpisodes()
        }
    }


    getDetailTmdb = () => {
        let {id} = this.props.match.params
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    getEpisodes = (seasonNumber) => {
        let {id} = this.props.match.params
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "/season/" + (seasonNumber + 1) + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    getSimilarTmdb = () => {
        let {id} = this.props.match.params
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "/similar?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    getProviderTmdb = () => {
        let {id} = this.props.match.params
        return BaseAxios.get("https://api.themoviedb.org/3/tv/" + id + "/watch/providers?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    };

    seasonDetail = async (state) => {
        try {
            let {seasonNumber} = this.state;
            let response = await this.getEpisodes(seasonNumber);
            let episodes = response.data.episodes;


            this.setState({seasonDetail: response.data, episodes, ...state})
        } catch (e) {
            console.error("Error while recovering seasonDetail", e.response)
        } finally {
            this.setLoading(false, "ShowDetailScreen - refreshEpisodes")

        }

    };

    refreshEpisodes = async () => {
        try {
            let {id} = this.props.match.params
            this.setLoading(true, "ShowDetailScreen - refreshEpisodes")
            let response = await this.getDetailTmdb();
            let responseSimiliar = await this.getSimilarTmdb();
            let responseProvider = await this.getProviderTmdb();
            let rawEpisodes = await getRawEpisode(id);

            let steps = [];
            for (let i = 0; i < response.data.number_of_seasons; i++) {
                steps.push(i + 1);
            }


            let provider = responseProvider.data.results
            await this.seasonDetail({
                steps,
                show: response.data,
                loading: false,
                rawEpisodes,
                responseSimiliar: responseSimiliar.data.results,
                provider: provider[LANGUAGE.toUpperCase()] || provider[Object.keys(provider)[0]]
            })

        } catch (e) {
            console.error("Error while refreshing Episodes", e.response)
        }
    };

    closeMassiveInsertDialog = () => {
        this.setState({massiveInsertDialog: false, inputPadding: DEFAULT_PADDING,}, async () => {
            await this.refreshEpisodes()
        })
    }
    openMassiveInsertDialog = () => {
        let {setDialogState, resetDialogState} = this.context
        let {
            inputPadding,
            seasonDetail,
            episodes,
            seasonNumber,
            show,

        } = this.state;

        let rightButtonText = "Upload", handleSubmit = async () => {
            let multilineLink = document.getElementById("linkAreaText").value
            if (!multilineLink || multilineLink.length === 0) {
                alert("No link inserted")
            }
            let lines = multilineLink.split("\n").map(x => x.trim());
            for (let index in seasonDetail.episodes) {
                let item = seasonDetail.episodes[index];
                //Se non ho elementi fermo il ciclo
                if (lines.length < index) {
                    break;
                }
                let link = lines[index];
                let {id} = this.props.match.params;
                if (validURL(link)) {
                    let input = {
                        tvShowId: id,
                        episodeNumber: item.episode_number,
                        episodeImage: item.still_path,
                        seasonNumber,
                        showName: show.name,
                        showDetail: JSON.stringify(show),
                        episodeName: item.name,
                        link
                    };
                    await saveUrlEpisode(input);
                }
            }
            alert("Links associated with success");
            resetDialogState();
            this.setState({inputPadding: DEFAULT_PADDING,}, async () => {
                await this.refreshEpisodes()
            })
        }

        if (this.state.tabMassiveValue === 1) {
            rightButtonText = "Fetch"
            handleSubmit = async () => {
                let inputLinkPattern = document.getElementById("linkPattern").value
                if (!inputLinkPattern || !inputLinkPattern.includes("[@EP]")) {
                    alert("Missing tag [@EP]")
                }
                if (seasonDetail) {
                    for (let i of seasonDetail.episodes) {
                        let temp = inputLinkPattern.includes("[@EP]") ? inputLinkPattern.replace("[@EP]", i.episode_number.toString().padStart(inputPadding, '0')) : inputLinkPattern;
                        let {id} = this.props.match.params;
                        if (validURL(temp)) {
                            let input = {
                                tvShowId: id,
                                episodeNumber: i.episode_number,
                                episodeImage: i.still_path,
                                seasonNumber,
                                showName: show.name,
                                showDetail: JSON.stringify(show),
                                episodeName: i.name,
                                link: temp
                            };
                            await saveUrlEpisode(input);
                        }
                    }
                    alert("Links associated with success");
                    resetDialogState();
                    this.setState({inputPadding: DEFAULT_PADDING,}, async () => {
                        await this.refreshEpisodes()
                    })
                }

            }
        }
        if (this.state.tabMassiveValue === 2) {
            rightButtonText = "Fetch"
            handleSubmit = async () => {
                //TODO parsare il json e inserire gli episodi
                // alert("Links associated with success");
                alert("Function not working yet");
                resetDialogState();
                this.setState({}, async () => {
                    await this.refreshEpisodes()
                })
            }

        }


        setDialogState({
            dialogIsOpen: true,
            title: <CategoryHeader>Upload link list</CategoryHeader>,
            handleClose: () => resetDialogState(),
            content: <>
                <Tabs
                    value={this.state.tabMassiveValue}
                    indicatorColor="primary"
                    textColor="secondary"
                    onChange={(event, value) => {
                        this.setState({tabMassiveValue: value}, this.openMassiveInsertDialog)
                    }}
                    style={{alignSelf: "center", marginBottom: "5px"}}
                >
                    <Tab label="Link list"/>
                    <Tab label="Pattern"/>
                    <Tab label="JSON"/>
                </Tabs>
                {this.state.tabMassiveValue === 0 && <>
                    <DialogContentText color={"secondary"}>
                        Insert the links of the episodes, the links must be in order and one per line. All
                        links
                        greater than the number of episodes will be ignored<br/>
                        <br/>- Link ep1<br/>
                        <br/>- Link ep2<br/><br/>
                    </DialogContentText>
                    <TextField
                        id="linkAreaText"
                        multiline
                        rows={(episodes && episodes.length) || 4}
                        placeholder={"Insert the link here"}
                        variant="outlined"
                        style={{width: "100%"}}
                    />
                </>}
                {this.state.tabMassiveValue === 1 && <>
                    <DialogContentText color={"secondary"}>
                        Upload the link following a pattern:
                        <br/><br/>Insert the pattern to follow, to generate the link (Insert into the string the
                        tag
                        [@EP]
                        that will be replaced by the number of the episode):<br/><br/>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="linkPattern"
                        fullWidth
                        required
                        color={"secondary"}
                    />
                    <DialogContentText color={"secondary"}>
                        <br/>Insert the padding for the number to place inside the link(Es. 2-02,
                        3-002):<br/><br/>
                    </DialogContentText>
                    <TextField
                        id="padding"
                        InputProps={{inputProps: {min: 0, max: 10}}}
                        type={"number"}
                        fullWidth
                        value={inputPadding}
                        onChange={(e) => {
                            this.setState({inputPadding: e.target.value})
                        }}
                        color={"secondary"}
                    />
                </>}
                {this.state.tabMassiveValue === 2 && <>
                    <DialogContentText color={"secondary"}>
                        Upload the JSON file:
                    </DialogContentText>
                    <UploadButton
                        uploadButtonOnClick={(event) => {

                            let fr = new FileReader();
                            let output=""
                            fr.onload = () => {
                                let obj = JSON.parse(fr.result);
                                output=JSON.stringify(obj, undefined, 4);
                                document.getElementById("linkAreaTextJSON").value = output

                            }

                            fr.readAsText(event.target.files[0]);



                        }}
                        removeButtonOnClick={(event) => {
                            console.error("delete aa", event.target.value)
                        }}
                    />
                    {/*<DialogContentText color={"secondary"}>*/}
                    {/*    or a link to it://TODO capire come cazzo worka stammerda*/}
                    {/*</DialogContentText>*/}
                    {/*<TextField*/}
                    {/*    autoFocus*/}
                    {/*    id="linkPatternJSON"*/}
                    {/*    onChange={async () => {*/}
                    {/*        let link = document.getElementById("linkPatternJSON").value*/}

                    {/*        console.error("ma sto link",link)*/}

                    {/*        try {*/}
                    {/*            // fetch("https://cors.io/?"+link).then(a=>a.text().then((f)=>{*/}
                    {/*            //     console.error("AAAA", f)*/}
                    {/*            // }))*/}
                    {/*            let res = await axios.request({*/}
                    {/*                url: link,*/}
                    {/*                method: "GET",*/}
                    {/*                headers: {*/}
                    {/*                    Accept: 'application/json',*/}
                    {/*                    'Content-Type': 'application/json',*/}
                    {/*                },*/}
                    {/*            })*/}
                    {/*            console.error("AAAA", res)*/}
                    {/*        } catch (e) {*/}
                    {/*            console.error("errore tutte cose", e, e.response)*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    fullWidth*/}
                    {/*    required*/}
                    {/*    color={"secondary"}*/}
                    {/*/>*/}
                    <DialogContentText color={"secondary"}>
                        <br/>or copy paste the JSON body:<br/><br/>
                    </DialogContentText>
                    <TextField
                        id="linkAreaTextJSON"
                        name="linkAreaTextJSON"
                        multiline
                        rows={(episodes && episodes.length) || 4}
                        placeholder={"Insert the JSON body here"}
                        variant="outlined"
                        style={{width: "100%"}}
                    />
                </>}
            </>,
            rightButtonText,
            leftButtonText: "Close",
            handleSubmit
        })

    }


    handleHeaderOnScroll = () => {
        let {lastScrollTop} = this.state;
        let header = document.getElementById("hidableHeader")
        let scrollTop = this.scrollerDiv.scrollTop;
        if (scrollTop > lastScrollTop) {
            header.style.top = "-73px"
        } else {
            header.style.top = "0"
        }
        this.state.lastScrollTop = scrollTop

    }

    render() {


        let {id} = this.props.match.params;
        let {
            loading,
            remoteProps,
            shareableLink,
            inputPadding,
            seasonDetail,
            episodes,
            seasonNumber,
            show,
            responseSimiliar,
            steps,
            massiveInsertDialog,
            loadingChild,
            provider,
            enableDescription,
            enableVideoPlayerPage,
            enableEpisodesButton,
            rawEpisodes,
            enableRemoteController,
            imageSrcAvatarWidth
        } = this.state;
        console.log("PROVIDER: ", provider)
        if (loading || this.loading || loadingChild) {
            return <LinearProgress color={"primary"}/>;
        }
        let {history, path} = this.props

        let queryParams = queryString.parse(this.props.location.search);
        let epIndex = queryParams.ep
        let isRaw = queryParams.isRaw
        if (epIndex) {
            enableVideoPlayerPage = true
        }
        let isMobile = false
        if (window.innerWidth < 600) {
            isMobile = true
        }

        let itemStyle = {padding: "10px"}

        return <div style={{
            overflow: "hidden",
            flex: 1,
            maxHeight: "100%", display: "flex", width: "-webkit-fill-available",
        }}>{enableVideoPlayerPage ?
            <VideoPlayerPage
                setLoading={(value) => {
                    this.setState({loadingChild: value})
                }} id={id}
                isMobile={isMobile}
                seasonNumber={seasonNumber}
                episodes={isRaw ? rawEpisodes : episodes}
                episode={isRaw ? rawEpisodes[epIndex] : episodes[epIndex]}
                show={show}
                isRaw={isRaw}
                closeVideoPlayerPage={() => {
                    history.push("/dp/" + id + "/" + seasonNumber)
                    this.setState({enableVideoPlayerPage: false})
                }}
            /> : enableRemoteController ? <RemoteController
                    id={id}
                    seasonNumber={seasonNumber}
                    episodes={episodes}
                    episode={episodes[remoteProps.index]}
                    show={show}
                    closeVideoPlayerPage={() => {
                        this.setState({enableRemoteController: false})
                    }}
                /> :
                <Grid container spacing={0} style={{overflow: "auto", padding: "100px 10px 10px 10px"}}
                      ref={(ref) => {
                          this.scrollerDiv = ref
                          if (this.scrollerDiv && this.scrollerDiv.getAttribute("listener") !== "true") {
                              this.scrollerDiv.addEventListener("scroll", this.handleHeaderOnScroll)
                              this.scrollerDiv.setAttribute('listener', "true");
                          }

                      }}>
                    <Grid item xs={12}><HeaderTop id={"hidableHeader"}/></Grid>
                    <Grid item xs={12} style={itemStyle}><BackArrowComponentSmart
                        path={path}
                    /></Grid>
                    {isMobile && <Grid item xs={12} style={itemStyle}><TitleText>{show.name}</TitleText></Grid>}
                    <Grid item xs={12} sm={12} md={8} lg={8} style={itemStyle}> <Image
                        alt={"image-detail"} onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = placeholder
                    }}
                        src={imageSrcAvatarWidth + (show.backdrop_path ? show.backdrop_path : show.poster_path)}/></Grid>

                    {isMobile &&
                    <Grid item xs={12} style={{
                        display: "flex",
                        width: "100%",
                        padding: "10px",
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}>
                        <Button color={"primary"} variant={"contained"}
                                onClick={() => {
                                    this.setState({enableDescription: !this.state.enableDescription})
                                }}>
                            {enableDescription ? "Show less" : "Show more"}
                        </Button></Grid>}
                    <Grid item xs={12} sm={12} md={4} lg={4} style={itemStyle}>
                        <Collapse in={!isMobile || enableDescription} timeout={1000}>
                            <Grid container spacing={1}>
                                <Grid item container xs={12} spacing={2}>
                                    {!isMobile && <Grid item xs={"auto"}>
                                        <TitleText>{show.name}</TitleText>
                                    </Grid>}
                                    {show.vote_average != 0 &&
                                    <Grid item xs={"auto"}>
                                        <StarIcon alt={"star"}
                                                  src={show.vote_average > 0 ? StarYellow : StarGrey}/>
                                        <StarIcon alt={"star"}
                                                  src={show.vote_average > 1 ? StarYellow : StarGrey}/>
                                        <StarIcon alt={"star"}
                                                  src={show.vote_average > 2 ? StarYellow : StarGrey}/>
                                        <StarIcon alt={"star"}
                                                  src={show.vote_average > 3 ? StarYellow : StarGrey}/>
                                        <StarIcon alt={"star"}
                                                  src={show.vote_average > 4 ? StarYellow : StarGrey}/>
                                    </Grid>}
                                </Grid>
                                {show.overview && <Grid item container xs={12} spacing={1}>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>Overview:</CategoryContentSubSub>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>{show.overview}</CategoryContentSubSub>
                                    </Grid>
                                </Grid>}
                                {show.type && <Grid item container xs={"auto"} spacing={1}>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>Type:</CategoryContentSubSub>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub bold>{show.type}</CategoryContentSubSub>
                                    </Grid>
                                </Grid>}
                                {provider && provider.flatrate && provider.flatrate.length > 0 &&
                                <Grid item alignItems={"center"} container xs={"auto"} spacing={1}>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>Watch now:</CategoryContentSubSub>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        {provider.flatrate && provider.flatrate.map(x => <IconButton
                                            key={"adsfaf" + uuid4()}
                                            onClick={() => {
                                                window.open("https://www.google.com/search?q=" + x.provider_name + "+" + show.name);
                                            }}
                                        >
                                            <Avatar
                                                variant={"square"}
                                                key={x.provider_id}

                                                src={x.logo_path ? "https://image.tmdb.org/t/p/w500" + (x.logo_path) : null}/>
                                        </IconButton>)}
                                    </Grid>
                                </Grid>}
                                {show.number_of_episodes && <Grid item container xs={"auto"} spacing={1}>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>Episodes:</CategoryContentSubSub>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub
                                            bold>{show.number_of_episodes}</CategoryContentSubSub>
                                    </Grid>
                                </Grid>}
                                {show.first_air_date && <Grid item container xs={"auto"} spacing={1}>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>First air date:</CategoryContentSubSub>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub
                                            bold>{moment(show.first_air_date).format("DD/MM/YYYY")}</CategoryContentSubSub>
                                    </Grid>
                                </Grid>}
                                {show.last_air_date && <Grid item container xs={"auto"} spacing={1}>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub>Last air date:</CategoryContentSubSub>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <CategoryContentSubSub
                                            bold>{moment(show.last_air_date).format("DD/MM/YYYY")}</CategoryContentSubSub>
                                    </Grid>
                                </Grid>}
                                <Grid item container>
                                    <CategoryContentSubSub bold>{show.summary}</CategoryContentSubSub>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                    <CategoryContainer>
                        <CategoryHeader>
                            <div style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: isMobile ? "space-between" : "flex-start",
                                display: "flex"
                            }}>
                                Seasons
                                <div style={{marginLeft: isMobile ? "0" : "20px"}}>
                                    <FollowButton
                                        enableLabel={!isMobile}
                                        show={show} key={"followButton" + show.id}/>
                                </div>
                                <div style={{marginLeft: isMobile ? "0" : "20px"}}>
                                    <ToWatchButton
                                        enableLabel={!isMobile}
                                        show={show} key={"ToWatchButton" + show.id}/>
                                </div>
                            </div>
                        </CategoryHeader>
                        <Grid container spacing={0} style={{padding: "10px 0"}}>
                            {steps.map((label, index) => {
                                let isSelected = seasonNumber === index
                                return (
                                    <Grid item key={label + uuid4()}
                                          style={{padding: "10px", transition: "all 1s ease-in-out"}} xs={4} sm={3}
                                          md={2} lg={1} xl={1}>
                                        <RowContainer customStyle={"width:100%;"} justifyContent={"flex-start"}>
                                            <div
                                                style={{
                                                    height: "2px",
                                                    width: "100%",
                                                    background: "var(--second-color)"
                                                }}
                                            />
                                            <Button
                                                color={"primary"}
                                                variant={"contained"}
                                                style={{
                                                    margin: "0 5px",
                                                    borderRadius: "20px",
                                                    height: "40px",
                                                    maxHeight: "40px",
                                                    minHeight: "40px",
                                                    width: "40px",
                                                    maxWidth: "40px",
                                                    minWidth: "40px",
                                                    padding: 0,
                                                    backgroundColor: isSelected ? "var(--second-color)" : "var(--first-color)",
                                                    color: isSelected ? "var(--first-color)" : "var(--second-color)",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        seasonNumber: index,
                                                        episodes: null
                                                    }, this.seasonDetail)
                                                    history.push("/dp/" + show.id + "/" + index)
                                                    localStorage.setItem(show.id, JSON.stringify(index))
                                                }}>
                                                {label}
                                            </Button>
                                            <div
                                                style={{
                                                    height: "2px",
                                                    width: "100%",
                                                    background: "var(--second-color)"
                                                }}
                                            />
                                        </RowContainer>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid container spacing={0} style={{padding: "10px 0"}}>
                            <Grid item xs={12} style={{padding: "10px"}}>
                                <RowContainer customStyle={"width:100%"}>
                                    <CategoryHeader>
                                        Episodes
                                    </CategoryHeader>
                                    {isMobile && <Button color={"primary"}
                                                         variant={"contained"}
                                                         style={{marginTop: "20px"}}
                                                         onClick={() => this.setState({enableEpisodesButton: !this.state.enableEpisodesButton})}>
                                        {enableEpisodesButton ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                    </Button>}
                                </RowContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={!isMobile || enableEpisodesButton} timeout={1000}>
                                    <Grid container spacing={0} style={{padding: "10px 0"}}>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={3} style={{padding: "10px"}}>
                                            <Button fullWidth variant={"contained"} color={"primary"}
                                                    onClick={() => this.openMassiveInsertDialog()}>
                                                Associate link episode
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={3} style={{padding: "10px"}}>
                                            <Button fullWidth variant={"contained"} color={"primary"}
                                                    onClick={async () => {
                                                        this.setLoading(true, "ShowDetailScreen - shareableLinkData")
                                                        let shareableLink = await shareableLinkData(id, seasonNumber, seasonDetail.episodes.map(x => x.episode_number));
                                                        this.setState({shareableLink}
                                                            , this.setLoading(false, "ShowDetailScreen - shareableLinkData")
                                                        )
                                                    }}>
                                                Share season link
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={3} style={{padding: "10px"}}>
                                            <Button fullWidth variant={"contained"} color={"secondary"}
                                                    endIcon={<DeleteForever/>}
                                                    onClick={async () => {
                                                        let {setDialogState, resetDialogState} = this.context
                                                        setDialogState({
                                                            dialogIsOpen: true,
                                                            paperSizeIsSmallest: true,
                                                            title: "Delete all links",
                                                            content: <Label font={"--text-normal-20"}>Are you sure
                                                                you
                                                                want to delete all the links for this
                                                                season?</Label>,
                                                            rightButtonText: "DELETE",
                                                            leftButtonText: "Close",
                                                            handleClose: () => resetDialogState(),
                                                            handleSubmit: async (e) => {
                                                                e.preventDefault();
                                                                let {id} = this.props.match.params;
                                                                await deleteSeriesAndLink(id, seasonNumber)
                                                                resetDialogState()
                                                                await this.refreshEpisodes()
                                                            },

                                                        })
                                                    }}>
                                                Delete all links
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                        {shareableLink && <Grid container spacing={0} style={{
                            padding: "10px",
                        }}>
                            <Grid item xs={12}>
                                <RowContainer style={{justifyContent: "flex-start"}}>
                                    <h3 style={{fontSize: 14, marginRight: "10px"}}>{shareableLink}</h3>
                                    <Button color={"secondary"} variant={"contained"} startIcon={<FileCopy/>}
                                            onClick={() => {
                                                copyToClipboard(shareableLink)
                                            }}
                                    >Copy!</Button>
                                </RowContainer>
                            </Grid>
                        </Grid>}
                        {episodes && episodes.length > 0 &&
                        <Grid container spacing={0} style={{
                            padding: "10px 0",
                        }}>{episodes.map((episode, index) => {
                            return <Grid item xs={12} sm={6} md={3} lg={2} xl={2}
                                         style={{
                                             transition: "all 1s ease-in-out",
                                             padding: "3px",
                                         }}
                                         key={uuid4()}>
                                <div
                                    style={{
                                        background: "linear-gradient(0deg, rgba(39,76,134,0.7) 0%,  rgba(31,38,49,0) 100%)",
                                        height: "144px"
                                    }}
                                >
                                    <EpisodeCard
                                        setLoading={(value) => {
                                            this.setState({loadingChild: value})
                                        }} id={id}
                                        handlePlayEpisode={(selectedLink, isMobile) => {


                                            let peerVar = this.context.getGlobalVariable("peerVar")
                                            let connection = peerVar["connection"]
                                            if (connection) {
                                                let peerId = peerVar["clientId"]
                                                let data = {
                                                    action: "LINK",
                                                    src: selectedLink,
                                                    peerId
                                                };
                                                connection.send(data)
                                                this.setState({
                                                    remoteProps: {
                                                        index
                                                    },
                                                    enableRemoteController: true,
                                                })
                                            } else {
                                                history.push("/dp/" + id + "/" + seasonNumber + "?ep=" + index + "&link=" + selectedLink)
                                                this.setState({
                                                    enableVideoPlayerPage: true,
                                                })
                                            }
                                        }}
                                        seasonNumber={seasonNumber}
                                        episodes={episodes}
                                        episode={episode}
                                        show={show}/>
                                </div>
                            </Grid>
                        })

                        }</Grid>
                        }
                        {episodes && episodes.length == 0 && <TitleText>No episode for now</TitleText>}
                    </CategoryContainer>
                    {rawEpisodes && rawEpisodes.length > 0 && <CategoryContainer>
                        <CategoryHeader>Media server episode:</CategoryHeader>
                        <Button variant={"contained"} color={"secondary"}
                                endIcon={<DeleteForever/>}
                                onClick={async () => {
                                    let {setDialogState, resetDialogState} = this.context
                                    setDialogState({
                                        dialogIsOpen: true,
                                        paperSizeIsSmallest: true,
                                        title: "Delete all media server link",
                                        content: <Label font={"--text-normal-20"}>Are you sure you
                                            want to delete all the links for tv show?</Label>,
                                        rightButtonText: "DELETE",
                                        leftButtonText: "Close",
                                        handleClose: () => resetDialogState(),
                                        handleSubmit: async (e) => {
                                            e.preventDefault();
                                            let {id} = this.props.match.params;
                                            await deleteAllRawEpisode(id)
                                            resetDialogState()
                                            await this.refreshEpisodes()
                                        },


                                    })
                                }}>
                            Delete all links
                        </Button>
                        <Grid container spacing={0} style={{
                            padding: "10px 0",
                        }}>{rawEpisodes.map((episode, index) => {
                            return <Grid item xs={12} sm={6} md={3} lg={2} xl={2}
                                         style={{
                                             transition: "all 1s ease-in-out",
                                             padding: "3px",

                                         }}
                                         key={uuid4()}>
                                <div
                                    style={{
                                        background: "linear-gradient(0deg, rgba(39,76,134,0.7) 0%,  rgba(31,38,49,0) 100%)",
                                        height: "144px"

                                    }}
                                >

                                    <EpisodeCard
                                        setLoading={(value) => {
                                            this.setState({loadingChild: value})
                                        }} id={id}
                                        handlePlayEpisode={(selectedLink, isMobile) => {
                                            let peerVar = this.context.getGlobalVariable("peerVar")
                                            let connection = peerVar["connection"]
                                            let peerId = peerVar["clientId"]

                                            if (connection) {
                                                let data = {
                                                    action: "LINK",
                                                    src: selectedLink,
                                                    peerId
                                                };
                                                connection.send(data)
                                            } else {
                                                history.push("/dp/" + id + "/" + seasonNumber + "?ep=" + index + "&link=" + selectedLink + "&isRaw=true")
                                                this.setState({
                                                    enableVideoPlayerPage: true,
                                                })
                                            }
                                        }}
                                        episodes={rawEpisodes}
                                        episode={episode}
                                        show={show}
                                        rawEpisode={true}
                                    />
                                </div>
                            </Grid>
                        })}
                        </Grid>
                    </CategoryContainer>
                    }
                    <CategoryContainer>
                        <CategoryHeader>Related series</CategoryHeader>
                        <ShowList
                            path={""}
                            disableHeader={true}
                            hideLoadMore={true}
                            shows={responseSimiliar}
                            disableScroll={true}
                        />
                    </CategoryContainer>
                </Grid>}
        </div>

    }

}


export default withRouter(ShowDetailScreen);


const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 97%;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #535353;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  font: var(--text-normal-32);
  line-height: 37px;
  color: #AAAAAA;
  width: 100%;
  flex-direction: row;
  margin-top: 20px;
`;

const ContentContainer = styled.div`
  flex: 1;
  max-height: 100%;
  overflow: auto;
  padding-top: 100px;
`;


const CategoryContentSubSub = styled.h5`
  font: var(--text-normal-16);
  line-height: 19px;
  color: ${props => props.bold ? "#CCCCCC" : "#999999"};
  margin-left: ${props => props.bold ? "5px" : "0px"};
`;


const Image = styled.img`
  display: flex;
  flex: 1;
  margin-bottom: 15px;
  padding: 5px;
  object-fit: contain;
`;


const TitleText = styled.h2`
  font: var(--text-normal-32);
  line-height: 37px;
  color: #AAAAAA;
`;

const StarIcon = styled.img`
  min-height: 25px;
  min-width: 25px;
`;

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'// port and path
        , 'i'); // fragment locator
    return !!pattern.test(str);
}