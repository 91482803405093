import React, {Component} from 'react';

class EpisodeListUi extends Component {
    render() {
        let {enableEpisodeList, isMobile, episodeList} = this.props


        return (enableEpisodeList && <div
                onWheel={(e) => {
                    e.preventDefault()
                    let container = document.getElementById('testCont')
                    let containerScrollPosition = document.getElementById('testCont').scrollLeft
                    container.scrollTo({
                        top: 0,
                        left: containerScrollPosition + e.deltaY,
                        behaviour: 'smooth' //if you want smooth scrolling
                    })
                }}
                id={"testCont"}
                style={{
                    minWidth: "15%",
                    maxWidth: "100vw",
                    overflow: "auto",
                    gap: 20,
                    maxHeight: "100%",
                    minHeight: "120px",
                    zIndex: 1000,
                    display: "flex",
                    padding: isMobile ? "10px" : "20px 0",
                    flexDirection: "row",
                    background: "linear-gradient(90deg, rgba(31,38,49,0.9) 0%, rgba(31,38,49,0.9) 100%)"
                }}>
                {episodeList}
            </div>
        );
    }
}

export default EpisodeListUi;