import React, {Component} from 'react';
import {IconButton} from "@material-ui/core";
import {v4 as uuid4} from "uuid"
import placeholder from "../assets/images/Logo.png";
import {Link, withRouter} from "react-router-dom";
import styled from "styled-components";
import FollowButton from "./FollowButton";
import ToWatchButton from "./ToWatchButton";
import {PlayCircleFilled} from "@material-ui/icons";
import style from "../assets/Card.module.css"
import {Skeleton} from "@material-ui/lab";
import BaseAxios from "../constants/ApiHelpers/BaseAxios";
import {LANGUAGE, TMDB_KEY} from "../constants/Config";
import {updateShowName} from "../constants/StorageHelper";
import {Label} from "./ComponentManager/ComponentManager";

class ShowCard extends Component {

    state = {
        w: window.innerWidth,
        key: uuid4(),
        loading: true
    }


    async componentDidMount() {
        window.addEventListener('resize', this.handleResize)

        let {show} = this.props;
        if (show.idShow) {
            let res = await BaseAxios.get("https://api.themoviedb.org/3/tv/" + show.idShow + "?api_key=" + TMDB_KEY + "&language=" + LANGUAGE)
            await updateShowName(res.data.id,res.data.name)
            show = res.data
        }
        this.setState({show, loading: false})


    }


    handleResize = (e) => {
        let {w} = this.state;

        let newW = window.innerWidth

        if (
            (
                769 < w && w < 960
                &&
                (newW > 960 || newW < 769)
            )
            ||
            (
                961 <= w && w < 1280
                &&
                (newW > 1280 || newW < 961)
            )
            ||
            (
                1280 <= w && w < 1920
                &&
                (newW > 1920 || newW < 1280)
            )
            ||
            (
                1920 <= w
                &&
                (newW < 1920)
            )
        ) {
            this.setState({w: newW})
        }
    }


    renderCommonDesktop = () => {
        let {show,} = this.state;
        let description = show.name;

        //2. Render the item

        let imageStyle = style.image


        return <div style={{padding: "10px 10px 0px 10px", width: "100%"}}>


            <Label ellipsis={2} font={"--text-font-h6"} color={"--white-text"}
                   cursor={"pointer"}
                   customStyle={{container: "margin-bottom:10px;height:38px;"}}>{description}</Label>

            {(!show.fake) ? <img
                    className={imageStyle}
                    alt={"item" + uuid4()}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = placeholder
                        e.target.className = style.imageScaleDown
                    }}
                    src={"https://image.tmdb.org/t/p/w500" + (show.backdrop_path ? show.backdrop_path : show.poster_path)}/>
                :
                <Skeleton variant="rect" className={imageStyle}/>
            }
        </div>
    }

    renderCommonMobile = () => {

        let {refreshData, isMobile, path, shareRoom} = this.props;
        let {show} = this.state;
        let description = show.name;

        //2. Render the item

        let imageStyle = style.image


        let LinkWrapper = (props) => this.renderLinkWrapper(props)

        if (isMobile) {
            imageStyle = style.imageMobile
        }
        return <div style={{padding: "10px 10px 0px 10px"}}>
            {isMobile && (<RowContainerSpaceBetween>
                <LinkWrapper
                    shareRoom={shareRoom}
                    show={show} path={path}>
                    <Label ellipsis={2} font={"--text-font-h6"} color={"--white-text"}
                           cursor={"pointer"}
                           customStyle={{container: "margin-bottom:10px;height:38px;"}}>{description}</Label>
                </LinkWrapper>

                <FollowButton refreshData={refreshData} show={show}/>

            </RowContainerSpaceBetween>)
            }

            <LinkWrapper
                shareRoom={shareRoom}
                show={show}
                path={path}
            > <img
                className={imageStyle}
                alt={"item" + uuid4()}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholder
                }}
                src={"https://image.tmdb.org/t/p/w500" + (show.backdrop_path ? show.backdrop_path : show.poster_path)}/></LinkWrapper>
        </div>
    }

    renderLinkWrapper = (props) => {
        let {shareRoom} = props

        if (shareRoom) {

            return <div onClick={() => shareRoom(props.show.id)}>{props.children}</div>
        } else {
            return <Link
                style={{
                    textDecoration: 'none',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
                to={{
                    pathname: "/dp/" + props.show.id + "/" + 0
                }}>{props.children}</Link>
        }


    }

    /**
     *
     * Render the single item of comic
     *
     * @param show
     * @param param If passe will not use the name but the param to describe the comic under the image
     * @returns {*}
     */
    render() {
        let {refreshData, isMobile, index, path, shareRoom} = this.props;
        let {w, key, show, loading} = this.state

        if (loading) {
            return null
        }
        let subtitle = show.overview
        if (show.overview && show.overview.split(' ').length > 11) {
            subtitle = show.overview.split(' ').slice(0, 12).join(' ') + "...";
        }

        //2. Render the item

        let containerStyle = style.container
        let check

        if (769 < w && w < 960) {
            check = index % 3 === 0
            containerStyle = check ? style.containerLast : style.containerFirst
            if ((index + 1) % 3 === 0

            ) {
                containerStyle = style.container
            }
        } else if (961 <= w && w < 1280) {
            check = index % 4 === 0
            containerStyle = check ? style.containerLast : style.containerFirst
            if ((index + 1) % 4 === 0
                ||
                (index + 2) % 4 === 0
            ) {
                containerStyle = style.container
            }
        }
            // else if (1280 <= w && w < 1920) {
            //     check = index % 4 === 0
            //     containerStyle = check ? style.containerLast : style.containerFirst
            //     if (
            //         (index + 1) % 4 === 0
            //         ||
            //         (index + 2) % 4 === 0
            //     ) {
            //         containerStyle = style.container
            //     }
        // }
        else if (w >= 1920 || 1280 <= w && w < 1920) {
            check = index % 6 === 0
            containerStyle = check ? style.containerLast : style.containerFirst
            if (
                (index + 1) % 6 === 0
                ||
                (index + 2) % 6 === 0
                ||
                (index + 3) % 6 === 0
                ||
                (index + 4) % 6 === 0
            ) {
                containerStyle = style.container
            }
        }
        let LinkWrapper = (props) => this.renderLinkWrapper(props)

        if (isMobile) {
            containerStyle = style.containerMobile
        }
        return <div
            className={[
                containerStyle
            ]}
            key={"item-" + key}
            id={"item-" + key}
        >
            {isMobile && this.renderCommonMobile()}
            {!isMobile && <LinkWrapper show={show}
                                       shareRoom={shareRoom}
                                       path={path}
            >
                {this.renderCommonDesktop()}
            </LinkWrapper>}
            <div className={style.ColumnContainer}>
                <Label ellipsis={2} font={"--text-normal-10"} color={"--white-text"}
                >
                    Detail: {subtitle}
                </Label>
                <RowContainer>
                    <IconButton
                        to={{
                            pathname: "/dp/" + show.id + "/" + 0
                        }}
                        style={{padding: 0, marginRight: "10px"}}
                        color={"secondary"}
                        variant={"contained"}
                    >
                        <PlayCircleFilled fontSize={"large"}/>
                    </IconButton>
                    <FollowButton refreshData={refreshData} show={show}/>
                    <ToWatchButton refreshData={refreshData} show={show}/>
                </RowContainer>
            </div>
        </div>


    }


}

export default withRouter(ShowCard);
const RowContainer = styled.div`
display:flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;
width:100%;
padding:5px 0;
`;

const RowContainerSpaceBetween = styled.div`
display:flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: row;
width:100%;
`;




