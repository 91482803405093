import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Forward10 from "@material-ui/icons/Forward10"
import Replay10 from "@material-ui/icons/Replay10"
import PlayArrow from "@material-ui/icons/PlayArrow"
import ArrowBack from "@material-ui/icons/ArrowBack"
import Pause from "@material-ui/icons/Pause"
import {withRouter} from "react-router-dom";
import {GlobalContext} from "../constants/CommonConstants";
import EpisodeCard from "./EpisodeCard";
import {v4 as uuid4} from "uuid";
import {ColumnContainer, Label, RowContainer} from "./ComponentManager/ComponentManager";

class RemoteController extends Component {
    state = {
        isPaused: false,
        seasonNumber: this.props.seasonNumber,
        episode: this.props.episode,
        id: this.props.id,
        episodes: this.props.episodes,
        show: this.props.show,
    }

    contextUnwrap = (key) => {
        let peerVar = this.context.getGlobalVariable("peerVar")
        return peerVar[key]
    }

    connectionWrap = (data) => {
        let connection = this.contextUnwrap("connection")
        if (connection) {
            connection.send(data)
        }
    }

    render() {
        let {
            history, closeVideoPlayerPage
        } = this.props
        let {seasonNumber, episode, id, episodes, show,} = this.state
        let itemStyle = {padding: "20px", display: "flex", justifyContent: "center"}

        let episodeNumber = episode.episode_number
        let selectedId
        let temp2 = []
        let isMobile = false
        if (window.innerWidth < 600) {
            isMobile = true
        }
        for (let j in episodes) {
            let episodeTmp = episodes[j];
            let isSelected = episodeTmp.episode_number === episodeNumber
            if (isSelected) {
                selectedId = episodeNumber
            }
            temp2.push(<EpisodeCard
                liteVersion={true}
                isSelected={isSelected}
                handlePlayEpisode={(selectedLink, isMobile) => {
                    this.connectionWrap({
                        action: "LINK",
                        src: selectedLink,
                    })
                    this.setState({
                        seasonNumber,
                        episode: episodeTmp,
                        id,
                        episodes,
                        show,
                        selectedLink,
                        isMobile
                    })
                }}
                setLoading1={(value) => {
                    this.setState({loadingChild: value})
                }} key={"Internal" + uuid4()} id={id} seasonNumber={seasonNumber}
                episodes={episodes}
                episode={episodeTmp}
                episodeNumber={episodeNumber}
                show={show}/>);
        }
        setTimeout(() => {
                let elmnt = document.getElementById(selectedId);
                let elmntTE = document.getElementById("testCont");
                if (elmnt) {
                    let elmntCoord = elmnt.getBoundingClientRect()
                    elmntTE.scroll(((elmntCoord.width * 1.1) * (parseInt(selectedId) - 1)) + (selectedId * 5), 0);
                }
            }, 5
        )

        return (<ColumnContainer customStyle={"overflow:auto;"}>
                <RowContainer
                    customStyle={"width:100%;height:100%;padding:5px;max-height:50px;background: linear-gradient(90deg, rgba(39, 76, 134, 0.8) 0%, rgba(39, 76, 134, 0) 100%);"}
                >
                    <IconButton variant={"contained"} onClick={() => {
                        closeVideoPlayerPage()
                    }} color="secondary">
                        <ArrowBack/>
                    </IconButton>
                </RowContainer>
                <div
                    onWheel={(e) => {
                        e.preventDefault()
                        let container = document.getElementById('testCont')
                        let containerScrollPosition = document.getElementById('testCont').scrollLeft
                        container.scrollTo({
                            top: 0,
                            left: containerScrollPosition + e.deltaY,
                            behaviour: 'smooth' //if you want smooth scrolling
                        })
                    }}
                    id={"testCont"}
                    style={{
                        minWidth: "15%",
                        maxWidth: "100vw",
                        overflow: "auto",
                        gap: 20,
                        minHeight: "120px",
                        maxHeight: "100%",
                        zIndex: 1000,
                        display: "flex",

                        flexDirection: "row",
                        background: "linear-gradient(90deg, rgba(31,38,49,0.9) 0%, rgba(31,38,49,0.9) 100%)"
                    }}>
                    {temp2}
                </div>
                <Grid container spacing={0} style={{padding: "20px"}}>
                    {controls.map((control, index) => {
                        return <Grid
                            key={"grid" + index}
                            item xs={4} style={itemStyle}>
                            <IconButton
                                color={"secondary"}
                                onClick={() => {
                                    this.connectionWrap(control.data)
                                }}
                            >
                                {control.content}
                            </IconButton>
                        </Grid>
                    })}
                </Grid>
            </ColumnContainer>
        );
    }
}

RemoteController.contextType = GlobalContext;
export default withRouter(RemoteController);

let controls = [
    {
        data: {
            action: "BACK",
            value: 80
        },
        content: <Label
            font={"--text-font-h4"}>-80s</Label>
    },
    {
        data: {
            action: "PLAY",
        },
        content: <PlayArrow fontSize={"large"}/>
    },
    {
        data: {
            action: "FORWARD",
            value: 80
        },
        content: <Label
            font={"--text-font-h4"}>+80s</Label>
    },
    {
        data: {
            action: "BACK",
            value: 10
        },
        content: <Replay10
            fontSize={"large"}/>
    },
    {
        data: {
            action: "PAUSE",
        },
        content: <Pause fontSize={"large"}/>
    },
    {
        data: {
            action: "FORWARD",
            value: 10
        },
        content: <Forward10
            fontSize={"large"}/>
    },

]