import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import {randomIdGenerator} from "../ComponentManager";

const MainContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    
      ${props => {
    let propsStyles = ``;
    switch (props.platform) {
        case "mobile":
            propsStyles += `padding: 10px 10px 0 10px;`;
            break;
        case "desktop":
            propsStyles += `padding: 20px 20px 0 20px;`;
            break;
        default:
            propsStyles += `padding: 20px 20px 0 20px;`;
            break;
    }

    if (props.flex) {
        propsStyles += `flex: ` + props.flex + `;`
    } else {
        propsStyles += `flex:1;`
    }


    if (props.customStyle) {
        propsStyles += props.customStyle
    }

    return propsStyles
}}
`;

class ContentMainContainer extends Component {
    render() {
        let {children, id} = this.props
        return (
            <MainContainer
                id={randomIdGenerator(id, "ContentMainContainer")}
                {...this.props}
            >
                {children}
            </MainContainer>
        );
    }
}

ContentMainContainer.propTypes = {
    id: PropTypes.string,
    platform: PropTypes.string,
    flex: PropTypes.number,
    customStyle: PropTypes.string

};
export default ContentMainContainer;