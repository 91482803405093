import React, {Component} from 'react';
import {ArrowBack} from "@material-ui/icons";
import {Link, withRouter} from "react-router-dom";
import styled from "styled-components";
import {Label} from "./ComponentManager/ComponentManager";

class BackArrowComponentSmart extends Component {
    render() {
        let {history, match, customBack} = this.props;
        return (
            <Link
                to={{pathname: ""}}
                onClick={() => {
                    history.goBack()
                }}
            >
                <BackButtonContainer>
                    <ArrowBack color={"secondary"}/>
                    <Label font={"--text-normal-20"} ellipsis={2}
                           cursor={"pointer"}
                           customStyle={{container: "margin-left:10px;", label: "text-decoration-line: underline;"}}
                    >Back </Label>
                </BackButtonContainer>
            </Link>
        );
    }
}

const BackButtonContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 0px;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
`;
export default withRouter(BackArrowComponentSmart);