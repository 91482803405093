import React from 'react';
import {withRouter} from 'react-router-dom';
import {IconButton, ListItemIcon} from "@material-ui/core";
import ProfilePageHeader from "./ProfilePageHeader";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import GoogleLogin, {GoogleLogout} from "react-google-login";
import {SESSION_GOOGLE_TOKEN, SETTINGS_AUTO_SYNC} from "../constants/Config";
import {databasePull, db, DB_NAME} from "../constants/StorageHelper";
import styled from "styled-components";
import {GlobalContext} from "../constants/CommonConstants";
import BaseComponent from "../interfaces/BaseComponent";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {GetApp, Publish, Sync, SyncDisabled} from "@material-ui/icons";
import {exportDB} from "dexie-export-import";
import axios from "axios";
import UploadButton from "./UploadButton";
import Queue from "./Queue/Queue";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

class LoginGoogle extends BaseComponent {
    state = {
        menuOpen: false,
        promises: new Queue(true),
        toSync: false,
        size: null
    }

    async componentDidMount() {
        await this.databaseCompare();
        setInterval(this.databaseCompare, 3000)
    }

    databaseCompare = async () => {
        let {size} = this.state;
        let token = sessionStorage.getItem(SESSION_GOOGLE_TOKEN);
        if (size) {
            let blobLocal = await exportDB(db(), {noTransaction: true});
            this.setState({toSync: blobLocal.size != size})
            // if (blobLocal.size != size && sessionStorage.getItem(SETTINGS_AUTO_SYNC) == "true") {
            //     await this.databasePush();
            // }
        } else {
            try {
                let list = await axios.get("https://www.googleapis.com/drive/v3/files?q=name+%3d+%27" + DB_NAME + ".json%27", {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });
                if (list != null && list.data.files && list.data.files[0]) {
                    let getResponse = await axios.get("https://www.googleapis.com/drive/v3/files/" + list.data.files[0].id + "?alt=media", {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    });
                    let content = getResponse.data;
                    let blobRemote = new Blob([
                        JSON.stringify(content)
                    ], {
                        type: 'text/json'
                    });
                    let blobLocal = await exportDB(db(), {noTransaction: true});
                    this.setState({toSync: blobLocal.size != blobRemote.size, size: blobRemote.size})
                    // if (blobLocal.size != size && sessionStorage.getItem(SETTINGS_AUTO_SYNC) == "true") {
                    //     await this.databasePush();
                    // }
                }
            } catch (e) {
                console.error("FAILED IMPORT: ", e)
                throw e;
            }
        }

    };

    databasePush = async () => {
        let {promises} = this.state;
        let token = sessionStorage.getItem(SESSION_GOOGLE_TOKEN);
        if (!token) {
            return;
        }
        try {
            promises.add(async () => {
                let blob = await exportDB(db(), {noTransaction: true});
                let list = await axios.get("https://www.googleapis.com/drive/v3/files?q=name+%3d+%27" + DB_NAME + ".json%27", {
                    headers: {
                        Authorization: "Bearer " + token
                    }
                });
                if (list != null && list.data.files && list.data.files[0]) {
                    let updateResponse = await axios.patch("https://www.googleapis.com/upload/drive/v3/files/" + list.data.files[0].id + "?uploadType=resumable", {
                        "name": DB_NAME + ".json",
                        "properties": {key: "uniqueId", value: DB_NAME + ".json"}
                    }, {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    });
                    await axios.put(updateResponse.headers.location, blob, {
                        headers: {
                            Authorization: "Bearer " + token, "Content-Type": "multipart/form-data"
                        }
                    })
                } else {
                    let createResponse = await axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable", {
                        "name": DB_NAME + ".json",
                        "properties": {key: "uniqueId", value: DB_NAME + ".json"}
                    }, {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    });
                    await axios.put(createResponse.headers.location, blob, {
                        headers: {
                            Authorization: "Bearer " + token, "Content-Type": "multipart/form-data"
                        }
                    })
                }
                this.setState({toSync: false, size: blob.size})
            })
        } catch (e) {
            console.error("Error uploading data: ", e.response)
        }
    }


    handleClose = () => {
        this.setState({menuOpen: false, anchorEl: null})
    };


    handleClick = (e) => {
        this.setState({menuOpen: true, anchorEl: e.currentTarget})
    };

    render() {
        let {menuOpen, anchorEl, toSync} = this.state;
        let {disableLogout} = this.props;
        let profileData = this.context.getGlobalVariable("profileData")
        return profileData && profileData.imageUrl ? <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (<MenuContainer>
                <IconButton style={{flex: 1}} aria-controls="simple-menu" aria-haspopup="true"
                            {...bindTrigger(popupState)}
                            onClick={disableLogout ? disableLogout : this.handleClick}>
                    <ProfilePageHeader name={profileData.givenName}
                                       profileImage={profileData.imageUrl}/>
                </IconButton>
                <Menu
                    id="simple-menu"
                    keepMounted
                    {...bindMenu(popupState)}
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: "bottom"
                    }}

                >
                    <MenuItem onClick={() => {
                        this.handleClose();
                    }}> <GoogleLogout
                        clientId="12998500978-9rk2hki7jntah53m20m9nag4bgk1kr1o.apps.googleusercontent.com"
                        buttonText="Logout"
                        onLogoutSuccess={() => {
                            this.context.setProfileData(null)
                        }}
                    >
                    </GoogleLogout>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        if (toSync) {
                            this.databasePush();
                        } else {
                            this.databaseCompare();
                        }
                    }}>
                        <ListItemIcon>
                            {toSync ? <Publish color={"secondary"} fontSize={"large"}/> :
                                <SyncDisabled color={"secondary"} fontSize={"large"}/>}
                        </ListItemIcon>
                        {toSync ? <p>Push local to remote</p> :
                            <p>Database synced</p>}

                    </MenuItem>
                    {toSync ? <MenuItem onClick={() => {
                        databasePull(sessionStorage.getItem(SESSION_GOOGLE_TOKEN)).then(() => {
                            window.location.reload()
                        })

                    }}>
                        <ListItemIcon>
                            <GetApp color={"secondary"} fontSize={"large"}/>
                        </ListItemIcon>
                        <p>Pull remote to local</p>
                    </MenuItem> : null}
                </Menu>
            </MenuContainer>)}
        </PopupState> : <div
        >
            <div
                onClick={disableLogout ? (event) => {
                    disableLogout();
                } : () => {
                }}
                style={{
                    cursor: "pointer",
                    width: "94px",
                    zIndex: 1,
                    position: "fixed",
                    top: "16px",
                    height: "42px",
                }}/>
            <GoogleLogin
                clientId="12998500978-9rk2hki7jntah53m20m9nag4bgk1kr1o.apps.googleusercontent.com"
                buttonText="Login"
                uxMode={"popup"}
                onSuccess={(response) => {
                    this.setLoading(true, "Base-router")
                    this.context.setProfileData(response.profileObj)
                    sessionStorage.setItem(SESSION_GOOGLE_TOKEN, response.accessToken)
                    if (sessionStorage.getItem("firstSessionLogin") !== "true") {
                        sessionStorage.setItem("firstSessionLogin", "true")
                        localStorage.setItem("myScreenFilter", 1)
                        window.location.reload()
                    }
                    this.setLoading(false, "Base-router")

                }}

                scope={"profile email https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata"}
                onFailure={(response) => {
                    console.error("Login fallito: ", response)
                }}
                onAutoLoadFinished={(response) => {
                }}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
            /></div>
    }
}

LoginGoogle.contextType = GlobalContext;
export default withRouter(LoginGoogle);