import React, {Component} from 'react';
import {getDevice} from "../../../constants/CommonUtils";
import Button from "@material-ui/core/Button"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

class VideoPlayerMobileGridActions extends Component {
    render() {
        let {enableSettings, disableUi, editStateFromChild} = this.props
        return (getDevice() && enableSettings &&
            <Button color={"primary"}
                    variant={"contained"}
                    style={{width: "80px", height: "40px", marginLeft: "10px", zIndex: 1000}}
                    endIcon={disableUi ? <Visibility/> :  <VisibilityOff/>}
                    onClick={() => {
                        localStorage.setItem("disableUiPlayer", JSON.stringify(!disableUi))
                        editStateFromChild({disableUi: !disableUi})
                    }}>
                Grid
            </Button>

        );
    }
}

export default VideoPlayerMobileGridActions;