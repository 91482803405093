import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types";
import {randomIdGenerator} from "../ComponentManager";


class AdvancedGridContainer extends Component {


    renderDataObject = (props) => {
        let {gridItemComponent, CustomComponent, size, data} = props;
        let output = []
        for (let index in data) {
            let item = data[index];
            output.push(<Grid
                id={randomIdGenerator(item.id, "AdvancedGridItem")}
                key={randomIdGenerator(item.id, "AdvancedGridItem")}
                component={gridItemComponent}
                item
                {...size}
            >
                <CustomComponent
                    items={item}
                    itemIndex={index}
                />
            </Grid>)
        }
        return output
    }

    render() {
        let {
            id, loadMoreButton, data, CustomComponent, size,
            spacing, gridItemComponent, gridContainerComponent,
            flexDirection, justifyContent, alignItems,
            wrap, customStyle, isDataObject
        } = this.props;

        // 0. In base al size decido le dimensioni delle Grid item
        switch (size) {
            case "auto":
                size = {xs: "auto"};
                break;
            case"small":
                size = {xs: 12};
                break;
            case"medium":
                size = {xs: 12, sm: 6};
                break;
            case"large":
                size = {xs: 12, sm: 6, md: 3};
                break;
            case"extra-large":
                size = {xs: 12, sm: 6, md: 3, lg: 2, xl: 2};
                break;
            default:
                // 0.1 size custom passato come oggetto e passato alla grid tale e quale
                break;
        }

        let style = {overflow: "auto"}
        if (customStyle) {
            style = {...style, ...customStyle}
        }

        return (
            <Grid
                style={style}
                item
                id={randomIdGenerator(id, "AdvancedGridContainer")}
                component={gridContainerComponent}
                wrap={wrap}
                direction={flexDirection}
                justify={justifyContent}
                alignItems={alignItems}
                container spacing={spacing || 1}>
                {
                    !isDataObject ?
                        data.map((i, itemIndex) => {
                            return <Grid
                                id={randomIdGenerator(i.id, "AdvancedGridItem")}
                                key={randomIdGenerator(i.id, "AdvancedGridItem")}
                                component={gridItemComponent}
                                item
                                {...size}
                            >
                                <CustomComponent
                                    {...i}
                                    itemIndex={itemIndex}
                                />
                            </Grid>
                        }) :
                        this.renderDataObject({size, gridItemComponent, CustomComponent, data})
                }
                {loadMoreButton &&
                <Grid
                    id={randomIdGenerator(false, "AdvancedGridLoadMoreButton")}
                    key={randomIdGenerator(false, "AdvancedGridLoadMoreButton")}
                    component={gridItemComponent}
                    item
                    xs={12}
                >
                    {loadMoreButton}
                </Grid>}
            </Grid>
        );
    }
}


AdvancedGridContainer.propTypes = {
    id: PropTypes.string,
    spacing: PropTypes.number,
    flexDirection: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    wrap: PropTypes.string,
    size: PropTypes.string,
    // CustomComponent: PropTypes.element,
    gridContainerComponent: PropTypes.element,
    gridItemComponent: PropTypes.element

};

export default AdvancedGridContainer;