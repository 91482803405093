import React, {Component} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {ArrowBack} from "@material-ui/icons";
import VideoPlayer, {getTypeVideo} from "../../components/VideoPlayer";
import {withRouter} from "react-router-dom";
import {GlobalContext} from "../../constants/CommonConstants";
import {ColumnContainer} from "../../components/ComponentManager/ComponentManager";
import {RowContainer} from "../SearchScreen/SearchScreen";


class QuickVideoPage extends Component {
    state = {
        selectedLink: "",
        showUI: true,
        enableSettings: false
    }

    childRef = React.createRef();


    renderContent = () => {
        let {
        } = this.state;
        let {
            externalMediaserver
        } = this.props;

        let isMobile = false
        if (window.innerWidth < 600) {
            isMobile = true
        }

        let peerVar
        let selectedLink
        if (!externalMediaserver) {
            peerVar = this.context.getGlobalVariable("peerVar")
            selectedLink = peerVar["selectedLink"]
        } else {
            selectedLink = this.props.selectedLink
        }
        let flexDirection = "flex-direction:row;height:100%;"


        let videoJsOptions = {
            autoplay: false,
            controls: true,
            "data-setup": '{}',
            preload: "auto",
            sources: [//TODO switch case con controlli in abbondaza
                {
                    src: selectedLink + "#t=0.5",
                    type: getTypeVideo(selectedLink)
                },

            ]
        }


        return <RowContainer customStyle={"width:100%;" + flexDirection}>
            <VideoPlayer videoJsOptions={videoJsOptions}
                         setRef={(x) => {
                             if (!externalMediaserver) {
                                 this.context.setGlobalVariables({peerVar: {...peerVar, player: x.player}})
                             }
                         }}
                         isQuickVideo={true}
                         isMobile={isMobile}
                         selectedLink={selectedLink}
                         disableUi={true}
                         forceFullScreen={true}
                         onPlay={() => {
                             this.setState({showUI: false})
                         }}
                         onPause={() => {
                             this.setState({showUI: true})
                         }}
                         f={(force) => {
                             if (force) {
                                 this.setState({showUI: force})
                             } else {
                                 this.setState({showUI: !this.state.showUI})
                             }
                         }}
            />
        </RowContainer>
    }

    renderActions = () => {
        let {history, externalMediaserver} = this.props;

        return <RowContainer
            customStyle={"width:100%;height:100%;padding:5px;max-height:50px;z-index:1000;position:fixed;top:0;left:0; background: linear-gradient(90deg, rgba(39, 76, 134, 0.8) 0%, rgba(39, 76, 134, 0) 100%);"}
        >
            <IconButton variant={"contained"} onClick={() => {
                if (externalMediaserver) {
                    externalMediaserver()
                } else {
                    history.goBack()
                }
            }} color="secondary">
                <ArrowBack/>
            </IconButton>
        </RowContainer>
    }

    render() {
        let {showUI} = this.state
        return (

            <ColumnContainer customStyle={"width:100%;"}>
                {showUI && this.renderActions()}
                {this.renderContent()}
            </ColumnContainer>
        );
    }
}

QuickVideoPage.contextType = GlobalContext;
export default withRouter(QuickVideoPage);