import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';


class PaperDraggableSmallest extends Component {
    render() {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...this.props} style={{overflow: "hidden",minHeight: "200px",
                    minWidth:"300px",
                    maxWidth:"80%"}}/>
            </Draggable>
        );
    }
}

export default PaperDraggableSmallest;
