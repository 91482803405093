import React, {Component} from "react";

import {IconButton, Snackbar} from "@material-ui/core";
import {Close} from "@material-ui/icons";

import style from "./SnackBar.module.css";
import {uuidGenerator} from "../constants/CommonUtils";

const Alert = React.forwardRef(function Alert(props, ref) {
    return
});
export default class SnackBar extends Component {
    state={
        customKey:uuidGenerator()
    }

    render() {
        let {
            open,
            vertical,
            horizontal,
            autoHideDuration,
            handleClose,
            message,
            type="info",
            customColor,

        } = this.props;
        let{customKey}=this.state

        let color;

        switch (type) {
            case "success":
                color = "#43A047";
                break;
            case "warning":
                color = "#FFA000";
                break;
            case "info":
                color = "#0659BD";
                break;
            case "error":
                color = "#D32F2F";
                break;
            case "notification":
                if (customColor) {
                    color = customColor;
                }
                break;
            default:
                color = "#000000"
                break;
        }

        return (
            <Snackbar
                key={customKey}
                className={style.snackBar}
                anchorOrigin={{vertical: vertical, horizontal: horizontal}}
                open={open}
                onClose={
                    handleClose
                }
                type={type}
                message={message}
                autoHideDuration={autoHideDuration}
                // TransitionComponent={(props) => <Slide {...props} direction="left"/>}
                action={[
                    <IconButton
                        key={"snackBar"}
                        id="close"
                        aria-label="close"
                        onClick={handleClose}
                        size="large">
                        <Close className={style.icon}/>
                    </IconButton>
                ]}
            >
                <Alert onClose={handleClose}
                       severity={type}
                       sx={{
                           width: '100%',
                           backgroundColor: color
                       }}
                       className={style.message}
                >
                    {message}
                </Alert>
            </Snackbar>
        );
    }
}

SnackBar.defaultProps = {
    vertical: "top",
    horizontal: "right",
    open: false,
    autoHideDuration: 3000,
    message: "",
    type: "info",
    handleClose: () => {
    },
    customColor: "#D3D3D3"
};
