import React from 'react';
import BaseComponent from "../interfaces/BaseComponent";
import {Grid} from "@material-ui/core";
import {v4 as uuid4} from "uuid"
import ShowCard from "./ShowCard";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Skeleton} from "@material-ui/lab";
import HeaderTop from "./HeaderTop";

class ShowList extends BaseComponent {
    minHeightDesktop = "100%"
    customStyle = {
        flex: undefined
    }

    state = {loading: false, lastScrollTop: 0}

    async componentDidMount() {
        let scrollingGrid = document.getElementById("griddona")
        scrollingGrid.addEventListener("scroll", this.handleHeaderOnScroll)

    }

    handleHeaderOnScroll = () => {
        let {lastScrollTop} = this.state;
        let {loadMore} = this.props;
        let header = document.getElementById("hidableHeader")
        let scrollingGrid = document.getElementById("griddona")
        let scrollTop = scrollingGrid.scrollTop;
        if (scrollTop > lastScrollTop) {
            header.style.top = "-73px"
        } else {
            header.style.top = "0"
        }
        if (loadMore && scrollTop + scrollingGrid.clientHeight > scrollingGrid.scrollHeight / 2) {
            loadMore()
        }

        this.state.lastScrollTop = scrollTop

    }

    renderCommon(isMobile) {
        // 1. Fill the sctructure with the API data if present
        let {shows, path, refreshData, disableScroll, loadMore, toolbar, disableLoadMore, hideLoadMore, isSearching, disableHeader, shareRoom} = this.props;
        let {loading} = this.state
        let skeletons = Array.from(Array(10).keys())
        return (
            <Grid container spacing={0}
                  id={"griddona"}
                  alignContent={"flex-start"}
                  style={{
                      overflow: disableScroll ? "hidden" : "auto",
                      padding: "100px 10px 10px 10px",
                      minHeight: "100%", flex: 1
                  }}>
                {!disableHeader && !shareRoom && <Grid item xs={12}>
                    <HeaderTop id={"hidableHeader"}/>
                </Grid>}
                {toolbar}
                <Grid item xs={12} style={{height: "20px"}}/>
                {shows.length > 0 ? shows.map((show, index) => {
                    return <Grid
                        style={{
                            padding: 3,
                            transition: "all 1s ease-in-out"
                        }}
                        key={"itemGrid" + (show.idShow || show.id)}
                        item xs={6} sm={4} md={3} lg={2} xl={2}>
                        <ShowCard show={show}
                                  key={"card" + (show.idShow || show.id)}
                                  path={path}
                                  shareRoom={shareRoom}
                                  index={index + 1}
                                  refreshData={refreshData}
                                  isMobile={isMobile}
                        /></Grid>
                }) : isSearching ? <h3>No series found with this name, try different keywords</h3> :
                    <h3>You have seen no series.<br/> Start going to search tab and adding new link to you favorites TV
                        series.</h3>
                }
                {loading && skeletons.map((show1, index) => {
                    let show = {
                        overwiew: "",
                        id: "",
                        fake: true,
                        name: <Skeleton variant="text"/>,
                    }
                    return <Grid
                        style={{
                            padding: 3,
                            transition: "all 1s ease-in-out"
                        }}
                        key={"itemGrid" + uuid4()}
                        item xs={6} sm={4} md={3} lg={2} xl={2}>
                        <ShowCard show={show}
                                  key={"card" + uuid4()}
                                  shareRoom={shareRoom}
                                  path={path}
                                  index={index + 1}
                                  refreshData={refreshData}
                                  isMobile={isMobile}
                        /></Grid>
                })}

                {!hideLoadMore && <Grid item xs={12}
                                        style={{
                                            height: "100px",
                                            padding: "20px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                    <Button color={"primary"}
                            style={{backgroundColor: disableLoadMore ? "white" : undefined}}
                            variant={"contained"}
                            disabled={loading || disableLoadMore}
                            onClick={() => {
                                this.setState({loading: true}, () => {
                                    setTimeout(
                                        () => {
                                            loadMore()
                                            setTimeout(
                                                () => this.setState({loading: false}),
                                                500
                                            )
                                        }
                                        , 1200
                                    )
                                })
                            }}>{loading ? <CircularProgress color={"secondary"}/> : "Load more"}</Button>
                </Grid>}

            </Grid>
        );
    }

    renderDesktop = () => {
        return this.renderCommon()
    }
    renderMobile = () => {
        return this.renderCommon(true)
    }
}

export default ShowList;

