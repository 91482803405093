import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import {randomIdGenerator} from "../ComponentManager";


const MainContainer = styled.div`
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

      ${props => {
    let propsStyles = ``;
    switch (props.platform) {
        case "mobile":
            propsStyles += ``;
            break;
        case "desktop":
            propsStyles += ``;
            break;
        default:
            propsStyles += ``;
            break;
    }
    if (props.flex) {
        propsStyles += `flex: ` + props.flex + `;`
    }

    if (props.customStyle) {
        propsStyles += props.customStyle
    }

    return propsStyles
}}
`;

class ScrollableContainer extends Component {


    render() {
        let {children, id} = this.props;
        return (
            <MainContainer
                id={randomIdGenerator(id, "ScrollableContainer")}
                {...this.props}>
                {children}
            </MainContainer>
        );
    }
}

ScrollableContainer.propTypes = {
    id: PropTypes.string,
    platform: PropTypes.string,
    flex: PropTypes.number,
    customStyle: PropTypes.string

};
export default ScrollableContainer;