import React from "react";
import {withRouter} from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from "styled-components";
import {LANGUAGE, TMDB_KEY} from "../../constants/Config";
import BaseAxios from "../../constants/ApiHelpers/BaseAxios";
import ShowList from "../../components/ShowList";
import {Grid} from "@material-ui/core";
import BaseComponent from "../../interfaces/BaseComponent";
import SearchBar from "../../components/SearchBar";

class SearchScreen extends BaseComponent {
    state = {
        source: "tmdb",
        sources: ["tmdb"],
        animes: [],
        total_pages: 1,
        page: 1,
        enableToolBar: false
    };

    componentDidMount() {
        this.refreshData();
    }

    searchTmnd = (q, page = 1) => {
        if (q) {
            return BaseAxios.get("https://api.themoviedb.org/3/search/tv?api_key=" + TMDB_KEY + "&language=" + LANGUAGE + "&query=" + q + "&include_adult=true&page=" + page);
        } else {
            return BaseAxios.get("https://api.themoviedb.org/3/trending/tv/week?api_key=" + TMDB_KEY + "&language=" + LANGUAGE + "&page=" + page)
        }
    }

    generes = () => {
        return BaseAxios.get("https://api.themoviedb.org/3/genre/tv/list?api_key=" + TMDB_KEY + "&language=" + LANGUAGE);
    }

    lock = false
    refreshData = (searchString = this.state.searchString, page = this.state.page) => {
        if (this.lock) {
            return
        }
        try {
            this.lock = true
            this.searchTmnd(searchString, page).then((response) => {
                let tmp=[]
                response.data.results.forEach((tmpNew) => {
                    if (!this.state.animes.some((tmpOld) => tmpOld.id === tmpNew.id)) {
                        tmp.push(tmpNew)
                    }
                })
                this.setState({
                    animes: [...this.state.animes, ...tmp],
                    total_pages: response.data.total_pages,
                    page
                }, () => this.lock = false);
            })
        } catch (e) {
            console.error("Error while refreshing searching screen", e.response)
        }
    }


    handleChangeSource = (e) => {
        let source = e.target.value;
        this.setState({source})
    };

    renderDesktop = () => {
        return this.renderCommon()
    }
    renderMobile = () => {
        return this.renderCommon(true)
    }


    renderCommon = (isMobile) => {
        let {animes, searchString} = this.state;


        return <ListContainer>
            <ShowList
                path={"/s"}
                toolbar={
                    <Grid container item xs={12}>
                        <Grid item xs={12} sm={8} md={6} lg={4}
                              style={{padding: "5px", transition: "all 1s ease-in-out"}}>
                            <SearchBar
                                refreshData={async (searchString) => {
                                    this.setState({
                                            searchString,
                                            page: 1,
                                            animes: []
                                        },
                                        async () => await this.refreshData(searchString, 1)
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                loadMore={() => {
                    this.refreshData(searchString, this.state.page + 1)

                }}
                refreshData={this.refreshData}
                shows={animes}
            />

        </ListContainer>

    }

}

export default withRouter((SearchScreen));


const ListContainer = styled.div`
display: flex;
flex: 1;
min-height: 0px; //THIS make scrolling vertical work in other browser flex tips and trick https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
flex-direction:column;
align-items:center;
`;


export const RowContainer = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
`;

