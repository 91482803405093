import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';

class PaperFullScreen extends Component {
    render() {
        return (
                <Paper
                    {...this.props}
                    style={{
                        overflow: "hidden",
                        display: "flex",
                        alignSelf:"stretch",
                        flexDirection:"column",
                        justifyContent:"space-between",
                        flex:"1",
                        minHeight:"100%",
                        margin:0
                    }}
                />
        );
    }
}

export default PaperFullScreen;
