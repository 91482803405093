import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import {randomIdGenerator} from "../ComponentManager";

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
  
      ${props => {
    let propsStyles = ``;
    switch (props.platform) {
        case "mobile":
            propsStyles += ``;
            break;
        case "desktop":
            propsStyles += ``;
            break;
        default:
            propsStyles += ``;
            break;
    }

    if (props.justifyContent) {
        propsStyles += `justify-content: ` + props.justifyContent + `;`
    } else {
        propsStyles += `justify-content:space-between;`
    }
    if (props.alignItems) {
        propsStyles += `align-items: ` + props.alignItems + `;`
    } else {
        propsStyles += `align-items:center;`
    }
    if (props.flex) {
        propsStyles += `flex: ` + props.flex + `;`
    } 

    if (props.customStyle) {
        propsStyles += props.customStyle
    }

    return propsStyles
}}
`;

class RowContainer extends Component {
    render() {
        let {children, id} = this.props;
        return (
            <MainContainer
                id={randomIdGenerator(id, "RowContainer")}
                {...this.props}
            >
                {children}
            </MainContainer>
        );
    }
}

RowContainer.propTypes = {
    id: PropTypes.string,
    platform: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    flex: PropTypes.number,
    customStyle: PropTypes.string

};
export default RowContainer;