import React from "react";
import {GlobalContext} from "../constants/CommonConstants";
import InterfaceComponent from "./InterfaceComponent";
import LoadingSystem from "../components/LoadingSystem";


class BaseComponent extends InterfaceComponent {
    loadingSystem = <LoadingSystem loading={this.loading} setLoading={this.setLoading}/>
    snackBar = {
        sb: {
            vertical: "top",
            horizontal: "right",
            open: false,
            autoHideDuration: 30000,
            message: "No message"
        },
        sendToDevMessage: "",
        sendToDevMessageSuccess: "",
    };


    render() {

        return <GlobalContext.Consumer>{
            globalContext => {
                return this.render2(globalContext)
            }
        }</GlobalContext.Consumer>
    }
}

BaseComponent.contextType = GlobalContext;
export default BaseComponent;

