import React from "react";
import {withRouter} from "react-router-dom";
import logo from "./../../assets/images/CitTMDB.svg"
import BaseComponent from "../../interfaces/BaseComponent";
import HeaderTop from "../../components/HeaderTop";


class PrivacyPolicyScreen extends BaseComponent {


    render() {

        return (<div style={{overflow: "auto", display: "flex", flex: 1, flexDirection: "column", padding: "100px 20px 20px 20px"}}>
                <HeaderTop id={"hidableHeader"}/>
                <h2><a href={"https://t.me/joinchat/HV2UixRK70Dxa-48"}>Join telegram to ask permission to login</a></h2>
                <h3>We don't store your data, period. (Everything remains in your browser)</h3>
                <h4>We use the api of:</h4>
                <img alt={"cit"} src={logo}/>
            </div>
        )
    }

}


export default withRouter(PrivacyPolicyScreen);
