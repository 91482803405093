import React, {Component} from 'react';
import {IconButton} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import styled from "styled-components";

class SearchBar extends Component {

    state={}

    render() {
        let {refreshData} = this.props;
        let {searchString} = this.state;
        return (
            <SearchContainer id={"SearchContainer"}>
                <PlaceholderText type={"text"} placeholder="Search here"
                                 id={"search-box"}
                                 onChange={async(event) => {
                                     let value = event.target.value
                                     this.setState({searchString: value})
                                     if(!value || value.length===0){
                                         await refreshData(value)
                                     }
                                 }}
                                 onKeyDown={async (event) => {
                                     if (event.key === "Enter") {
                                         await refreshData(searchString)
                                     }
                                 }}
                />
                <IconButton
                    onClick={async () => {
                        await refreshData(searchString)
                    }}
                >
                    <Search style={{color: "#fff"}}/>
                </IconButton>
            </SearchContainer>
        );
    }
}

export default SearchBar;


const SearchContainer = styled.div`
display:flex;
flex-direction: row;
align-items:center;
justify-content: flex-start;
height: 40px;
border: 3px solid #777777;
box-sizing: border-box;
border-radius: 8px;
`;


export const PlaceholderText = styled.input`
height: 23px;
width: 100%;
margin-left: 10px;
font: var(--text-normal-20);
line-height: 28px;
color: #fff;
background-color:transparent;
border: none;
&:focus {
    outline:none;
  }
`;