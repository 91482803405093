import React, {Component} from 'react';
import {Button, ClickAwayListener, MenuItem, MenuList, Paper, Popover} from "@material-ui/core";
import {ArrowDropDown} from "@material-ui/icons";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";

class MobileButtonListV2 extends Component {

    state = {
        open: false,
    }

    handleToggle = () => {
        this.setState({open: !this.state.open});
    };

    handleClose = event => {
        this.setState({open: false});
    };

    render() {
        let {buttonsArray, customStyle, disabled} = this.props;
        let {open} = this.state;
        return (

            <PopupState variant="popover">
                {popupState => (<div
                    {...bindTrigger(popupState)}
                >
                    <Button
                        style={customStyle && customStyle.rightButton ? customStyle.rightButton : undefined}
                        color="primary"
                        size="small"
                        disabled={disabled}
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={this.handleToggle}>
                        <ArrowDropDown/>
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        open={open}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener mouseEvent={"onMouseDown"} onClickAway={this.handleClose}>
                                <MenuList id="split-button-menu" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: " 10px",
                                    alignItems: "flex-start"
                                }}>
                                    {buttonsArray.map((button, index) => {
                                        return <MenuItem
                                            key={"button" + index}
                                            style={{width: "100%", padding: 0}}
                                        >
                                            {button}
                                        </MenuItem>
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover></div>)}</PopupState>

        );
    }
}

export default MobileButtonListV2;