import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';


class PaperDraggable extends Component {
    render() {
        return (
            <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...this.props} style={{overflow: "hidden",minHeight: "270px",
                    minWidth:"300px",
                    maxWidth:"50vw"}}/>
            </Draggable>
        );
    }
}

export default PaperDraggable;
